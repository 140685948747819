import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, useEffect, useState } from 'react';
import { Fallback, NotFound } from '@components/';
import { useAppSelector, useAppDispatch } from '@redux/hooks';
import { getUserListRequest } from '@redux/userDetails/userDetails.actions';
import { selectIsAdmin, selectIsSuperAdmin, selectIsUserListFetched, selectIsUserListPresent } from '@redux/userDetails/userDetails.selector';
import UserForm from './partials/UserForm';
const EmptyUserList = lazy(() => import('./partials/EmptyUserList'));
const UserList = lazy(() => import('./partials/UserList'));
const UserDetails = () => {
    const isAdmin = useAppSelector(selectIsAdmin);
    const isSuperAdmin = useAppSelector(selectIsSuperAdmin);
    const isUserListPresent = useAppSelector(selectIsUserListPresent);
    const isUserListFetching = useAppSelector(selectIsUserListFetched);
    const dispatch = useAppDispatch();
    const [isSideDrawerOpen, setSideDrawerOpen] = useState(false);
    const [userId, setUserId] = useState('');
    useEffect(() => {
        dispatch(getUserListRequest());
    }, []);
    if (!(isAdmin || isSuperAdmin))
        return _jsx(NotFound, {});
    if (isUserListFetching)
        return _jsx(Fallback, { customClassName: "w-full h-[calc(100vh_-_15rem)]" });
    return (_jsxs(_Fragment, { children: [_jsx(React.Suspense, { fallback: _jsx(Fallback, {}), children: isUserListPresent ? (_jsx(UserList, { setSideDrawerOpen: setSideDrawerOpen, setUserId: setUserId, userId: userId })) : (_jsx(EmptyUserList, { setSideDrawerOpen: setSideDrawerOpen })) }), _jsx(UserForm, { open: isSideDrawerOpen, onClose: () => setSideDrawerOpen(false), userId: userId })] }));
};
export default UserDetails;
