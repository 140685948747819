import { ACTION_TYPES } from '@redux/ReduxActionTypes';
import { checkTokenIssue } from '@redux/utils';
import { initialState as userDetailsInitState } from './constants';
import { fetchUserList, getUserDetails } from '@services/UserApi';
const { USER_ACTION_TYPES } = ACTION_TYPES;
export const setUserDetails = (userDetails) => ({
    type: USER_ACTION_TYPES.SET_LOGIN_USER_DETAIL_SUCCESS,
    payload: userDetails
});
const setUserDetailsError = (payload) => ({
    type: USER_ACTION_TYPES.SET_LOGIN_USER_DETAIL_ERROR,
    payload
});
export const getUserDetailsAPI = () => {
    return async function (dispatch) {
        await getUserDetails()
            .then((response) => {
            dispatch(setUserDetails(response?.data));
        })
            .catch((error) => {
            dispatch(setUserDetailsError(error));
            checkTokenIssue(error, dispatch);
        });
    };
};
export const resetUserDetails = () => ({
    type: USER_ACTION_TYPES.RESET_LOGIN_USER_DETAIL_DATA,
    payload: userDetailsInitState
});
const setUserListSuccess = (payload) => ({
    type: USER_ACTION_TYPES.SET_USER_LIST_SUCCESS,
    payload
});
const setUserListError = (payload) => ({
    type: USER_ACTION_TYPES.SET_USER_LIST_ERROR,
    payload
});
const setUserListPageDetails = (payload) => ({
    type: USER_ACTION_TYPES.SET_USER_LIST_PAGE_DETAILS,
    payload
});
const resetUserList = (payload) => ({
    type: USER_ACTION_TYPES.RESET_USER_LIST_DATA,
    payload
});
const setIsUserListPresent = (isUserListPresent) => ({
    type: USER_ACTION_TYPES.SET_IS_USER_LIST_PRESENT,
    payload: isUserListPresent
});
const setFetchingUserList = (isFetching) => ({
    type: USER_ACTION_TYPES.SET_USER_LIST_FETCHING,
    payload: isFetching
});
export const getUserListRequest = (requestPayload) => {
    return function (dispatch) {
        const { currentPageNumber = 0, currentPageSize = 10 } = requestPayload || {};
        dispatch(setFetchingUserList(true));
        fetchUserList({
            pageNo: currentPageNumber,
            pageSize: currentPageSize
        })
            .then((response) => {
            const { users = [], pageDetails = {}, errors = [] } = response?.data?.data || {};
            if (users?.length) {
                dispatch(setIsUserListPresent(true));
                dispatch(setUserListSuccess(users));
                dispatch(setUserListPageDetails(pageDetails));
            }
            if (errors?.length) {
                dispatch(setIsUserListPresent(false));
                dispatch(setUserListError(errors));
            }
        })
            .catch((error) => {
            dispatch(setUserListError(error));
            checkTokenIssue(error, dispatch);
        })
            .finally(() => {
            dispatch(setFetchingUserList(false));
        });
    };
};
export const resetUserListRequest = () => {
    return function (dispatch) {
        dispatch(resetUserList({
            isFetching: false,
            userListSuccess: [],
            userListError: [],
            userListPageDetails: {},
            isUserListPresent: false
        }));
    };
};
