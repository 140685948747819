// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NQMuJsRHy5sqInHAxuPQ{position:relative;height:100vh}.njOyD8fRhsVjP66X_03Y{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);margin:0 auto}.soPRnmntRTaQlvqVlEwz{margin-top:32px;text-align:center}@media screen and (min-width: 768px){.soPRnmntRTaQlvqVlEwz{margin-top:40px}}", "",{"version":3,"sources":["webpack://./src/common/components/NotFound/NotFound.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,aAAA,CAGF,sBACE,eAAA,CACA,iBAAA,CAGF,qCACE,sBACE,eAAA,CAAA","sourcesContent":[".not-found-wrapper {\n  position: relative;\n  height: 100vh;\n}\n\n.not-found-illustration {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  margin: 0 auto;\n}\n\n.not-found-msg-wrapper {\n  margin-top: 32px;\n  text-align: center;\n}\n\n@media screen and (min-width: 768px) {\n  .not-found-msg-wrapper {\n    margin-top: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"not-found-wrapper": "NQMuJsRHy5sqInHAxuPQ",
	"not-found-illustration": "njOyD8fRhsVjP66X_03Y",
	"not-found-msg-wrapper": "soPRnmntRTaQlvqVlEwz"
};
export default ___CSS_LOADER_EXPORT___;
