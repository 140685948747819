import { jsx as _jsx } from "react/jsx-runtime";
import { Drawer } from '@navi/web-ui/lib/primitives';
import { ShuriLink } from '@navi/shuri';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { useDispatch } from 'react-redux';
import { getUserListRequest } from '@redux/userDetails/userDetails.actions';
import { SHURI_CREATE_USER_FORM_LINK, SHURI_EDIT_USER_FORM_LINK, SHURI_USER_RESPONSE, USER_CREATE_SUCCESS_MESSAGE, USER_UPDATE_SUCCESS_MESSAGE } from '../constants';
import { STATUS_CODE } from '@const/ApiConstants';
import { getErrorMessage } from '@utils/';
import { AUTH_CLIENT_ID } from '@const/ApiConstants';
const UserForm = (props) => {
    const { userId, open, onClose } = props;
    const shuriUrl = !userId ? SHURI_CREATE_USER_FORM_LINK : SHURI_EDIT_USER_FORM_LINK;
    const dispatch = useDispatch();
    const callbackHandler = (eventName, payload) => {
        if (eventName === SHURI_USER_RESPONSE) {
            if (payload.response.statusCode === STATUS_CODE.OK) {
                toast.success(userId ? USER_UPDATE_SUCCESS_MESSAGE : USER_CREATE_SUCCESS_MESSAGE);
                dispatch(getUserListRequest({}));
                onClose();
            }
            else {
                const errMessage = getErrorMessage(payload?.response);
                toast.error(errMessage);
            }
        }
    };
    const clientApiOptions = {
        pathReplacementMap: {
            userId
        },
        additionalHeaders: {
            'X-Realm-Id': AUTH_CLIENT_ID
        }
    };
    return (_jsx(Drawer, { open: open, onClose: onClose, headerText: userId ? 'Edit Details' : 'New User', headerContainerClasses: "border-solid border-x-0 border-t-0 border-grey", children: open && (_jsx(ShuriLink, { endpoint: shuriUrl, callback: callbackHandler, apiOptions: clientApiOptions, payload: { userId } })) }));
};
export default UserForm;
