import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LoadingIcon } from '@navi/web-ui/lib/icons';
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Card, KeyValueLabel, Typography } from '@navi/web-ui/lib/primitives';
import React from 'react';
import styles from '../Dashboard.module.scss';
import ClockFilled from '@assets/icons/ClockFilled.svg';
import NotesFilled from '@assets/icons/NotesFilled.svg';
import QueryFilled from '@assets/icons/QueryFilled.svg';
import { useNavigate } from 'react-router-dom';
import { getPendingActionTickets } from '@services/ClaimApi';
import { STATUS_CODE } from '@const/ApiConstants';
import { getErrorMessage } from '@utils/';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { PendingActionTicketStatus } from '../enums';
const PendingActionTickets = (props) => {
    const { hospitalId } = props;
    const [pendingActionTickets, setPendingActionTickets] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        if (hospitalId) {
            setIsLoading(true);
            getPendingActionTickets(hospitalId)
                .then((response) => {
                if (response.data?.statusCode === STATUS_CODE.OK) {
                    setPendingActionTickets(response.data?.data?.claims);
                    return;
                }
                throw response;
            })
                .catch((error) => {
                toast.error(getErrorMessage(error));
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
    }, [hospitalId]);
    const getPendingActionDetails = (value) => {
        switch (value) {
            case PendingActionTicketStatus.DISCHARGE_AWAITED:
                return {
                    icon: _jsx("img", { src: ClockFilled, className: styles['pending-action-icon'] }),
                    status: 'Discharge Awaited'
                };
            case PendingActionTicketStatus.CASHLESS_DOCUMENT_AWAITED:
                return {
                    icon: _jsx("img", { src: NotesFilled, className: styles['pending-action-icon'] }),
                    status: 'Cashless Document Request'
                };
            case PendingActionTicketStatus.MORE_INFORMATION_NEEDED:
                return {
                    icon: _jsx("img", { src: QueryFilled, className: styles['pending-action-icon'] }),
                    status: 'Missing Info Request'
                };
            default:
                break;
        }
    };
    const navigate = useNavigate();
    return (_jsx(GridContainer, { className: "m-0", children: _jsxs(Card, { className: `w pb-0 pt-4 px-0 rounded-xl homepage-custom-card`, children: [_jsx(Typography, { variant: "p2", className: "font-semibold mb-6 px-6", color: "#1C1C1C", children: "Pending Action Tickets" }), _jsx(GridContainer, { className: `overflow-scroll max-h-[592px] min-h-[592px] p-0 ${(isLoading || !pendingActionTickets.length) && 'flex justify-center'} ${isLoading && 'items-center'}`, children: isLoading ? (_jsx(LoadingIcon, { className: "px-4", width: 20 })) : pendingActionTickets.length ? (pendingActionTickets?.map((item, index) => {
                        const ticketDetails = getPendingActionDetails(item.ticketStatus) || {};
                        return (_jsxs(_Fragment, { children: [_jsxs(GridRow, { className: "m-0 pb-2 pt-4 cursor-pointer flex-nowrap px-6 hover:bg-light-grey", onClick: () => navigate(`/claim-journey/${item.claimId}`), children: [_jsx(GridColumn, { className: "p-0 w-fit", children: ticketDetails?.icon }), _jsxs(GridColumn, { className: "p-0 pl-2", children: [_jsx(Typography, { variant: "p3", className: "font-medium mb-2", color: "#1C1C1C", children: ticketDetails?.status }), _jsx(KeyValueLabel, { className: `bg-transparent p-0 text-regal-grey ${styles['header-details']}`, dataArray: [
                                                        {
                                                            key: 'Name',
                                                            value: item.patientName
                                                        },
                                                        {
                                                            key: 'Claim ID',
                                                            value: item.claimId
                                                        }
                                                    ] })] })] }), pendingActionTickets?.length - 1 !== index && (_jsx("hr", { className: styles['horizontal-line'] }))] }));
                    })) : (_jsx(Typography, { variant: "p3", className: "text-primary-grey mt-10", children: "No Pending Action Tickets to show" })) })] }) }));
};
export default PendingActionTickets;
