import { jsx as _jsx } from "react/jsx-runtime";
import { PreAuthClaimStatus } from '@enums/ClaimEnums';
import { ClaimStatus } from '@enums/ClaimStatus';
import settledIcon from '@assets/icons/blue-doc-tickmark.svg';
import missingInfoIcon from '@assets/icons/orange-question-mark.svg';
import purpleDocIcon from '@assets/icons/purple-doc.svg';
import redCrossIcon from '@assets/icons/red-cross.svg';
import greenTickIcon from '@assets/icons/green-tick.svg';
export const defaultNotificationContent = {
    title: 'Status Updated',
    body: 'Claim ID CLAIM_ID has been moved from FROM_STATUS to TO_STATUS',
    icon: _jsx("img", { src: purpleDocIcon, alt: "", width: 16, className: "mr-4" })
};
export const statusMap = [
    {
        toClaimStatus: [
            ClaimStatus.CLAIM_APPROVED,
            ClaimStatus.INITIAL_REQUEST_APPROVED,
            ClaimStatus.ENHANCEMENT_REQUEST_APPROVED,
            ClaimStatus.DISCHARGE_REQUEST_APPROVED
        ],
        toPreauthStatus: [PreAuthClaimStatus.APPROVED],
        title: 'Claim Approved',
        body: 'Claim ID CLAIM_ID has been approved',
        icon: _jsx("img", { src: greenTickIcon, alt: "", width: 20 })
    },
    {
        toClaimStatus: [ClaimStatus.CLAIM_REJECTED],
        toPreauthStatus: [PreAuthClaimStatus.REJECTED],
        title: 'Claim Rejected',
        body: 'Claim ID CLAIM_ID has been rejected',
        icon: _jsx("img", { src: redCrossIcon, alt: "", width: 20 })
    },
    {
        toClaimStatus: [ClaimStatus.CLAIM_SETTLED],
        toPreauthStatus: [],
        title: 'Claim Settled',
        body: 'Claim ID CLAIM_ID has been settled',
        icon: _jsx("img", { src: settledIcon, alt: "", width: 16 })
    },
    {
        toClaimStatus: [ClaimStatus.MISSING_INFO],
        toPreauthStatus: [PreAuthClaimStatus.MISSING_INFO_REQUESTED],
        title: 'Missing Info Needed',
        body: 'Missing info has been raised against claim ID CLAIM_ID',
        icon: _jsx("img", { src: missingInfoIcon, alt: "", width: 20, className: "mr-3" })
    }
];
export const DEFAULT_NOTIFICATION_PAGE_SIZE = '30';
