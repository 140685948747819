import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import ToastWrapper from '@components/ToastWrapper';
import FullScreenLoader from '@components/Loader';
import { store } from '@redux/store';
import App from '@layouts/App';
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <StrictMode>
    <ToastWrapper />
    <BrowserRouter>
      <Provider store={store}>
        <FullScreenLoader />
        <App />
      </Provider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
