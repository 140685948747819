import { COMMON_ACTION_TYPES } from './ActionTypes';
export const showLoader = () => ({
    type: COMMON_ACTION_TYPES.SHOW_LOADER
});
export const hideLoader = () => ({
    type: COMMON_ACTION_TYPES.HIDE_LOADER
});
export const setNotificaitons = (payload) => ({
    type: COMMON_ACTION_TYPES.SET_NOTIFICATIONS,
    payload
});
export const setUnreadNotificationsCount = (payload) => ({
    type: COMMON_ACTION_TYPES.SET_UNREAD_NOTIFICATIONS_COUNT,
    payload
});
