export var MissingInfoStatusEnum;
(function (MissingInfoStatusEnum) {
    MissingInfoStatusEnum["PENDING"] = "PENDING";
    MissingInfoStatusEnum["CLOSED"] = "CLOSED";
    MissingInfoStatusEnum["UPLOADED"] = "UPLOADED";
})(MissingInfoStatusEnum || (MissingInfoStatusEnum = {}));
export const MissingInfoStatusMap = {
    [MissingInfoStatusEnum.PENDING]: 'Pending',
    [MissingInfoStatusEnum.CLOSED]: 'Resolved',
    [MissingInfoStatusEnum.UPLOADED]: 'Uploaded'
};
export var KycDocumentsType;
(function (KycDocumentsType) {
    KycDocumentsType["DRIVING_LICENSE"] = "DRIVING_LICENSE";
    KycDocumentsType["AADHAR_CARD"] = "AADHAR_CARD";
    KycDocumentsType["VOTER_ID_CARD"] = "VOTER_ID_CARD";
})(KycDocumentsType || (KycDocumentsType = {}));
export var ClaimReason;
(function (ClaimReason) {
    ClaimReason["ILLNESS"] = "ILLNESS";
    ClaimReason["INJURY"] = "INJURY";
    ClaimReason["MATERNITY"] = "MATERNITY";
})(ClaimReason || (ClaimReason = {}));
export var NON_PAYABLE_REASON_BILL;
(function (NON_PAYABLE_REASON_BILL) {
    NON_PAYABLE_REASON_BILL["ROOM_RENT"] = "Room rent Excess";
    NON_PAYABLE_REASON_BILL["NA"] = "NA";
    NON_PAYABLE_REASON_BILL["PROPORTIONATE_DEDUCTION"] = "Proportionate deduction";
    NON_PAYABLE_REASON_BILL["NME"] = "NME";
    NON_PAYABLE_REASON_BILL["UCR"] = "Usual Consumer and Reasonable";
    NON_PAYABLE_REASON_BILL["NO_DOCUMENTS"] = "No Documents";
    NON_PAYABLE_REASON_BILL["PRE_AUTH"] = "Pre-auth deduction";
})(NON_PAYABLE_REASON_BILL || (NON_PAYABLE_REASON_BILL = {}));
