import { USER_API_BASE_URL } from '@const/ApiConstants';
import { toSearchParams } from '@utils/';
import { ApiService } from './ApiService';
const ENDPOINTS = {
    FETCH_USER_DETAILS: {
        endpoint: () => `${USER_API_BASE_URL}/users/info`
    },
    USER_LIST: {
        endpoint: () => `${USER_API_BASE_URL}/users?includeInactiveUsers=false`
    },
    DEACTIVATE_USER: {
        endpoint: (userId) => `${USER_API_BASE_URL}/users/${userId}`
    }
};
export const getUserDetails = () => {
    return ApiService.get(ENDPOINTS.FETCH_USER_DETAILS.endpoint());
};
export const fetchUserList = (query) => {
    return ApiService.get(`${ENDPOINTS.USER_LIST.endpoint()}&${toSearchParams(query)}`);
};
export const deactivateUserApi = (userId) => {
    return ApiService.delete(ENDPOINTS.DEACTIVATE_USER.endpoint(userId));
};
