import { ACTION_TYPES } from '@redux/ReduxActionTypes';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { getErrorMessage } from '@utils/';
import { getPolicyDetails, getPolicyStatus } from '@services/PolicyApi';
import { getClaimDetails, getClaimMasterValue, getDraftPreAuthClaim } from '@services/ClaimApi';
import { ClaimMasterListEnum } from '@enums/ClaimMasterListEnum';
import { STATUS_CODE } from '@const/ApiConstants';
import { hideLoader, showLoader } from '../common/common.actions';
const { CLAIM_JOURNEY_ACTION_TYPES } = ACTION_TYPES;
const ActionMasterValueMap = {
    [ClaimMasterListEnum.ADMISSION_TYPE]: CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_MASTER_ADMISSION_TYPE_SUCCESS,
    [ClaimMasterListEnum.CASE_TYPE]: CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_MASTER_CASE_TYPE_SUCCESS,
    [ClaimMasterListEnum.HOSPITALIZATION_TYPE]: CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_MASTER_HOSPITALIZATION_TYPE_SUCCESS,
    [ClaimMasterListEnum.ROOM_TYPE]: CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_MASTER_ROOM_TYPE_SUCCESS
};
export const setPolicyStatusSuccess = (policyStatus) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.SET_POLICY_STATUS_SUCCESS,
    payload: policyStatus
});
export const setPolicyStatusError = (errMessage) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.SET_POLICY_STATUS_ERROR,
    payload: errMessage
});
export const resetPolicyStatus = () => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.RESET_POLICY_STATUS
});
export const resetPolicyStatusError = () => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.RESET_POLICY_STATUS_ERROR
});
export const setPolicyDetailsSuccess = (policyDetails) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.SET_POLICY_DETAIL_SUCCESS,
    payload: policyDetails
});
export const resetPolicyDetails = () => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.RESET_POLICY_DETAILS
});
export const setFetchingPolicyDetails = (isFetching) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.SET_POLICY_DETAILS_FETCHING,
    payload: isFetching
});
export const setClaimDetailsSuccess = (claimDetails) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_DETAIL_SUCCESS,
    payload: claimDetails
});
export const resetClaimDetails = () => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.RESET_CLAIM_DETAILS
});
export const setFetchingClaimDetails = (isFetching) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_DETAILS_FETCHING,
    payload: isFetching
});
export const addDocument = (payload) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.ADD_DOCUMENT,
    payload
});
export const addAllDocuments = (payload) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.ADD_ALL_DOCUMENTS,
    payload
});
export const removeDocument = (documentReferenceId) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.REMOVE_DOCUMENT,
    payload: documentReferenceId
});
export const setDocumentTags = (documentReferenceId, tags) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.SET_DOCUMENT_TAGS,
    payload: { documentReferenceId, tags }
});
export const setSubmitMissingInfoPayload = (submitMissingInfoPayload) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.SET_SUBMIT_MISSING_INFO_PAYLOAD,
    payload: submitMissingInfoPayload
});
export const resetSubmitMissingInfoPayload = () => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.RESET_SUBMIT_MISSING_INFO_PAYLOAD
});
export const resetDocuments = () => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.RESET_DOCUMENTS
});
export const setDraftSelectedAction = (payload) => ({
    type: CLAIM_JOURNEY_ACTION_TYPES.SET_DRAFT_SELECTED_ACTION,
    payload
});
export const getPolicyDetailsRequest = (policyId, showLoaderView = true) => {
    return function (dispatch) {
        showLoaderView && dispatch(showLoader());
        getPolicyDetails(policyId)
            .then((response) => {
            if (response?.error) {
                throw response;
            }
            dispatch(setPolicyDetailsSuccess(response?.data));
        })
            .catch((error) => {
            toast.error(getErrorMessage(error));
            dispatch(resetPolicyDetails());
        })
            .finally(() => {
            showLoaderView && dispatch(hideLoader());
        });
    };
};
export const getPolicyStatusRequest = (policyId, dateOfAdmission, setIsLoading) => {
    return function (dispatch) {
        setIsLoading(true);
        getPolicyStatus(policyId, dateOfAdmission)
            .then((response) => {
            if (response?.data?.error) {
                throw response.data;
            }
            dispatch(resetPolicyStatusError());
            dispatch(setPolicyStatusSuccess(response?.data?.policyStatus));
        })
            .catch((error) => {
            dispatch(resetPolicyStatus());
            dispatch(setPolicyStatusError(getErrorMessage(error)));
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
};
export const resetPolicyDetailsRequest = () => {
    return function (dispatch) {
        dispatch(resetPolicyDetails());
    };
};
export const resetPolicyStatusRequest = () => {
    return function (dispatch) {
        dispatch(resetPolicyStatus());
    };
};
export const getClaimDetailsRequest = (claimId) => {
    return function (dispatch) {
        dispatch(showLoader());
        getClaimDetails(claimId)
            .then((response) => {
            if (response.data?.statusCode === STATUS_CODE.NOT_FOUND || !response.data?.data) {
                throw response.data;
            }
            dispatch(setClaimDetailsSuccess(response.data?.data));
        })
            .catch((error) => {
            toast.error(getErrorMessage(error));
            dispatch(resetClaimDetails());
        })
            .finally(() => {
            dispatch(hideLoader());
        });
    };
};
export const getDraftDetailsRequest = (draftId) => {
    return function (dispatch) {
        dispatch(showLoader());
        getDraftPreAuthClaim(draftId)
            .then((draftResponse) => {
            if (draftResponse.data?.statusCode === STATUS_CODE.NOT_FOUND || !draftResponse.data?.data) {
                throw draftResponse.data;
            }
            const draftData = draftResponse.data.data;
            dispatch(addAllDocuments(draftData.data.documentDetails));
            dispatch(setDraftSelectedAction(draftData.action));
            if (draftData?.claimId) {
                dispatch(showLoader());
                getClaimDetails(draftData.claimId)
                    .then((claimResponse) => {
                    if (claimResponse.data?.statusCode === STATUS_CODE.NOT_FOUND ||
                        !claimResponse.data?.data) {
                        throw claimResponse.data;
                    }
                    dispatch(setClaimDetailsSuccess({
                        ...claimResponse.data.data,
                        ailmentDetailsDto: {
                            ...claimResponse.data.data.ailmentDetailsDto,
                            ...draftData.data.ailmentDetailsDto
                        },
                        claimInfo: {
                            ...claimResponse.data.data.claimInfo,
                            ...draftData.data.claimInfo
                        },
                        kycDocumentDetails: [...draftData.data.kycDocumentDetails],
                        patientInfo: {
                            ...claimResponse.data.data.patientInfo,
                            ...draftData.data.patientInfo
                        },
                        documentDetails: [
                            ...claimResponse.data.data.documentDetails // for viewing already uploaded documents
                        ]
                    }));
                })
                    .finally(() => dispatch(hideLoader()));
            }
            else {
                dispatch(setClaimDetailsSuccess({
                    ...draftResponse.data.data?.data,
                    documentDetails: []
                }));
            }
        })
            .catch((error) => {
            toast.error(getErrorMessage(error));
            dispatch(resetClaimDetails());
        })
            .finally(() => {
            dispatch(hideLoader());
        });
    };
};
export const resetClaimDetailsRequest = () => {
    return async function (dispatch) {
        dispatch(resetClaimDetails());
    };
};
export const setClaimMasterValueSuccess = (masterData, masterType) => {
    return {
        type: ActionMasterValueMap[masterType],
        payload: masterData
    };
};
export const getClaimMasterDataRequest = (masterType) => {
    return function (dispatch) {
        dispatch(setFetchingClaimDetails(true));
        getClaimMasterValue(masterType)
            .then((response) => {
            if (response?.data?.error || response?.data?.message) {
                throw response;
            }
            dispatch(setClaimMasterValueSuccess(response?.data, masterType));
        })
            .catch((error) => {
            toast.error(getErrorMessage(error));
        })
            .finally(() => {
            dispatch(setFetchingClaimDetails(false));
        });
    };
};
