import { format, ISODateFormatTimeSec } from '@utils/Date';
import { CANCELLED_CHEQUE } from './constants';
import { DocumentStatus, HospitalPhoneLabel } from './types';
export const getUpdateContactDetailsPayload = (data) => {
    const { financeManager, hospitalContact, managingDirector, operationsHead, tpaCoordinator } = data;
    return {
        email: hospitalContact?.email,
        website: hospitalContact?.website,
        contactNumbers: [
            {
                number: hospitalContact?.phoneNumber,
                label: HospitalPhoneLabel.HOSPITAL_TELEPHONE
            },
            {
                number: managingDirector?.phoneNumber,
                label: HospitalPhoneLabel.CEO
            },
            {
                number: tpaCoordinator?.phoneNumber,
                label: HospitalPhoneLabel.TPA_COORDINATOR
            },
            {
                number: financeManager?.phoneNumber,
                label: HospitalPhoneLabel.FINANCE_MANAGER
            },
            {
                number: operationsHead?.phoneNumber,
                label: HospitalPhoneLabel.HEAD_OF_OPERATIONS
            }
        ],
        ceoName: managingDirector?.name,
        ceoEmailId: managingDirector?.email,
        financeManagerName: financeManager?.name,
        financeManagerEmailId: financeManager?.email,
        headOfOperationsName: operationsHead?.name,
        headOfOperationsEmailId: operationsHead?.email,
        tpaCoordinatorName: tpaCoordinator?.name,
        tpaCoordinatorEmailId: tpaCoordinator?.email
    };
};
export const getUpdateAccountDetailsPayload = (data) => {
    const { bankName, bankAddress, ifscCode, accountNumber, accountType, chequePayableName, panNumber, document } = data;
    return {
        bankName,
        bankAddress,
        ifsCode: ifscCode,
        bankAccountNumber: accountNumber,
        accountType,
        chequePayableName,
        cancelledChequeReferenceId: document?.documentReferenceId,
        panNumber
    };
};
export const getPhoneNumber = (phoneNumbers, label) => phoneNumbers?.filter((item) => item?.label === label)?.[0]?.number;
export const getContactDetailsInitialValues = (hospitalDetails) => {
    const { tpaCoordinatorEmailId, tpaCoordinatorName, headOfOperationsEmailId, headOfOperationsName, financeManagerEmailId, financeManagerName, ceoEmailId, ceoName, email, website, phoneNumbers } = hospitalDetails || {};
    return {
        hospitalContact: {
            phoneNumber: getPhoneNumber(phoneNumbers, HospitalPhoneLabel.HOSPITAL_TELEPHONE),
            email: email,
            website: website
        },
        managingDirector: {
            name: ceoName,
            phoneNumber: getPhoneNumber(phoneNumbers, HospitalPhoneLabel.CEO),
            email: ceoEmailId
        },
        financeManager: {
            name: financeManagerName,
            phoneNumber: getPhoneNumber(phoneNumbers, HospitalPhoneLabel.FINANCE_MANAGER),
            email: financeManagerEmailId
        },
        operationsHead: {
            name: headOfOperationsName,
            phoneNumber: getPhoneNumber(phoneNumbers, HospitalPhoneLabel.HEAD_OF_OPERATIONS),
            email: headOfOperationsEmailId
        },
        tpaCoordinator: {
            name: tpaCoordinatorName,
            phoneNumber: getPhoneNumber(phoneNumbers, HospitalPhoneLabel.TPA_COORDINATOR),
            email: tpaCoordinatorEmailId
        }
    };
};
export const getAccountDetailsInitialValues = (hospitalDetails) => {
    const { bankAccountNumber, bankAddress, bankName, panNumber, chequePayableName, accountType, ifsCode, cancelledChequeReferenceId } = hospitalDetails || {};
    return {
        bankName: bankName,
        bankAddress: bankAddress,
        ifscCode: ifsCode,
        accountNumber: bankAccountNumber,
        accountType: accountType,
        chequePayableName: chequePayableName,
        panNumber: panNumber,
        document: {
            documentName: CANCELLED_CHEQUE,
            documentReferenceId: cancelledChequeReferenceId
        }
    };
};
export const getHospitalDocumentsPayload = (documents) => {
    const hospitalDocuments = documents?.map(item => {
        return item.status === DocumentStatus.PENDING
            ? {
                documentReferenceId: item.documentReferenceId,
                tag: item.tag,
                fileName: item.fileName,
                validFrom: format(item.validFrom, ISODateFormatTimeSec),
                validTill: format(item.validTill, ISODateFormatTimeSec)
            }
            : item;
    });
    return {
        hospitalDocuments: hospitalDocuments
    };
};
