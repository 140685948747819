export const setCookie = (expiry, value = '', name = 'token') => {
    let expires = '';
    if (expiry) {
        const newDate = new Date(expiry * 1000);
        expires = '; expires='.concat(newDate.toUTCString());
    }
    document.cookie = name.concat(`= ${value} ${expires} ; path=/; secure`);
};
export const getCookie = (name = 'token') => {
    const nameEQ = name.concat('=');
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length);
    }
    return '';
};
export const deleteCookie = (name = 'token') => {
    document.cookie = name.concat('=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;');
};
