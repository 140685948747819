import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Fallback, NotFound, RequireAuth } from '@common/components';
import routes from './routes';
import Nav from '@common/components/Nav';
const Login = lazy(() => import('@layouts/Login'));
const App = () => {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/login", element: _jsx(Suspense, { fallback: _jsx(Fallback, {}), children: _jsx(Login, {}) }) }), _jsx(Route, { element: _jsx(Nav, { children: _jsx(RequireAuth, {}) }), children: routes.map(component => (_jsx(Route, { path: component?.path, element: _jsx(React.Suspense, { fallback: _jsx(Fallback, {}), children: component?.element }) }, component?.id))) }), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }));
};
export default App;
