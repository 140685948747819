import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CloseIcon, SearchIcon } from '@navi/web-ui/lib/icons';
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { BorderedInput, Button } from '@navi/web-ui/lib/primitives';
import { getFilteredListAPI, setDashBoardUrl } from '@redux/globalFilter/globalFilter.actions';
import { getDashBoardUrl } from '@redux/globalFilter/globalFilter.selectors';
import { useAppSelector } from '@redux/hooks';
import { ErrValidationMessage } from '@const/Validations';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ENTER_KEY } from '@const/Symbols';
import CustomSelectInput from '../CustomSelectInput';
import AlertOutlineIcon from '@assets/icons/AlertOutlineIcon';
import { SideDrawer } from '../SideDrawer';
import NewClaimForm from '@src/pages/ClaimJourney/partials/NewClaimForm';
const defaultSearchOptions = [
    {
        label: 'Claim Id',
        value: 'claimId'
    },
    {
        label: 'Policy Id',
        value: 'policyId'
    },
    {
        label: 'Member Id',
        value: 'memberId'
    },
    {
        label: 'IP Number',
        value: 'ipNumber'
    },
    {
        label: 'Patient Name',
        value: 'patientName'
    }
];
const SearchBar = props => {
    const { searchValue = '', searchBy = '', searchPage = false, homePage = false } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchText, setSearchText] = React.useState(searchValue);
    const [searchByValue, setSearchByValue] = React.useState(searchBy);
    const [open, setOpen] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const dashboardUrl = useAppSelector(getDashBoardUrl);
    const validateSearchParams = () => {
        let err = {};
        if (!searchByValue)
            err = { searchByValue: ErrValidationMessage.REQUIRED };
        if (!searchText)
            err = { ...err, searchText: ErrValidationMessage.REQUIRED };
        setErrors(err);
        return err;
    };
    const fetchClaims = () => {
        const err = validateSearchParams();
        if (Object.keys(err).length)
            return;
        if (searchPage) {
            dispatch(getFilteredListAPI({ [searchByValue]: searchText, page: 0, size: 10 }));
        }
        else {
            dispatch(setDashBoardUrl(location.pathname));
        }
        navigate(`/search?searchBy=${searchByValue}&searchValue=${searchText}`);
    };
    const onKeyPressClickHandler = (event) => {
        if (event.key === ENTER_KEY) {
            fetchClaims();
        }
    };
    return (_jsx(GridContainer, { className: "mt-6", children: _jsxs(GridRow, { className: "items-center justify-between", children: [_jsxs(GridColumn, { className: "flex items-center w-fit", children: [_jsx(CustomSelectInput, { placeholder: "Search By", inputClassName: "w-60 rounded-r-none mb-[5px]", selectPickerClassName: "min-w-fit", value: searchByValue, setSelectedValue: (value) => setSearchByValue(value), inputSize: "medium", options: defaultSearchOptions, multiSelect: false, error: errors.searchByValue && !searchByValue ? errors.searchByValue : '', readOnly: true, helperTextClass: "absolute" }), _jsx(BorderedInput, { inputSize: "medium", placeholder: "Search", value: searchText, onChange: e => setSearchText(e.target.value), onKeyPress: onKeyPressClickHandler, containerClassName: "rounded-none ml-0.5 mb-[5px]", error: errors.searchText && !searchText ? errors.searchText : '', LeftInputAdornment: _jsx(SearchIcon, {}), RightInputAdornment: searchText && (_jsx(CloseIcon, { className: "cursor-pointer", onClick: () => setSearchText('') })), helperTextClasses: "absolute", Icon: _jsx(AlertOutlineIcon, {}) }), _jsx(GridRow, { className: "w-12 h-[38px] rounded-r-lg mx-px bg-primary-blue flex items-center justify-center cursor-pointer mt-[3px]", onClick: fetchClaims, children: _jsx(SearchIcon, { color: "white", width: 24 }) })] }), searchPage && (_jsx(GridColumn, { onClick: () => navigate(dashboardUrl), className: "flex items-center justify-end font-normal text-sm underline cursor-pointer text-primary-blue w-fit", children: "Go to Dashboard" })), homePage && (_jsxs(_Fragment, { children: [_jsx(GridColumn, { className: "flex items-center justify-end mt-1 w-fit", children: _jsx(Button, { size: "medium", color: "primary", onClick: () => setOpen(true), children: "Create new claim" }) }), _jsx(SideDrawer, { open: open, onClose: () => setOpen(false), headerText: "Create new claim", children: _jsx(NewClaimForm, {}) })] }))] }) }));
};
export default SearchBar;
