import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
export const ISODateFormat = 'YYYY-MM-DD';
export const ISODateFormatTime = 'YYYY-MM-DD HH:mm';
export const ISODateFormatTimeSec = 'YYYY-MM-DD HH:mm:ss';
export const ISOTimeFormat = 'HH:mm';
export const BusinessDateFormat = 'DD-MM-YYYY';
export const BusinessDateFormatTime = 'DD-MM-YYYY HH:mm';
const MILLI_IN_SEC = 1000;
const SEC_IN_HOUR = 3600;
const HOUR_IN_DAY = 24;
export function getBusinessDateFormatTime(separator = '-', isTime = false) {
    const dateFormat = isTime ? BusinessDateFormatTime : BusinessDateFormat;
    return dateFormat.split('-').join(separator);
}
export const format = (time, formatType) => time && dayjs(time).format(formatType);
export const ISODate = (epochTime) => format(epochTime, ISODateFormat);
export const getFormattedDate = (date, inputDateFormat, outputDateFormat = BusinessDateFormat) => {
    if (!date)
        return;
    return dayjs(date, inputDateFormat).format(outputDateFormat);
};
export const getTimeDifference = (startDateTime, endDateTime) => {
    if (!startDateTime)
        return;
    const diffInDays = dayjs(endDateTime).diff(startDateTime, 'day');
    const diffInHours = dayjs(endDateTime).diff(startDateTime, 'hour');
    const diffInMinutes = dayjs(endDateTime).diff(startDateTime, 'minute');
    let duration = '';
    if (diffInDays)
        duration += `${diffInDays} Days `;
    if (diffInHours)
        duration += `${diffInHours - diffInDays * 24} Hours `;
    if (diffInMinutes)
        duration += `${diffInMinutes - diffInHours * 60} Minutes `;
    return duration;
};
export const getDateDiffInDays = (d1, d2, roundForQuarter = false) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const time = date2.getTime() - date1.getTime();
    const days = time / (MILLI_IN_SEC * SEC_IN_HOUR * HOUR_IN_DAY);
    if (roundForQuarter) {
        // Subtracting 0.25 for rounding it to 1 ( 1/4 which is 6 hours )
        return days >= 0.25 ? Math.ceil(days - 0.25) : 0;
    }
    return Math.ceil(days);
};
export const fromNow = (date) => {
    if (!date)
        return '';
    return dayjs(date).fromNow();
};
