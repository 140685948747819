import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AgTable } from '@navi/web-ui/lib/components';
import { missingInfoColumnDefinitions } from '../constants';
import { Card, Typography } from '@navi/web-ui/lib/primitives';
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
const ViewMissingInfo = props => {
    const { missingInfoList } = props;
    const defaultColDef = {
        cellStyle: {
            lineHeight: 2
        },
        wrapText: true,
        autoHeight: true
    };
    return (_jsx(Card, { className: "custom-card py-5", children: _jsx(GridContainer, { children: _jsx(GridRow, { children: _jsxs(GridColumn, { className: "pb-8", children: [_jsx(Typography, { variant: "h2", className: "text-regal-grey mb-6", children: "Missing Info List" }), missingInfoList?.length ? (_jsx(AgTable, { columnDefs: missingInfoColumnDefinitions, rowData: missingInfoList, theme: "alpine", sizeColumnsToFit: true, defaultColDef: defaultColDef, domLayout: "autoHeight" })) : (_jsx(Typography, { variant: "p3", className: "text-center py-[34px] bg-regal-blue-40 text-primary-grey", children: "No Missing Infos to show" }))] }) }) }) }));
};
export default ViewMissingInfo;
