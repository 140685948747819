import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import SearchBar from '@components/SearchBar';
import DashboardHeader from './partials/DashboardHeader';
import ClosedClaimsChart from './partials/ClosedClaimsChart';
import ClaimDetails from './partials/ClaimDetails';
import ClaimsProgressDetails from './partials/ClaimsProgressDetails';
import { getHomePageClaimDetails } from '@services/ClaimApi';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '@redux/common/common.actions';
import { useAppSelector } from '@redux/hooks';
import { selectLoginUserGroups } from '@redux/userDetails/userDetails.selector';
import PendingActionTickets from './partials/PendingActionTickets';
import { STATUS_CODE } from '@const/ApiConstants';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { getErrorMessage } from '@utils/';
const Dashboard = () => {
    const [homePageDetails, setHomePageDetails] = React.useState({});
    const groups = useAppSelector(selectLoginUserGroups);
    const hospitalId = groups[0]?.toUpperCase();
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (hospitalId) {
            dispatch(showLoader());
            getHomePageClaimDetails(hospitalId)
                .then((response) => {
                if (response.data?.statusCode === STATUS_CODE.OK) {
                    setHomePageDetails(response.data?.data);
                    return;
                }
                throw response;
            })
                .catch((error) => toast.error(getErrorMessage(error)))
                .finally(() => {
                dispatch(hideLoader());
            });
        }
    }, [groups]);
    return (_jsxs(GridContainer, { children: [_jsx(SearchBar, { homePage: true }), _jsxs(GridRow, { children: [_jsx(GridColumn, { lg: 9, children: _jsx(DashboardHeader, { hospitalId: hospitalId }) }), _jsx(GridColumn, { lg: 3, children: _jsx(ClosedClaimsChart, { homePageDetails: homePageDetails }) })] }), _jsx(GridRow, { className: "my-2", children: _jsx(ClaimDetails, { hospitalId: hospitalId }) }), _jsxs(GridRow, { className: "mb-2", children: [_jsxs(GridColumn, { xs: 7, children: [_jsx(GridRow, { className: "m-0", children: _jsx(ClaimsProgressDetails, { title: "Pre-Authorised Claims", claimsData: homePageDetails.preAuthClaimsInPercentage, color: "#00BA34", isPreAuth: true }) }), _jsx(GridRow, { className: "m-0 my-6", children: _jsx(ClaimsProgressDetails, { title: "Network Reimbursement Claims", claimsData: homePageDetails.networkReimbursementClaimsInPercentage, color: "#0276FE" }) })] }), _jsx(GridColumn, { xs: 5, children: _jsx(GridRow, { className: "m-0", children: _jsx(PendingActionTickets, { hospitalId: hospitalId }) }) })] })] }));
};
export default Dashboard;
