import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography } from '@navi/web-ui/lib/primitives';
import React from 'react';
import phoneIcon from '@assets/icons/phone-icon.svg';
import mailIcon from '@assets/icons/mail-icon.svg';
import globeIcon from '@assets/icons/globe-icon.svg';
import contactIllustration from '@assets/icons/hospital-contact-illustration.svg';
import dashedLine from '@assets/icons/grey-dashed-line.svg';
import { EditIcon } from '@navi/web-ui/lib/icons';
import EditContactDetails from './EditContactDetails';
import { HospitalPhoneLabel } from '../types';
import { DEFAULT_TEXT } from '@const/Symbols';
import { getPhoneNumber } from '../utils';
import { GridColumn, GridRow } from '@navi/web-ui/lib/layouts/Grid';
const HospitalContactItem = (props) => {
    const { hospitalDetails } = props;
    const { phoneNumbers, email, website } = hospitalDetails || {};
    const phoneNumber = getPhoneNumber(phoneNumbers, HospitalPhoneLabel.HOSPITAL_TELEPHONE);
    return (_jsxs("div", { className: "custom-card py-7 w-1/2 flex justify-between items-center", children: [_jsxs("div", { className: "pl-9", children: [_jsxs("div", { className: "flex mb-6", children: [_jsx("img", { src: phoneIcon, alt: "phone", className: "mr-3" }), _jsx(Typography, { variant: "h3", className: "text-regal-grey font-medium", children: phoneNumber ?? DEFAULT_TEXT })] }), _jsxs("div", { className: "flex mb-6", children: [_jsx("img", { src: mailIcon, alt: "mail", className: "mr-3" }), _jsx(Typography, { variant: "h3", className: "text-regal-grey font-medium", children: email ?? DEFAULT_TEXT })] }), _jsxs("div", { className: "flex", children: [_jsx("img", { src: globeIcon, alt: "globe", className: "mr-3" }), website ? (_jsx(Button, { variant: "text", className: 'underline', onClick: () => window.open(website.includes('https://') ? website : `https://${website}`), children: website })) : (_jsx(Typography, { variant: "h3", children: DEFAULT_TEXT }))] })] }), _jsx("img", { src: contactIllustration, alt: "", className: "pr-9" })] }));
};
const OtherContactItem = (props) => {
    const { details } = props;
    return (_jsxs(GridRow, { className: "custom-card flex justify-between w-[49%] m-0 py-6 px-6 mb-6", children: [_jsx(GridColumn, { xs: 7, className: "p-0", children: _jsx(Typography, { variant: "h3", children: details?.label }) }), _jsxs(GridColumn, { xs: 5, className: "p-0", children: [_jsx(Typography, { variant: "h3", className: "mb-4 text-ellipsis text-regal-grey font-medium", children: details?.name ?? DEFAULT_TEXT }), _jsxs("div", { className: "flex mb-4", children: [_jsx("img", { src: phoneIcon, alt: "phone", className: "mr-3", width: 13 }), _jsx(Typography, { variant: "h4", className: "text-regal-grey font-medium", children: details?.phoneNumber ?? DEFAULT_TEXT })] }), _jsxs("div", { className: "flex", children: [_jsx("img", { src: mailIcon, alt: "mail", className: "mr-3", width: 15 }), _jsx(Typography, { variant: "h4", className: "text-ellipsis font-medium text-regal-grey", children: details?.email ?? DEFAULT_TEXT })] })] })] }));
};
const ContactDetails = props => {
    const { hospitalDetails, setHospitalDetails } = props;
    const [isEditContactDetailsOpen, setIsEditContactDetailsOpen] = React.useState(false);
    const { phoneNumbers, ceoName, ceoEmailId, financeManagerName, financeManagerEmailId, headOfOperationsName, headOfOperationsEmailId, tpaCoordinatorName, tpaCoordinatorEmailId } = hospitalDetails || {};
    const contactDetails = [
        {
            name: ceoName,
            phoneNumber: getPhoneNumber(phoneNumbers, HospitalPhoneLabel.CEO),
            email: ceoEmailId,
            label: 'Managing Director'
        },
        {
            name: tpaCoordinatorName,
            phoneNumber: getPhoneNumber(phoneNumbers, HospitalPhoneLabel.TPA_COORDINATOR),
            email: tpaCoordinatorEmailId,
            label: 'TPA Co-ordinator'
        },
        {
            name: financeManagerName,
            phoneNumber: getPhoneNumber(phoneNumbers, HospitalPhoneLabel.FINANCE_MANAGER),
            email: financeManagerEmailId,
            label: 'Finance Manager'
        },
        {
            name: headOfOperationsName,
            phoneNumber: getPhoneNumber(phoneNumbers, HospitalPhoneLabel.HEAD_OF_OPERATIONS),
            email: headOfOperationsEmailId,
            label: 'Head of Operations'
        }
    ];
    return (_jsxs("div", { className: "mt-14", children: [_jsxs(Typography, { variant: "h2", className: "mb-6 text-regal-grey font-semibold", children: ["Hospital Contact Details", _jsx(Button, { startAdornment: _jsx(EditIcon, { color: "#0276FE" }), variant: "secondary", onClick: () => setIsEditContactDetailsOpen(true), className: "ml-6 text-primary-blue border-primary-blue", 
                        // Remove disabled once Ops Portal flow is completed
                        disabled: true, children: "Edit" })] }), _jsx(EditContactDetails, { open: isEditContactDetailsOpen, onClose: () => setIsEditContactDetailsOpen(false), hospitalDetails: hospitalDetails, setHospitalDetails: setHospitalDetails }), _jsx(HospitalContactItem, { hospitalDetails: hospitalDetails }), _jsxs("div", { className: "flex mt-10 mb-6", children: [_jsx(Typography, { variant: "p1", className: "text-regal-grey mr-6", children: "Other Contact Details" }), _jsx("img", { src: dashedLine, alt: "dashed line" })] }), _jsx("div", { className: "flex flex-wrap justify-between", children: contactDetails?.map((details, index) => (_jsx(OtherContactItem, { details: details }, index))) })] }));
};
export default ContactDetails;
