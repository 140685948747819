import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Button, Card, Typography } from '@navi/web-ui/lib/primitives';
import HospitalHomepageIllustration from '@assets/icons/HospitalHomepageIllustration.svg';
import React from 'react';
import { getHospitalDetails } from '@services/HospitalApi';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '@redux/common/common.actions';
import { STATUS_CODE } from '@const/ApiConstants';
import { getErrorMessage } from '@utils/';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { DEFAULT_TEXT } from '@const/Symbols';
import { useNavigate } from 'react-router-dom';
import { ROUTE_MAPPING } from '@const/Routes';
import { DashboardBuckets } from '@pages/PreAuth/constants';
const DashboardHeader = (props) => {
    const { hospitalId } = props;
    const [hospitalDetails, setHospitalDetails] = React.useState({
        rohinicode: '',
        hospitalName: ''
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (hospitalId) {
            dispatch(showLoader());
            getHospitalDetails(hospitalId)
                .then((response) => {
                if (response.data?.statusCode === STATUS_CODE.OK) {
                    setHospitalDetails(response.data?.data);
                    return;
                }
                throw response;
            })
                .catch((error) => {
                toast.error(getErrorMessage(error));
            })
                .finally(() => {
                dispatch(hideLoader());
            });
        }
    }, [hospitalId]);
    return (_jsx(GridContainer, { children: _jsx(Card, { className: `bg-regal-blue bg-opacity-40 w p-0 rounded-xl homepage-custom-card`, children: _jsxs(GridRow, { className: "pt-6 px-8 m-0", children: [_jsxs(GridColumn, { xs: 8, className: "p-0 pt-[6px] pb-1", children: [_jsxs(Typography, { variant: "p1", className: "text-[32px] font-semibold", color: "#025ECB", children: ["Hi, ", hospitalDetails?.hospitalName] }), _jsxs(GridRow, { className: "mx-0 my-3", children: [_jsxs(Typography, { className: "text-primary-grey", variant: "p2", as: "span", children: ["Hospital ID:\u00A0", _jsx(Typography, { className: "text-regal-grey", variant: "p2", as: "span", children: hospitalId ?? DEFAULT_TEXT })] }), _jsxs(Typography, { className: "text-primary-grey ml-8", variant: "p2", as: "span", children: ["Rohini Code:\u00A0", _jsx(Typography, { className: "text-regal-grey", variant: "p2", as: "span", children: hospitalDetails?.rohinicode ?? DEFAULT_TEXT })] })] }), _jsx(Button, { variant: "secondary", className: "mt-6 text-primary-blue border-primary-blue rounded", onClick: () => navigate(`${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.PRE_AUTH_PENDING_WITH_NAVI}`), children: "Go to Dashboard" })] }), _jsx(GridColumn, { xs: 4, className: "flex justify-center", children: _jsx("img", { src: HospitalHomepageIllustration, className: "w-[215px] mt-[-39px]" }) })] }) }) }));
};
export default DashboardHeader;
