import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SelectPicker } from '@navi/web-ui/lib/components';
import { ArrowDownSolidIcon } from '@navi/web-ui/lib/icons';
import { Button } from '@navi/web-ui/lib/primitives';
import { noop } from '@navi/web-ui/lib/utils/common';
import React from 'react';
const CustomMultiSelect = props => {
    const { title, handleChange, options, selectedValues } = props;
    const [isPickerOpen, setIsPickerOpen] = React.useState(false);
    const togglePicker = () => {
        setIsPickerOpen(prev => !prev);
    };
    const closePicker = () => {
        setIsPickerOpen(false);
    };
    return (_jsxs("div", { className: "relative", children: [_jsx(Button, { variant: "secondary", endAdornment: _jsx(ArrowDownSolidIcon, { className: "w-2.5 h-2.5 ml-2" }), onClick: togglePicker, children: title }), isPickerOpen && (_jsx(SelectPicker, { style: { position: 'absolute', width: '320px', left: 0, zIndex: 1 }, handleApplyClick: e => {
                    closePicker();
                    handleChange(e);
                }, handleCancelClick: closePicker, multiSelect: true, onSelectionChange: noop, options: options, selectedValues: selectedValues, showSearchBar: true }))] }));
};
export default CustomMultiSelect;
