export var HospitalReason;
(function (HospitalReason) {
    HospitalReason["ILLNESS"] = "ILLNESS";
    HospitalReason["INJURY"] = "INJURY";
    HospitalReason["MATERNITY"] = "MATERNITY";
})(HospitalReason || (HospitalReason = {}));
export var DocumentCategory;
(function (DocumentCategory) {
    DocumentCategory["INITIAL"] = "INITIAL";
    DocumentCategory["MISSING_INFO"] = "MISSING_INFO";
    DocumentCategory["ADDITIONAL"] = "ADDITIONAL";
    DocumentCategory["CONSOLIDATED"] = "CONSOLIDATED";
})(DocumentCategory || (DocumentCategory = {}));
export var PreAuthClaimType;
(function (PreAuthClaimType) {
    PreAuthClaimType["INITIAL"] = "Initial";
    PreAuthClaimType["DISCHARGE"] = "Discharge";
    PreAuthClaimType["INTERIM"] = "Interim";
    PreAuthClaimType["ENHANCEMENT"] = "Enhancement";
})(PreAuthClaimType || (PreAuthClaimType = {}));
export var PreAuthClaimStatus;
(function (PreAuthClaimStatus) {
    PreAuthClaimStatus["CREATED"] = "CREATED";
    PreAuthClaimStatus["PENDING_ADJUDICATION"] = "PENDING_ADJUDICATION";
    PreAuthClaimStatus["APPROVED"] = "APPROVED";
    PreAuthClaimStatus["REJECTED"] = "REJECTED";
    PreAuthClaimStatus["PENDING_FOR_EXPERT_MEDICAL_OPINION"] = "PENDING_FOR_EXPERT_MEDICAL_OPINION";
    PreAuthClaimStatus["MISSING_INFO_REQUESTED"] = "MISSING_INFO_REQUESTED";
    PreAuthClaimStatus["PENDING_INTERNAL_PROCESSING"] = "PENDING_INTERNAL_PROCESSING";
    PreAuthClaimStatus["CANCELLED"] = "CANCELLED";
})(PreAuthClaimStatus || (PreAuthClaimStatus = {}));
export var ClaimRequestType;
(function (ClaimRequestType) {
    ClaimRequestType["PRE_AUTH"] = "PRE_AUTH";
    ClaimRequestType["MAIN_CLAIM"] = "MAIN_CLAIM";
})(ClaimRequestType || (ClaimRequestType = {}));
export var DocumentLetterMapping;
(function (DocumentLetterMapping) {
    DocumentLetterMapping["PRE_AUTH_APPROVAL_LETTER"] = "Initial Enhancement Pre Auth Approval Letter";
    DocumentLetterMapping["PRE_AUTH_DISCHARGE_APPROVAL_LETTER"] = "Discharge Pre Auth Approval Letter";
    DocumentLetterMapping["CLAIM_APPROVAL_LETTER"] = "Claim Approval Letter";
    DocumentLetterMapping["CLAIM_SETTLEMENT_LETTER"] = "Claim Settlement Letter";
    DocumentLetterMapping["CLAIM_REJECTION_LETTER"] = "Claim Rejection Letter";
    DocumentLetterMapping["PRE_AUTH_REJECTION_LETTER"] = "Pre Auth Rejection Letter";
    DocumentLetterMapping["CLAIM_MISSING_INFO_LETTER"] = "Claim Missing Info Letter";
    DocumentLetterMapping["PRE_AUTH_CLAIM_MISSING_INFO_LETTER"] = "Pre Auth Claim Missing Info Letter";
})(DocumentLetterMapping || (DocumentLetterMapping = {}));
export var DocumentTags;
(function (DocumentTags) {
    DocumentTags["PRE_AUTH_FORM"] = "Pre-auth Form";
    DocumentTags["DOCTOR_REFERRAL_NOTE"] = "Doctor referral note";
    DocumentTags["FIRST_CONSULTATION_DIAGNOSIS_NOTE"] = "First Consultation/Diagnosis Note";
    DocumentTags["OTHER_PRE_HOSPITALIZATION_RECORD"] = "Other pre-hospitalization record";
    DocumentTags["ADMISSION_NOTES"] = "Admission notes";
    DocumentTags["INITIAL_ASSESSMENT_NOTES"] = "Initial assessment notes";
    DocumentTags["KYC_DOCUMENT_FOR_PATIENT"] = "KYC Document for patient";
    DocumentTags["INDOOR_CASE_PAPERS"] = "Indoor case papers";
    DocumentTags["DISCHARGE_SUMMARY"] = "Discharge summary";
    DocumentTags["FINAL_BILL_CONSOLIDATED_BILL"] = "Final Bill/Consolidated Bill";
    DocumentTags["BILL_BREAKUP"] = "Bill breakup";
    DocumentTags["INVESTIGATION_REPORTS"] = "Investigation reports";
    DocumentTags["INDOOR_CASE_PAPERS_ONLY_FOR_MEDICAL_CASE"] = "Indoor case papers (only for medical case)";
})(DocumentTags || (DocumentTags = {}));
export var DocumentUploadedTags;
(function (DocumentUploadedTags) {
    DocumentUploadedTags["PRE_AUTH_DOCUMENTS"] = "Pre-auth documents";
    DocumentUploadedTags["ENHANCEMENT_DOCUMENTS"] = "Enhancement documents";
    DocumentUploadedTags["DISCHARGE_DOCUMENTS"] = "Discharge documents";
    DocumentUploadedTags["NETWORK_REIMBURSEMENT_DOCUMENTS"] = "Network reimbursement documents";
})(DocumentUploadedTags || (DocumentUploadedTags = {}));
export var SelectedActionDocumentTag;
(function (SelectedActionDocumentTag) {
    SelectedActionDocumentTag["REQUEST_DISCHARGE"] = "Discharge documents";
    SelectedActionDocumentTag["NEW_INITIAL"] = "Pre-auth documents";
    SelectedActionDocumentTag["NEW_DISCHARGE"] = "Pre-auth documents";
    SelectedActionDocumentTag["REQUEST_ENHANCEMENT"] = "Enhancement documents";
    SelectedActionDocumentTag["SUBMIT_NETWORK_CLAIM"] = "Network reimbursement documents";
})(SelectedActionDocumentTag || (SelectedActionDocumentTag = {}));
