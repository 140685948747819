import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Card, Typography } from '@navi/web-ui/lib/primitives';
import CustomSelectInput from '@components/CustomSelectInput';
import { ActionOptionMap } from '../constants';
const Action = ({ selectedActionList, selectedAction, setSelectedAction }) => {
    return (_jsx(Card, { className: "custom-card", children: _jsxs(GridContainer, { children: [_jsx(GridRow, { children: _jsx(GridColumn, { xs: 12, children: _jsx(Typography, { className: "text-regal-grey", variant: "h2", children: "Select action to be taken:" }) }) }), _jsx(GridRow, { children: _jsx(GridColumn, { xs: 6, children: _jsx(CustomSelectInput, { placeholder: "Select Action", options: selectedActionList.map(item => ({
                                label: ActionOptionMap[item],
                                value: item
                            })), value: selectedAction, name: "selectedAction", setSelectedValue: setSelectedAction, multiSelect: false, readOnly: true, inputSize: "full-width", required: true }) }) })] }) }));
};
export default Action;
