// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dq88AqK5uMMu2spYnBbK{height:100%;overflow:auto}.q4TjHqbSJmZqvX3FVkki{display:inline-flex;gap:16px;min-width:-moz-max-content;min-width:max-content}.xjnplZjAzW0hzOqyC1E0{margin:24px 0px}", "",{"version":3,"sources":["webpack://./src/common/components/Nav/LeftNav/LeftNav.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,aAAA,CAGF,sBACE,mBAAA,CACA,QAAA,CACA,0BAAA,CACA,qBAAA,CAGF,sBACE,eAAA","sourcesContent":[".page-content-container {\n  height: 100%;\n  overflow: auto;\n}\n\n.header {\n  display: inline-flex;\n  gap: 16px;\n  min-width: -moz-max-content;\n  min-width: max-content;\n}\n\n.dialog-content {\n  margin: 24px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-content-container": "dq88AqK5uMMu2spYnBbK",
	"header": "q4TjHqbSJmZqvX3FVkki",
	"dialog-content": "xjnplZjAzW0hzOqyC1E0"
};
export default ___CSS_LOADER_EXPORT___;
