import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Typography } from '@navi/web-ui/lib/primitives';
import { DEFAULT_TEXT, PERCENTAGE_SYMBOL, RUPEE_SYMBOL } from '@const/Symbols';
import DeductionCard from './DeductionCard';
const ClaimLevelDeduction = (props) => {
    const { deductionDetails, claimedAmount, admissibleAmount } = props;
    return (_jsxs(GridContainer, { className: "mb-14 p-0", children: [_jsx(Typography, { className: "text-primary-grey mb-4", variant: "h3", children: "Claim Level Deductions" }), _jsxs(GridRow, { className: "justify-between m-0", children: [_jsx(DeductionCard, { title: "Claimed Amount", mainAmount: claimedAmount, subHeaderTitle: "Discount Details", tooltipTitle: "Discount remarks", tooltipText: "The discount is purely based on billing details", deductionDetails: [
                            {
                                key: `Hospital Discount (${PERCENTAGE_SYMBOL})`,
                                value: `${deductionDetails.hospitalDiscountPercentage.toFixed(2)}${PERCENTAGE_SYMBOL}` ??
                                    DEFAULT_TEXT
                            },
                            {
                                key: `Hospital Discount (${RUPEE_SYMBOL})`,
                                value: `${RUPEE_SYMBOL} ${deductionDetails.hospitalDiscountAmount.toFixed(2)}` ??
                                    DEFAULT_TEXT
                            },
                            {
                                key: `Other Dicsounts (${RUPEE_SYMBOL})`,
                                value: `${RUPEE_SYMBOL} ${deductionDetails.hospitalOtherDiscount.toFixed(2)}` ??
                                    DEFAULT_TEXT
                            }
                        ] }), _jsx(DeductionCard, { title: "Other details", tooltipTitle: "Deduction remarks", tooltipText: "The deductions are purely based on billing details", deductionDetails: [
                            {
                                key: `Non Medical Expenses (${RUPEE_SYMBOL})`,
                                value: `${RUPEE_SYMBOL} ${deductionDetails.nonMedicalDeduction.toFixed(2)}` ?? DEFAULT_TEXT
                            },
                            {
                                key: `Room Rent Deduction (${RUPEE_SYMBOL})`,
                                value: `${RUPEE_SYMBOL} ${deductionDetails.roomRentDeduction.toFixed(2)}` ?? DEFAULT_TEXT
                            },
                            {
                                key: `Proportionate Deduction (${RUPEE_SYMBOL})`,
                                value: `${RUPEE_SYMBOL} ${deductionDetails.proportionateDeduction.toFixed(2)}` ??
                                    DEFAULT_TEXT
                            },
                            {
                                key: 'UCR',
                                value: `${RUPEE_SYMBOL} ${deductionDetails.ucrDeduction.toFixed(2)}` ?? DEFAULT_TEXT
                            },
                            {
                                key: 'No. of Documents',
                                value: deductionDetails.noDocuments ?? DEFAULT_TEXT
                            }
                        ] }), _jsx(DeductionCard, { title: "Policy Level Deductions", deductionDetails: [
                            {
                                key: `Admissible Amount (${RUPEE_SYMBOL})`,
                                value: `${RUPEE_SYMBOL} ${(admissibleAmount -
                                    deductionDetails?.hospitalOtherDiscount -
                                    deductionDetails?.hospitalDiscountAmount).toFixed(2)}` ?? DEFAULT_TEXT
                            },
                            {
                                key: `Copay (${PERCENTAGE_SYMBOL})`,
                                value: `${deductionDetails.copayPercent.toFixed(2)}${PERCENTAGE_SYMBOL}` ?? DEFAULT_TEXT
                            },
                            {
                                key: `Copay Amount (${RUPEE_SYMBOL})`,
                                value: `${RUPEE_SYMBOL} ${deductionDetails.copayAmount.toFixed(2)}` ?? DEFAULT_TEXT
                            },
                            {
                                key: 'Excess over defined ailment limit',
                                value: DEFAULT_TEXT
                            },
                            {
                                key: 'Deductibles',
                                value: DEFAULT_TEXT
                            },
                            {
                                key: 'Policy Excess of SI',
                                value: DEFAULT_TEXT
                            }
                        ] })] })] }));
};
export default ClaimLevelDeduction;
