import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import LoadingIcon from '@navi/web-ui/lib/icons/LoadingIcon';
import styles from './Loader.module.scss';
import { useAppSelector } from '@src/redux/hooks';
import { selectApisInProgress } from '@redux/common/common.selector';
const FullScreenLoader = () => {
    const apisInProgress = useAppSelector(selectApisInProgress);
    return (_jsx(_Fragment, { children: apisInProgress > 0 ? (_jsx("div", { className: styles['loader-container'], children: _jsx(LoadingIcon, { size: "lg" }) })) : null }));
};
export default FullScreenLoader;
