export const ROUTE_MAPPING = {
    DASHBOARD: '/',
    USER_MANAGEMENT: '/user-management',
    NEW_CLAIM_JOURNEY: '/claim-journey',
    CLAIM_JOURNEY: '/claim-journey/:claimId',
    DRAFT_CLAIM_JOURNEY: '/claim-journey/draft/:draftId',
    SEARCH: '/search',
    PRE_AUTH_LISTING: '/pre-auth',
    NETWORK_REIMBURSEMENT_LISTING: '/network-reimbursement',
    HOSPITAL_DETAILS: '/hospital'
};
