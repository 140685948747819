export const ACTION_TYPES = {
    AUTH_ACTION_TYPES: {
        SET_MOBILE_NUMBER: 'SET_MOBILE_NUMBER',
        SHOW_OTP_SCREEN: 'SHOW_OTP_SCREEN',
        USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
        USER_LOGOUT_ERROR: 'USER_LOGOUT_ERROR',
        GET_OTP_SUCCESS: 'GET_OTP_SUCCESS',
        GET_OTP_ERROR: 'GET_OTP_ERROR',
        VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
        VERIFY_OTP_ERROR: 'VERIFY_OTP_ERROR',
        RESET_AUTH_DETAILS: 'RESET_AUTH_DETAILS',
        SET_IS_USER_LOGGED_IN: 'SET_IS_USER_LOGGED_IN'
    },
    USER_ACTION_TYPES: {
        SET_LOGIN_USER_DETAIL_ERROR: 'SET_LOGIN_USER_DETAIL_ERROR',
        SET_LOGIN_USER_DETAIL_SUCCESS: 'SET_LOGIN_USER_DETAIL_SUCCESS',
        RESET_LOGIN_USER_DETAIL_DATA: 'RESET_LOGIN_USER_DETAIL_DATA',
        SET_USER_LIST_SUCCESS: 'SET_USER_LIST_SUCCESS',
        SET_USER_LIST_ERROR: 'SET_USER_LIST_ERROR',
        SET_USER_LIST_PAGE_DETAILS: 'SET_USER_LIST_PAGE_DETAILS',
        RESET_USER_LIST_DATA: 'RESET_USER_LIST_DATA',
        SET_IS_USER_LIST_PRESENT: 'SET_IS_USER_LIST_PRESENT',
        SET_USER_LIST_FETCHING: 'SET_USER_LIST_FETCHING'
    },
    CLAIM_JOURNEY_ACTION_TYPES: {
        SET_POLICY_STATUS_SUCCESS: 'SET_POLICY_STATUS_SUCCESS',
        SET_POLICY_STATUS_ERROR: 'SET_POLICY_STATUS_ERROR',
        SET_POLICY_DETAIL_SUCCESS: 'SET_POLICY_DETAIL_SUCCESS',
        RESET_POLICY_STATUS: 'RESET_POLICY_STATUS',
        RESET_POLICY_STATUS_ERROR: 'RESET_POLICY_STATUS_ERROR',
        RESET_POLICY_DETAILS: 'RESET_POLICY_DETAILS',
        SET_POLICY_DETAILS_FETCHING: 'SET_POLICY_DETAILS_FETCHING',
        SET_CLAIM_DETAIL_SUCCESS: 'SET_CLAIM_DETAIL_SUCCESS',
        RESET_CLAIM_DETAILS: 'RESET_CLAIM_DETAILS',
        SET_CLAIM_DETAILS_FETCHING: 'SET_CLAIM_DETAILS_FETCHING',
        SET_CLAIM_MASTER_HOSPITALIZATION_TYPE_SUCCESS: 'SET_CLAIM_MASTER_HOSPITALIZATION_TYPE_SUCCESS',
        SET_CLAIM_MASTER_CASE_TYPE_SUCCESS: 'SET_CLAIM_MASTER_CASE_TYPE_SUCCESS',
        SET_CLAIM_MASTER_ADMISSION_TYPE_SUCCESS: 'SET_CLAIM_MASTER_ADMISSION_TYPE_SUCCESS',
        SET_CLAIM_MASTER_ROOM_TYPE_SUCCESS: 'SET_CLAIM_MASTER_ROOM_TYPE_SUCCESS',
        RESET_CLAIM_MASTER_DATA: 'RESET_CLAIM_MASTER_DATA',
        ADD_DOCUMENT: 'ADD_DOCUMENT',
        ADD_ALL_DOCUMENTS: 'ADD_ALL_DOCUMENTS',
        REMOVE_DOCUMENT: 'REMOVE_DOCUMENT',
        SET_DOCUMENT_TAGS: 'SET_DOCUMENT_TAGS',
        RESET_DOCUMENTS: 'RESET_DOCUMENTS',
        SET_SUBMIT_MISSING_INFO_PAYLOAD: 'SET_SUBMIT_MISSING_INFO_PAYLOAD',
        RESET_SUBMIT_MISSING_INFO_PAYLOAD: 'RESET_SUBMIT_MISSING_INFO_PAYLOAD',
        SET_DRAFT_SELECTED_ACTION: 'SET_DRAFT_SELECTED_ACTION'
    },
    GLOBAL_FILTER_TYPES: {
        SET_FILTERED_DATA: 'SET_FILTERED_DATA',
        SET_DASHBOARD_URL: 'SET_DASHBOARD_URL',
        SET_FILTER_DATA_FETCHING: 'SET_FILTER_DATA_FETCHING'
    }
};
