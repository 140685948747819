import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Typography } from '@navi/web-ui/lib/primitives';
import React from 'react';
import { PreAuthClaimStatus } from '@enums/ClaimEnums';
import { ClaimStatus } from '@enums/ClaimStatus';
import RejectedStatusRemark from './RejectedStatusRemark';
import ApprovedStatusRemark from './ApprovedStatusRemark';
import MissingInfoStatusRemark from './MissingInfoStatusRemark';
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import StatusTagComponent from './StatusTagComponent';
const getStatusRemarkComponent = (preAuthDetail, claimStatus, selectedAction, documentList, documentsError) => {
    if (claimStatus === ClaimStatus.CLAIM_REJECTED ||
        preAuthDetail?.status === PreAuthClaimStatus.REJECTED) {
        return (_jsx(RejectedStatusRemark, { latestPreAuthDetails: preAuthDetail, claimStatus: claimStatus, documentList: documentList }));
    }
    if (claimStatus === ClaimStatus.CLAIM_SETTLED ||
        preAuthDetail?.status === PreAuthClaimStatus.APPROVED) {
        return (_jsx(ApprovedStatusRemark, { latestPreAuthDetails: preAuthDetail, claimStatus: claimStatus, documentList: documentList }));
    }
    if (claimStatus === ClaimStatus.MISSING_INFO ||
        preAuthDetail?.status === PreAuthClaimStatus.MISSING_INFO_REQUESTED) {
        return (_jsx(MissingInfoStatusRemark, { latestPreAuthDetails: preAuthDetail, claimStatus: claimStatus, documentList: documentList, selectedAction: selectedAction, documentsError: documentsError }));
    }
};
const StatusRemark = ({ latestPreAuthDetails, claimStatus, missingInfoList, documentList, selectedAction, documentsError = false }) => {
    const [isMissingInfoActive, setMissingInfoActive] = React.useState(false);
    React.useEffect(() => {
        if (missingInfoList?.length) {
            setMissingInfoActive(!!missingInfoList.filter((item) => !item.docAvailable).length);
        }
    }, []);
    return (_jsx(Card, { className: "custom-card", children: _jsxs(GridContainer, { children: [_jsx(GridRow, { children: _jsx(GridColumn, { className: "pb-8", children: _jsxs(Typography, { className: "text-regal-grey", variant: "h2", children: ["Status and Remarks \u00A0\u00A0", _jsx(StatusTagComponent, { preAuthStatus: latestPreAuthDetails.status, claimStatus: claimStatus, isMissingInfoActive: isMissingInfoActive })] }) }) }), getStatusRemarkComponent(latestPreAuthDetails, claimStatus, selectedAction, documentList, documentsError)] }) }));
};
export default StatusRemark;
