import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KeyValueLabel, Tag, Typography } from '@navi/web-ui/lib/primitives';
import { DEFAULT_TEXT } from '@const/Symbols';
const Banner = props => {
    const { hospitalDetails } = props;
    const { hospitalName, addressArea, addressBuildingStreet, addressLandmark, city, pincode, hospitalId, rohinicode, speciality, registrationNumber, bedCount, allottedNaviBranch } = hospitalDetails || {};
    return (_jsxs("div", { className: "p-6 rounded-lg bg-regal-blue-56 flex justify-between", children: [_jsxs("div", { className: "flex flex-col justify-between", children: [_jsxs("div", { className: "flex", children: [_jsx(Typography, { variant: "h2", className: "mr-4", children: hospitalName }), speciality && _jsx(Tag, { color: "green", label: speciality })] }), _jsxs("div", { children: [_jsxs(Typography, { variant: "p4", className: "text-regal-grey", children: ["Address: ", addressBuildingStreet, ", ", addressLandmark, " ", _jsx("br", {}), addressArea, ", ", city, " - ", pincode] }), _jsxs("div", { className: "flex mt-2", children: [_jsx(Typography, { variant: "p3", className: "text-regal-grey", children: "Branch:" }), "\u00A0", _jsx(Typography, { variant: "h4", className: "text-regal-grey", children: allottedNaviBranch ?? DEFAULT_TEXT })] })] })] }), _jsxs("div", { children: [_jsx(Typography, { variant: "h3", className: "mb-4 text-regal-grey", children: "Other Details" }), _jsx(KeyValueLabel, { className: "w-[310px] bg-transparent p-0 hospital-banner", dataArray: [
                            {
                                key: 'Hospital ID',
                                value: hospitalId ?? DEFAULT_TEXT
                            },
                            {
                                key: 'Rohini Code',
                                value: rohinicode ?? DEFAULT_TEXT
                            },
                            {
                                key: 'Registration Number',
                                value: registrationNumber ?? DEFAULT_TEXT
                            },
                            {
                                key: 'No. of beds',
                                value: `${bedCount ?? DEFAULT_TEXT}`
                            }
                        ] })] })] }));
};
export default Banner;
