import { ACTION_TYPES } from '@redux/ReduxActionTypes';
import { initialState as authInitialState } from './constants';
const { AUTH_ACTION_TYPES } = ACTION_TYPES;
const authDetailsReducer = (state = authInitialState, action) => {
    switch (action?.type) {
        case AUTH_ACTION_TYPES.SET_MOBILE_NUMBER: {
            return {
                ...state,
                userMobileNumber: action?.payload
            };
        }
        case AUTH_ACTION_TYPES.GET_OTP_SUCCESS: {
            return {
                ...state,
                getOTPSuccess: action?.payload
            };
        }
        case AUTH_ACTION_TYPES.GET_OTP_ERROR: {
            return {
                ...state,
                getOTPError: action?.payload
            };
        }
        case AUTH_ACTION_TYPES.SHOW_OTP_SCREEN: {
            return {
                ...state,
                showOTPScreen: action?.payload,
                verifyOTPError: null,
                verifyOTPSuccess: ''
            };
        }
        case AUTH_ACTION_TYPES.VERIFY_OTP_SUCCESS: {
            return {
                ...state,
                isUserLoggedIn: true,
                verifyOTPSuccess: action?.payload
            };
        }
        case AUTH_ACTION_TYPES.VERIFY_OTP_ERROR: {
            return {
                ...state,
                verifyOTPError: action?.payload
            };
        }
        case AUTH_ACTION_TYPES.USER_LOGOUT_SUCCESS: {
            return {
                ...state,
                isUserLoggedIn: false,
                showOTPScreen: false
            };
        }
        case AUTH_ACTION_TYPES.USER_LOGOUT_ERROR: {
            return state;
        }
        case AUTH_ACTION_TYPES.RESET_AUTH_DETAILS: {
            return {
                ...action?.payload
            };
        }
        case AUTH_ACTION_TYPES.SET_IS_USER_LOGGED_IN: {
            return {
                ...state,
                isUserLoggedIn: action?.payload
            };
        }
        default:
            return state;
    }
};
export default authDetailsReducer;
