import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Typography } from '@navi/web-ui/lib/primitives';
import ApprovedImage from '@icons/ApprovedImage';
import DocumentItem from '@components/DocumentItem';
import { findLetterDocument } from './constant';
import { ReferenceType } from '@pages/HospitalDetails/types';
import { useParams } from 'react-router-dom';
const ApprovedStatusRemark = ({ latestPreAuthDetails, claimStatus, documentList }) => {
    const { claimId = '', draftId = '' } = useParams();
    const document = findLetterDocument(documentList);
    return (_jsxs(GridRow, { children: [_jsx(GridColumn, { xs: 9, className: "p-0", children: _jsxs(GridRow, { className: "h-full", children: [_jsx(GridColumn, { className: "p-0 text-regal-grey", children: _jsx(Typography, { variant: "p2", children: latestPreAuthDetails.description }) }), _jsxs(GridColumn, { children: [_jsx(Typography, { variant: "p2", className: "text-regal-grey", children: "Approval Letter" }), _jsx(Typography, { variant: "p2", className: "text-regal-grey", children: document ? (_jsx(DocumentItem, { documentItem: document, referenceType: claimId ? ReferenceType.CLAIM : ReferenceType.DRAFT, referenceId: claimId ? claimId : draftId })) : ('Letter is not generated yet.') })] })] }) }), _jsx(GridColumn, { xs: 3, className: "flex justify-center", children: _jsx(ApprovedImage, {}) })] }));
};
export default ApprovedStatusRemark;
