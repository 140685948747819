// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e1G9hsgU9KkyHFHqIU_i{background-color:#f7f7f7;border-radius:4px;height:12px}.jNJMigIcMJbvnIhqeAtl{border-radius:4px;height:12px}", "",{"version":3,"sources":["webpack://./src/common/components/ProgressBar/ProgressBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,iBAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CACA,WAAA","sourcesContent":[".container {\n  background-color: #f7f7f7;\n  border-radius: 4px;\n  height: 12px;\n}\n\n.progress {\n  border-radius: 4px;\n  height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "e1G9hsgU9KkyHFHqIU_i",
	"progress": "jNJMigIcMJbvnIhqeAtl"
};
export default ___CSS_LOADER_EXPORT___;
