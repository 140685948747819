import { jsx as _jsx } from "react/jsx-runtime";
import { ClaimStatus } from '@enums/ClaimStatus';
import { MissingInfoStatusEnum, MissingInfoStatusMap } from '@common/types/Claim';
import * as Yup from 'yup';
import { validateDigitOptional, validatePanCard } from '@const/Regex';
import cx from 'classnames';
import styles from './ClaimJourney.module.scss';
import { DEFAULT_TEXT, NETWORK_REIMBURSEMENT_JOURNEY_TYPE, PRE_AUTH_JOURNEY_TYPE } from '@common/const/Symbols';
import { DocumentLetterMapping, DocumentTags, DocumentUploadedTags, PreAuthClaimStatus, PreAuthClaimType } from '@enums/ClaimEnums';
import { ErrValidationMessage, ErrValidationType, ValidationConst } from '@const/Validations';
import { PreAuthClaimTypeList } from '@const/Claim';
import { BusinessDateFormat, getFormattedDate, ISODateFormat, ISODateFormatTimeSec, ISOTimeFormat } from '@utils/Date';
import { Tag } from '@navi/web-ui/lib/primitives';
import { StatusColorEnum } from './partials/StatusRemark/constant';
export const NEW_PRE_AUTH_CLAIM_TYPE_OPTIONS = [
    {
        value: PreAuthClaimType.INITIAL,
        label: 'Initial Request'
    },
    {
        value: PreAuthClaimType.DISCHARGE,
        label: 'Discharge Request'
    }
];
export const getClaimStatusToJourneyType = (claimStatus) => {
    if (!claimStatus)
        return PRE_AUTH_JOURNEY_TYPE; // For Draft
    if ([
        ClaimStatus.INITIAL_REQUEST_RECEIVED,
        ClaimStatus.INITIAL_REQUEST_APPROVED,
        ClaimStatus.ENHANCEMENT_REQUEST_RECEIVED,
        ClaimStatus.ENHANCEMENT_REQUEST_APPROVED,
        ClaimStatus.DISCHARGE_REQUEST_RECEIVED,
        ClaimStatus.DISCHARGE_REQUEST_APPROVED
    ].includes(claimStatus)) {
        return PRE_AUTH_JOURNEY_TYPE;
    }
    return NETWORK_REIMBURSEMENT_JOURNEY_TYPE;
};
export const convertMasterValueToOptionList = (masterValueList) => {
    return masterValueList?.map(item => ({
        label: item?.masterValue,
        value: item?.masterValue
    }));
};
export const ClaimJourneyFormInitialValues = {
    patientInfo: {
        mobileNumber: '',
        emailId: '',
        currentAddress: '',
        city: '',
        state: '',
        pincode: ''
    },
    panCardNumber: '',
    claimedAmount: '',
    dateOfAdmission: '',
    dateOfDischarge: '',
    dateOfAdmissionTime: '',
    dateOfDischargeTime: '',
    kycDocumentDetails: [
        {
            type: '',
            number: ''
        }
    ],
    ailmentDetails: {
        finalDiagnosis: '',
        ailmentDescription: '',
        ailmentGroup: '',
        icdCode: '',
        icdGroup: '',
        treatment: '',
        dateOfDiagnosis: undefined,
        dateOfDelivery: undefined,
        dateOfAccident: undefined,
        tpaAdmissionType: '',
        hospitalisationType: '',
        inPatientNumber: '',
        roomType: '',
        perDayRoomRent: 0,
        lengthOfStay: 0
    },
    claimDocument: []
};
export const MATERNITY_ADMISSION_TYPE = 'Maternity';
export const ACCIDENTAL_ADMISSION_TYPE = 'Accidental';
export const ClaimJourneyFormValidation = Yup.object().shape({
    patientInfo: Yup.object().shape({
        mobileNumber: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .test(ErrValidationType.NUMERIC, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER, validateDigitOptional)
            .length(ValidationConst.PHONE_NUMBER_LENGTH, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER),
        emailId: Yup.string().nullable().trim().email(ErrValidationMessage.ENTER_VALID_EMAIL),
        currentAddress: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        city: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        state: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        pincode: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .test(ErrValidationType.NUMERIC, ErrValidationMessage.ENTER_VALID_PIN_CODE, validateDigitOptional)
            .length(ValidationConst.PIN_CODE_LENGTH, ErrValidationMessage.ENTER_VALID_PIN_CODE)
    }),
    ailmentDetails: Yup.object().shape({
        finalDiagnosis: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        ailmentDescription: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        icdCode: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        ailmentGroup: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        hospitalisationType: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        treatment: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        tpaAdmissionType: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        dateOfDiagnosis: Yup.string()
            .nullable()
            .when('tpaAdmissionType', {
            is: (val) => ![MATERNITY_ADMISSION_TYPE, ACCIDENTAL_ADMISSION_TYPE].includes(val),
            then: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED)
        }),
        dateOfAccident: Yup.string()
            .nullable()
            .when('tpaAdmissionType', {
            is: (val) => val === ACCIDENTAL_ADMISSION_TYPE,
            then: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED)
        }),
        roomType: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        perDayRoomRent: Yup.number().nullable().required(ErrValidationMessage.REQUIRED),
        lengthOfStay: Yup.number().nullable().required(ErrValidationMessage.REQUIRED)
    }),
    panCardNumber: Yup.string()
        .trim()
        .when('claimedAmount', {
        is: (val) => parseInt(val) >= ValidationConst.PAN_REQUIRED_CLAIMED_AMOUNT,
        then: Yup.string()
            .nullable()
            .required(ErrValidationMessage.REQUIRED)
            .test(ErrValidationType.ALPHA_NUMERIC, ErrValidationMessage.ENTER_VALID_PAN_NUMBER, validatePanCard)
    }),
    claimedAmount: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    dateOfAdmission: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    dateOfDischarge: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    dateOfAdmissionTime: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    dateOfDischargeTime: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    kycDocumentDetails: Yup.array().of(Yup.object().shape({
        type: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        number: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED)
    }))
});
export const numberOnlyStyle = cx(styles['number-only']);
export const getClaimJourneyFormInitialValues = (claimDetails) => {
    if (!claimDetails)
        return ClaimJourneyFormInitialValues;
    const formInitialValues = {
        patientInfo: claimDetails?.patientInfo || ClaimJourneyFormInitialValues.patientInfo,
        panCardNumber: claimDetails?.claimInfo?.panCardNumber,
        claimedAmount: claimDetails?.claimInfo?.claimedAmount,
        dateOfAdmission: getFormattedDate(claimDetails?.claimInfo?.dateOfAdmission, ISODateFormatTimeSec, ISODateFormat),
        dateOfDischarge: getFormattedDate(claimDetails?.claimInfo?.dateOfDischarge, ISODateFormatTimeSec, ISODateFormat),
        dateOfAdmissionTime: getFormattedDate(claimDetails?.claimInfo?.dateOfAdmission, ISODateFormatTimeSec, ISOTimeFormat),
        dateOfDischargeTime: getFormattedDate(claimDetails?.claimInfo?.dateOfDischarge, ISODateFormatTimeSec, ISOTimeFormat),
        kycDocumentDetails: claimDetails?.kycDocumentDetails,
        ailmentDetails: claimDetails?.ailmentDetailsDto,
        claimDocument: (claimDetails?.documentDetails || [])
    };
    return formInitialValues;
};
export const getLatestPreAuthDetails = (preAuthDetails) => {
    if (!preAuthDetails?.length)
        return {};
    const sortedPreAuthDetails = preAuthDetails.sort((a, b) => a.receivedAt < b.receivedAt ? 1 : -1);
    return sortedPreAuthDetails[0];
};
export var ActionOptionEnum;
(function (ActionOptionEnum) {
    ActionOptionEnum["VIEW"] = "VIEW";
    ActionOptionEnum["CANCEL"] = "CANCEL";
    ActionOptionEnum["NEW_INITIAL"] = "NEW_INITIAL";
    ActionOptionEnum["NEW_DISCHARGE"] = "NEW_DISCHARGE";
    ActionOptionEnum["REQUEST_ENHANCEMENT"] = "REQUEST_ENHANCEMENT";
    ActionOptionEnum["REQUEST_DISCHARGE"] = "REQUEST_DISCHARGE";
    ActionOptionEnum["MISSING_INFO_SUBMISSION"] = "MISSING_INFO_SUBMISSION";
    ActionOptionEnum["MISSING_INFO_SUBMISSION_NR"] = "MISSING_INFO_SUBMISSION_NR";
    ActionOptionEnum["SUBMIT_NETWORK_CLAIM"] = "SUBMIT_NETWORK_CLAIM";
})(ActionOptionEnum || (ActionOptionEnum = {}));
export const ActionOptionMap = {
    [ActionOptionEnum.VIEW]: 'View',
    [ActionOptionEnum.CANCEL]: 'Cancel',
    [ActionOptionEnum.NEW_INITIAL]: 'Create new initial request',
    [ActionOptionEnum.NEW_DISCHARGE]: 'Create new discharge request',
    [ActionOptionEnum.REQUEST_ENHANCEMENT]: 'Request Enhancement',
    [ActionOptionEnum.REQUEST_DISCHARGE]: 'Request Discharge',
    [ActionOptionEnum.MISSING_INFO_SUBMISSION]: 'Missing Information Submission',
    [ActionOptionEnum.MISSING_INFO_SUBMISSION_NR]: 'Missing Information Submission',
    [ActionOptionEnum.SUBMIT_NETWORK_CLAIM]: 'Submit Network Claim'
};
export const getActionOptionList = (preAuthType, preAuthStatus, claimStatus, claimId, isNewPreAuthRequest = false) => {
    if (isNewPreAuthRequest || !claimId) {
        return [ActionOptionEnum.NEW_INITIAL, ActionOptionEnum.NEW_DISCHARGE];
    }
    if ([
        ClaimStatus.CLAIM_REJECTED,
        ClaimStatus.CLAIM_APPROVED,
        ClaimStatus.CLAIM_SETTLED,
        ClaimStatus.CLAIM_REJECTED
    ].includes(claimStatus)) {
        return [ActionOptionEnum.VIEW];
    }
    if (claimStatus &&
        [
            ClaimStatus.PENDING_DATA_ENTRY_ME,
            ClaimStatus.PENDING_INTERNAL_PROCESSING,
            ClaimStatus.CASHLESS_DOCUMENT_RECEIVED
        ].includes(claimStatus)) {
        // PENDING with Navi
        return [ActionOptionEnum.VIEW];
    }
    // preAuth claims
    if (preAuthType && PreAuthClaimTypeList.includes(preAuthType)) {
        if ([PreAuthClaimType.INITIAL, PreAuthClaimType.ENHANCEMENT].includes(preAuthType) &&
            preAuthStatus &&
            [PreAuthClaimStatus.PENDING_ADJUDICATION, PreAuthClaimStatus.APPROVED].includes(preAuthStatus)) {
            return [
                ActionOptionEnum.VIEW,
                ActionOptionEnum.CANCEL,
                ActionOptionEnum.REQUEST_ENHANCEMENT,
                ActionOptionEnum.REQUEST_DISCHARGE
            ];
        }
        if (preAuthType === PreAuthClaimType.DISCHARGE &&
            preAuthStatus === PreAuthClaimStatus.PENDING_ADJUDICATION) {
            return [ActionOptionEnum.VIEW, ActionOptionEnum.CANCEL, ActionOptionEnum.REQUEST_DISCHARGE];
        }
        if (preAuthStatus === PreAuthClaimStatus.MISSING_INFO_REQUESTED) {
            return [
                ActionOptionEnum.VIEW,
                ActionOptionEnum.MISSING_INFO_SUBMISSION,
                ActionOptionEnum.REQUEST_DISCHARGE
            ];
        }
        if (preAuthType === PreAuthClaimType.DISCHARGE &&
            preAuthStatus === PreAuthClaimStatus.APPROVED) {
            return [
                ActionOptionEnum.VIEW,
                ActionOptionEnum.CANCEL,
                ActionOptionEnum.REQUEST_DISCHARGE,
                ActionOptionEnum.SUBMIT_NETWORK_CLAIM
            ];
        }
        if (preAuthType === PreAuthClaimType.ENHANCEMENT &&
            preAuthStatus === PreAuthClaimStatus.REJECTED) {
            return [ActionOptionEnum.VIEW, ActionOptionEnum.CANCEL, ActionOptionEnum.REQUEST_DISCHARGE];
        }
        if (preAuthType === PreAuthClaimType.DISCHARGE &&
            preAuthStatus === PreAuthClaimStatus.REJECTED) {
            return [ActionOptionEnum.VIEW, ActionOptionEnum.CANCEL, ActionOptionEnum.REQUEST_DISCHARGE];
        }
    }
    // NR claims
    if (claimStatus === ClaimStatus.CASHLESS_DOCUMENT_AWAITED) {
        return [ActionOptionEnum.VIEW, ActionOptionEnum.SUBMIT_NETWORK_CLAIM];
    }
    if (claimStatus &&
        [ClaimStatus.PENDING_ADJUDICATION, ClaimStatus.INVESTIGATION].includes(claimStatus)) {
        // PENDING with Navi
        return [ActionOptionEnum.VIEW];
    }
    if (claimStatus && [ClaimStatus.MISSING_INFO].includes(claimStatus)) {
        // Missing Info (NR)
        return [ActionOptionEnum.VIEW, ActionOptionEnum.MISSING_INFO_SUBMISSION_NR];
    }
    return [ActionOptionEnum.VIEW];
};
export const documentTagGroups = {
    PRE_AUTH_DOCUMENTS: {
        label: 'Pre-auth Documents',
        tags: [DocumentUploadedTags.PRE_AUTH_DOCUMENTS]
    },
    ENHANCEMENT_DOCUMENTS: {
        label: 'Enhancement Documents',
        tags: [DocumentUploadedTags.ENHANCEMENT_DOCUMENTS]
    },
    DISCHARGE_DOCUMENTS: {
        label: 'Discharge Documents',
        tags: [DocumentUploadedTags.DISCHARGE_DOCUMENTS]
    },
    NETWORK_REIMBURSEMENT_DOCUMENTS: {
        label: 'Network Reimbursement Documents',
        tags: [DocumentUploadedTags.NETWORK_REIMBURSEMENT_DOCUMENTS]
    },
    LETTERS: {
        label: 'Letters',
        tags: Object.values(DocumentLetterMapping)
    }
};
export const documentTagOptions = Object.values(DocumentTags).map(item => ({
    label: item,
    value: item
}));
const getMissingInfoStatusTag = (status) => {
    switch (status) {
        case MissingInfoStatusEnum.CLOSED:
            return (_jsx(Tag, { label: MissingInfoStatusMap[MissingInfoStatusEnum.CLOSED], color: StatusColorEnum.GREEN, variant: "transparent" }));
            break;
        case MissingInfoStatusEnum.PENDING:
            return (_jsx(Tag, { label: MissingInfoStatusMap[MissingInfoStatusEnum.PENDING], color: StatusColorEnum.YELLOW, variant: "transparent" }));
            break;
        case MissingInfoStatusEnum.UPLOADED:
            return (_jsx(Tag, { label: MissingInfoStatusMap[MissingInfoStatusEnum.UPLOADED], color: StatusColorEnum.VOILET, variant: "transparent" }));
            break;
        default:
            break;
    }
};
export const missingInfoColumnDefinitions = [
    {
        headerName: 'Query Remarks',
        field: 'remark'
    },
    {
        headerName: 'Raised Date',
        cellRenderer: (row) => {
            return (getFormattedDate(row.data.dateOfQuery, ISODateFormat, BusinessDateFormat) ?? DEFAULT_TEXT);
        },
        class: 'flex items-center'
    },
    {
        headerName: 'Status',
        cellRenderer: (row) => {
            return getMissingInfoStatusTag(row.data.status);
        }
    },
    {
        headerName: 'Resolved Date',
        cellRenderer: (row) => {
            return row.data.status === MissingInfoStatusEnum.CLOSED
                ? getFormattedDate(row.data.updatedAt, ISODateFormat, BusinessDateFormat) ?? DEFAULT_TEXT
                : DEFAULT_TEXT;
        }
    },
    {
        headerName: 'Document',
        field: 'documentName',
        cellRenderer: (row) => {
            return row.data.documentName ?? DEFAULT_TEXT;
        }
    }
];
