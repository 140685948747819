import { PreAuthClaimStatus, PreAuthClaimType } from '@enums/ClaimEnums';
import { ClaimStatus } from '@enums/ClaimStatus';
import { HOSPITAL_PORTAL_CLAIM_STATUS } from '@enums/HospitalPortalClaimStatus';
import { DEFAULT_TEXT } from '@const/Symbols';
import { formatAmount } from '@utils/';
import { BusinessDateFormat, getTimeDifference, getFormattedDate, ISODateFormat } from '@utils/Date';
export const PreAuthClaimTypeList = [
    PreAuthClaimType.INITIAL,
    PreAuthClaimType.ENHANCEMENT,
    PreAuthClaimType.INTERIM,
    PreAuthClaimType.DISCHARGE
];
export const getHospitalPortalStatus = (preAuthType, preAuthClaimStatus, claimStatus) => {
    if (claimStatus === ClaimStatus.CLAIM_REJECTED) {
        return HOSPITAL_PORTAL_CLAIM_STATUS.CLAIM_REJECTED;
    }
    if ([
        ClaimStatus.CLAIM_APPROVED,
        ClaimStatus.PAYMENT_FAILED,
        ClaimStatus.PAYMENT_INITIATED,
        ClaimStatus.PAYMENT_IN_PROCESS,
        ClaimStatus.PAYMENT_PROCESSED,
        ClaimStatus.PAYMENT_RETRY,
        ClaimStatus.PENDING_PAYMENT,
        ClaimStatus.READY_FOR_PAYMENT
    ].includes(claimStatus)) {
        return HOSPITAL_PORTAL_CLAIM_STATUS.CLAIM_APPROVED;
    }
    if (claimStatus === ClaimStatus.CLAIM_SETTLED) {
        return HOSPITAL_PORTAL_CLAIM_STATUS.CLAIM_SETTLED;
    }
    if (claimStatus === ClaimStatus.CLAIM_CANCELLED) {
        return HOSPITAL_PORTAL_CLAIM_STATUS.CLAIM_CANCELLED;
    }
    if ([ClaimStatus.PENDING_ADJUDICATION, ClaimStatus.INVESTIGATION].includes(claimStatus)) {
        return HOSPITAL_PORTAL_CLAIM_STATUS.PENDING_NAVI;
    }
    if (ClaimStatus.CASHLESS_DOCUMENT_AWAITED === claimStatus) {
        return HOSPITAL_PORTAL_CLAIM_STATUS.CASHLESS_DOCUMENT_AWAITED;
    }
    if (ClaimStatus.NEW === claimStatus) {
        return HOSPITAL_PORTAL_CLAIM_STATUS.CLAIM_CREATED;
    }
    if ([ClaimStatus.MISSING_INFO, ClaimStatus.CASHLESS_DOCUMENT_REQUESTED].includes(claimStatus)) {
        return HOSPITAL_PORTAL_CLAIM_STATUS.MORE_INFORMATION_NEEDED;
    }
    if ([
        ClaimStatus.PENDING_DATA_ENTRY_ME,
        ClaimStatus.PENDING_INTERNAL_PROCESSING,
        ClaimStatus.CASHLESS_DOCUMENT_RECEIVED
    ].includes(claimStatus)) {
        return HOSPITAL_PORTAL_CLAIM_STATUS.PENDING_NAVI;
    }
    if (preAuthType && PreAuthClaimTypeList.includes(preAuthType)) {
        if (preAuthClaimStatus === PreAuthClaimStatus.PENDING_ADJUDICATION) {
            return HOSPITAL_PORTAL_CLAIM_STATUS.PENDING_NAVI;
        }
        if (preAuthClaimStatus === PreAuthClaimStatus.MISSING_INFO_REQUESTED) {
            return HOSPITAL_PORTAL_CLAIM_STATUS.MORE_INFORMATION_NEEDED;
        }
        if (preAuthType === PreAuthClaimType.INITIAL &&
            preAuthClaimStatus === PreAuthClaimStatus.APPROVED) {
            return HOSPITAL_PORTAL_CLAIM_STATUS.INITIAL_APPROVED;
        }
        if (preAuthType === PreAuthClaimType.ENHANCEMENT &&
            preAuthClaimStatus === PreAuthClaimStatus.APPROVED) {
            return HOSPITAL_PORTAL_CLAIM_STATUS.ENHANCEMENT_APPROVED;
        }
        if (preAuthType === PreAuthClaimType.DISCHARGE &&
            preAuthClaimStatus === PreAuthClaimStatus.APPROVED) {
            return HOSPITAL_PORTAL_CLAIM_STATUS.DISCHARGE_APPROVED;
        }
        if (preAuthType === PreAuthClaimType.ENHANCEMENT &&
            preAuthClaimStatus === PreAuthClaimStatus.REJECTED) {
            return HOSPITAL_PORTAL_CLAIM_STATUS.ENHANCEMENT_REJECTED;
        }
        if (preAuthType === PreAuthClaimType.DISCHARGE &&
            preAuthClaimStatus === PreAuthClaimStatus.REJECTED) {
            return HOSPITAL_PORTAL_CLAIM_STATUS.DISCHARGE_REJECTED;
        }
    }
};
export const columnDefinitions = {
    PATIENT_NAME: {
        headerName: 'Patient Name',
        field: 'patientName',
        cellRenderer: (params) => {
            return params.value ?? DEFAULT_TEXT;
        }
    },
    CLAIM_ID: {
        headerName: 'NAVI Claim ID',
        field: 'claimId',
        cellRenderer: (params) => {
            return params.value ?? DEFAULT_TEXT;
        }
    },
    IP_NUMBER: {
        headerName: 'IP Number',
        field: 'ipNumber',
        cellRenderer: (params) => {
            return params.value ?? DEFAULT_TEXT;
        }
    },
    MEMBER_ID: {
        headerName: 'Member ID',
        field: 'memberId',
        cellRenderer: (params) => {
            return params.value ?? DEFAULT_TEXT;
        }
    },
    APPROVED_ON: {
        headerName: 'Approved On',
        field: 'preAuthLastUpdatedAt',
        cellRenderer: (params) => {
            return getFormattedDate(params.value, ISODateFormat, BusinessDateFormat) ?? DEFAULT_TEXT;
        }
    },
    SUBMITTED_ON: {
        headerName: 'Submitted On',
        field: 'preAuthLastUpdatedAt',
        cellRenderer: (params) => {
            return getFormattedDate(params.value, ISODateFormat, BusinessDateFormat) ?? DEFAULT_TEXT;
        }
    },
    RAISED_ON: {
        headerName: 'Raised On',
        field: 'preAuthLastUpdatedAt',
        cellRenderer: (params) => {
            return getFormattedDate(params.value, ISODateFormat, BusinessDateFormat) ?? DEFAULT_TEXT;
        }
    },
    REJECTED_ON: {
        headerName: 'Rejected On',
        field: 'preAuthLastUpdatedAt',
        cellRenderer: (params) => {
            return getFormattedDate(params.value, ISODateFormat, BusinessDateFormat) ?? DEFAULT_TEXT;
        }
    },
    CANCELLED_ON: {
        headerName: 'Cancelled On',
        field: 'preAuthLastUpdatedAt',
        cellRenderer: (params) => {
            return getFormattedDate(params.value, ISODateFormat, BusinessDateFormat) ?? DEFAULT_TEXT;
        }
    },
    SETTLED_ON: {
        headerName: 'Settled On',
        field: 'preAuthLastUpdatedAt',
        cellRenderer: (params) => {
            return getFormattedDate(params.value, ISODateFormat, BusinessDateFormat) ?? DEFAULT_TEXT;
        }
    },
    PROCESSING_TIME: {
        headerName: 'Processing Time',
        field: 'preAuthLastUpdatedAt',
        cellRenderer: (params) => {
            return getTimeDifference(params.value) ?? DEFAULT_TEXT;
        }
    },
    AGEING: {
        headerName: 'Ageing',
        field: 'preAuthLastUpdatedAt',
        cellRenderer: (params) => {
            return getTimeDifference(params.value) ?? DEFAULT_TEXT;
        }
    },
    DOA: {
        headerName: 'DOA',
        field: 'dateOfAdmission',
        cellRenderer: (params) => {
            return getFormattedDate(params.value, ISODateFormat, BusinessDateFormat) ?? DEFAULT_TEXT;
        }
    },
    DOD: {
        headerName: 'DOD',
        field: 'dateOfDischarge',
        cellRenderer: (params) => {
            return getFormattedDate(params.value, ISODateFormat, BusinessDateFormat) ?? DEFAULT_TEXT;
        }
    },
    CLAIM_AMOUNT: {
        headerName: 'Claim Amount',
        field: 'claimedAmount',
        cellRenderer: (row) => {
            return formatAmount(row?.data?.claimedAmount) ?? DEFAULT_TEXT;
        }
    },
    APPROVED_AMOUNT: {
        headerName: 'Approved Amount',
        field: 'approvedAmount',
        cellRenderer: (row) => {
            return formatAmount(row?.data?.approvedAmount) ?? DEFAULT_TEXT;
        }
    },
    STATUS: {
        headerName: 'Status',
        field: 'latestPreAuthClaimStatus',
        cellRenderer: (params) => {
            return (getHospitalPortalStatus(params?.data?.latestPreAuthClaimType, params?.data?.latestPreAuthClaimStatus, params?.data?.claimStatus) ?? DEFAULT_TEXT);
        }
    },
    PRE_AUTH_TYPE: {
        headerName: 'Pre-auth Type',
        field: 'latestPreAuthClaimType',
        cellRenderer: (params) => {
            return params.value ?? DEFAULT_TEXT;
        }
    },
    SETTLED_AMOUNT: {
        headerName: 'Settled Amount',
        field: 'finalPayableAmount',
        cellRenderer: (row) => {
            return formatAmount(row?.data?.approvedAmount) ?? DEFAULT_TEXT;
        }
    }
};
