import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { BorderedInput, Card, Typography } from '@navi/web-ui/lib/primitives';
import CustomSelectInput from '@components/CustomSelectInput';
import { STATE_LIST } from '@const/StateList';
import { KycDocumentList } from '@const/KycDocumentList';
import { PREFIX_PHONE_NUMBER } from '@const/Symbols';
import { FormItemVisibilityEnum, PatientDetailsConfig } from '../ClaimJourneyConfig';
import AlertOutlineIcon from '@src/assets/icons/AlertOutlineIcon';
const PatientDetails = ({ handleChange, touched, errors, values, setFieldValue, selectedAction }) => {
    return (_jsx(Card, { className: "custom-card", children: _jsxs(GridContainer, { children: [_jsx(GridRow, { children: _jsx(GridColumn, { className: "pb-8", children: _jsx(Typography, { className: "text-regal-grey", variant: "h2", children: "Patient Details" }) }) }), _jsxs(GridRow, { children: [_jsx(GridColumn, { xs: 12, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Patient Mobile Number", LeftInputAdornment: PREFIX_PHONE_NUMBER, type: "text", onChange: handleChange, value: values.patientInfo?.mobileNumber, error: touched?.patientInfo?.mobileNumber && errors?.patientInfo?.mobileNumber
                                    ? errors.patientInfo.mobileNumber
                                    : '', disabled: PatientDetailsConfig.mobileNumber[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), name: "patientInfo.mobileNumber", inputLabel: "Patient Mobile Number", required: true }) }), _jsx(GridColumn, { xs: 12, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Email ID", type: "text", onChange: handleChange, value: values.patientInfo?.emailId, error: touched?.patientInfo?.emailId && errors?.patientInfo?.emailId
                                    ? errors.patientInfo.emailId
                                    : '', disabled: PatientDetailsConfig.emailId[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), name: "patientInfo.emailId", inputLabel: "Email ID" }) }), _jsx(GridColumn, { xs: 12, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Patient Current Address", type: "text", onChange: handleChange, value: values.patientInfo?.currentAddress, error: touched?.patientInfo?.currentAddress && errors?.patientInfo?.currentAddress
                                    ? errors.patientInfo.currentAddress
                                    : '', maxLength: 160, disabled: PatientDetailsConfig.currentAddress[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), name: "patientInfo.currentAddress", inputLabel: "Patient Current Address", multiple: true, required: true }) }), _jsx(GridColumn, { xs: 12, className: "input-box", children: _jsx(BorderedInput, { placeholder: "City", type: "text", onChange: handleChange, value: values.patientInfo?.city, error: touched?.patientInfo?.city && errors?.patientInfo?.city
                                    ? errors.patientInfo.city
                                    : '', disabled: PatientDetailsConfig.city[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), name: "patientInfo.city", inputLabel: "City", required: true }) }), _jsx(GridColumn, { xs: 12, className: "input-box", children: _jsx(CustomSelectInput, { inputLabel: "State", placeholder: "State", options: STATE_LIST, value: values.patientInfo?.state, name: "patientInfo.state", multiSelect: false, readOnly: true, setSelectedValue: (e) => setFieldValue('patientInfo.state', e), disabled: PatientDetailsConfig.state[selectedAction]?.[FormItemVisibilityEnum.isDisabled], error: touched?.patientInfo?.state && errors?.patientInfo?.state
                                    ? errors.patientInfo.state
                                    : '', required: true }) }), _jsx(GridColumn, { xs: 12, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Pincode", type: "text", onChange: handleChange, value: values.patientInfo?.pincode, error: touched?.patientInfo?.pincode && errors?.patientInfo?.pincode
                                    ? errors.patientInfo.pincode
                                    : '', disabled: PatientDetailsConfig.pincode[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), name: "patientInfo.pincode", inputLabel: "Pincode", required: true }) })] }), _jsx("hr", { className: "border-dashed text-grey mt-3 mb-2" }), _jsx(GridRow, { children: _jsx(GridColumn, { className: "pb-[33px] pt-6", children: _jsx(Typography, { className: "text-regal-grey", variant: "h2", children: "KYC Details" }) }) }), _jsxs(GridRow, { children: [_jsx(GridColumn, { className: "input-box", children: _jsx(CustomSelectInput, { inputLabel: "KYC Document Type", placeholder: "KYC Document Type", options: KycDocumentList, value: values.kycDocumentDetails?.[0]?.type, name: "kycDocumentDetails[0].type", multiSelect: false, readOnly: true, setSelectedValue: (e) => setFieldValue('kycDocumentDetails[0].type', e), error: touched?.kycDocumentDetails?.[0]?.type && errors?.kycDocumentDetails?.[0]
                                    ? errors.kycDocumentDetails[0].type
                                    : '', disabled: PatientDetailsConfig.kycDocumentDetailsType[selectedAction]?.[FormItemVisibilityEnum.isDisabled], required: true }) }), _jsx(GridColumn, { className: "input-box", children: _jsx(BorderedInput, { placeholder: "KYC Document ID", type: "text", onChange: handleChange, value: values.kycDocumentDetails?.[0]?.number, error: touched?.kycDocumentDetails?.[0]?.number && errors?.kycDocumentDetails?.[0]
                                    ? errors.kycDocumentDetails[0].number
                                    : '', disabled: PatientDetailsConfig.kycDocumentDetailsNumber[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), name: "kycDocumentDetails[0].number", inputLabel: "KYC Document ID", required: true }) }), _jsx(GridColumn, { children: _jsx(BorderedInput, { placeholder: "PH PAN Card Number", type: "text", onChange: handleChange, value: values.panCardNumber, error: touched?.panCardNumber && errors?.panCardNumber ? errors.panCardNumber : '', disabled: PatientDetailsConfig.panCardNumber[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), name: "panCardNumber", inputLabel: "PH PAN Card Number" }) })] })] }) }));
};
export default PatientDetails;
