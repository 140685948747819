import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BorderedInput, BottomSheet, Button, Typography } from '@navi/web-ui/lib/primitives';
import { useFormik } from 'formik';
import dashedLine from '@assets/icons/grey-dashed-line.svg';
import { updateContactDetails } from '@services/HospitalApi';
import { hideLoader, showLoader } from '@redux/common/common.actions';
import { useDispatch } from 'react-redux';
import { getErrorMessage } from '@utils/';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import AlertOutlineIcon from '@assets/icons/AlertOutlineIcon';
import { GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { getContactDetailsInitialValues, getUpdateContactDetailsPayload } from '../utils';
import { HospitalDetailsSchema } from '../constants';
import ArrowLeftIcon from '@assets/icons/ArrowLeftIcon';
import SaveIcon from '@assets/icons/SaveIcon';
import { STATUS_CODE } from '@const/ApiConstants';
const EditContactDetails = props => {
    const { open, onClose, hospitalDetails, setHospitalDetails } = props;
    const dispatch = useDispatch();
    const saveChangesHandler = (data) => {
        const payload = getUpdateContactDetailsPayload(data);
        dispatch(showLoader());
        updateContactDetails(hospitalDetails.hospitalId, payload)
            .then((response) => {
            if (response?.data?.code === STATUS_CODE.PARTNER_PORTAL_OK) {
                setHospitalDetails(response.data?.data);
                toast.success('Hospital Contact Details updated successfully');
                return;
            }
            throw response;
        })
            .catch((error) => {
            toast.error(getErrorMessage(error));
        })
            .finally(() => {
            dispatch(hideLoader());
            onClose();
        });
    };
    const { handleChange, errors, touched, values, handleSubmit, handleBlur, dirty, setFieldValue } = useFormik({
        initialValues: getContactDetailsInitialValues(hospitalDetails),
        onSubmit: (values) => {
            saveChangesHandler(values);
        },
        enableReinitialize: true,
        validationSchema: HospitalDetailsSchema
    });
    return (_jsx(BottomSheet, { fullScreen: true, open: open, onClose: onClose, className: "hospital-details edit-form", children: _jsxs("div", { className: "mt-9 px-24 m-auto", children: [_jsxs("div", { className: "flex justify-between mb-8", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(ArrowLeftIcon, { color: 'black', width: 32, height: 32, onClick: onClose, className: "cursor-pointer" }), _jsx(Typography, { variant: "h1", className: "ml-2", children: "Edit Contact details" })] }), _jsx(Button, { startAdornment: _jsx(SaveIcon, { color: "#0276FE", className: "mr-1" }), variant: "secondary", onClick: () => handleSubmit(), className: "ml-6 text-primary-blue border-primary-blue", children: "Save Changes" })] }), _jsx(Typography, { variant: "h2", className: "mb-6", children: "Hospital Contact Details" }), _jsxs("div", { className: "custom-card flex justify-between p-8", children: [_jsx(BorderedInput, { placeholder: "Enter number", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.hospitalContact.phoneNumber, error: touched?.hospitalContact?.phoneNumber && errors?.hospitalContact?.phoneNumber
                                ? errors.hospitalContact.phoneNumber
                                : '', Icon: _jsx(AlertOutlineIcon, {}), name: "hospitalContact.phoneNumber", inputLabel: "Hospital Contact Number", required: true }), _jsx(BorderedInput, { placeholder: "Enter e-mail", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.hospitalContact.email, error: touched?.hospitalContact?.email && errors?.hospitalContact?.email
                                ? errors.hospitalContact.email
                                : '', Icon: _jsx(AlertOutlineIcon, {}), name: "hospitalContact.email", inputLabel: "Hospital Mail ID", required: true }), _jsx(BorderedInput, { placeholder: "Enter website", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.hospitalContact.website, error: touched?.hospitalContact?.website && errors?.hospitalContact?.website
                                ? errors.hospitalContact.website
                                : '', Icon: _jsx(AlertOutlineIcon, {}), name: "hospitalContact.website", inputLabel: "Hospital Website", required: true })] }), _jsxs("div", { className: "flex mt-10 mb-6", children: [_jsx(Typography, { variant: "p1", className: "text-regal-grey mr-6", children: "Other Contact Details" }), _jsx("img", { src: dashedLine, alt: "dashed line" })] }), _jsxs("div", { className: "flex flex-wrap justify-between", children: [_jsxs("div", { className: "custom-card flex justify-between w-2/5 py-6 pl-6 pr-20 mb-6", children: [_jsx(Typography, { variant: "h3", children: "Managing Director" }), _jsxs("div", { children: [_jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter name", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.managingDirector.name, error: touched?.managingDirector?.name && errors?.managingDirector?.name
                                                    ? errors.managingDirector.name
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "managingDirector.name", inputLabel: "Name", required: true }) }), _jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter contact", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.managingDirector.phoneNumber, error: touched?.managingDirector?.phoneNumber && errors?.managingDirector?.phoneNumber
                                                    ? errors.managingDirector.phoneNumber
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "managingDirector.phoneNumber", inputLabel: "Contact", required: true }) }), _jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter email", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.managingDirector.email, error: touched?.managingDirector?.email && errors?.managingDirector?.email
                                                    ? errors.managingDirector.email
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "managingDirector.email", inputLabel: "Mail ID", required: true }) })] })] }), _jsxs("div", { className: "custom-card flex justify-between w-2/5 py-6 pl-6 pr-20 mb-6", children: [_jsx(Typography, { variant: "h3", children: "TPA co-ordinator" }), _jsxs("div", { children: [_jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter name", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.tpaCoordinator.name, error: touched?.tpaCoordinator?.name && errors?.tpaCoordinator?.name
                                                    ? errors.tpaCoordinator.name
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "tpaCoordinator.name", inputLabel: "Name", required: true }) }), _jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter contact", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.tpaCoordinator.phoneNumber, error: touched?.tpaCoordinator?.phoneNumber && errors?.tpaCoordinator?.phoneNumber
                                                    ? errors.tpaCoordinator.phoneNumber
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "tpaCoordinator.phoneNumber", inputLabel: "Contact", required: true }) }), _jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter email", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.tpaCoordinator.email, error: touched?.tpaCoordinator?.email && errors?.tpaCoordinator?.email
                                                    ? errors.tpaCoordinator.email
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "tpaCoordinator.email", inputLabel: "Mail ID", required: true }) })] })] }), _jsxs("div", { className: "custom-card flex justify-between w-2/5 py-6 pl-6 pr-20 mb-6", children: [_jsx(Typography, { variant: "h3", children: "Finance Manager" }), _jsxs("div", { children: [_jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter name", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.financeManager.name, error: touched?.financeManager?.name && errors?.financeManager?.name
                                                    ? errors.financeManager.name
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "financeManager.name", inputLabel: "Name", required: true }) }), _jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter contact", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.financeManager.phoneNumber, error: touched?.financeManager?.phoneNumber && errors?.financeManager?.phoneNumber
                                                    ? errors.financeManager.phoneNumber
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "financeManager.phoneNumber", inputLabel: "Contact", required: true }) }), _jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter email", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.financeManager.email, error: touched?.financeManager?.email && errors?.financeManager?.email
                                                    ? errors.financeManager.email
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "financeManager.email", inputLabel: "Mail ID", required: true }) })] })] }), _jsxs("div", { className: "custom-card flex justify-between w-2/5 py-6 pl-6 pr-20 mb-6", children: [_jsx(Typography, { variant: "h3", children: "Head of Operations" }), _jsxs("div", { children: [_jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter name", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.operationsHead.name, error: touched?.operationsHead?.name && errors?.operationsHead?.name
                                                    ? errors.operationsHead.name
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "operationsHead.name", inputLabel: "Name", required: true }) }), _jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter contact", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.operationsHead.phoneNumber, error: touched?.operationsHead?.phoneNumber && errors?.operationsHead?.phoneNumber
                                                    ? errors.operationsHead.phoneNumber
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "operationsHead.phoneNumber", inputLabel: "Contact", required: true }) }), _jsx(GridRow, { className: "custom-input", children: _jsx(BorderedInput, { placeholder: "Enter email", type: "text", onChange: handleChange, onBlur: handleBlur, value: values.operationsHead.email, error: touched?.operationsHead?.email && errors?.operationsHead?.email
                                                    ? errors.operationsHead.email
                                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "operationsHead.email", inputLabel: "Mail ID", required: true }) })] })] })] })] }) }));
};
export default EditContactDetails;
