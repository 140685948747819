import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import PdfIcon from '@icons/PdfIcon';
import { BusinessDateFormat, getFormattedDate, ISODateFormatTimeSec } from '@utils/Date';
import { Button } from '@navi/web-ui/lib/primitives';
import ArrowNavigateIcon from '@icons/ArrowNavigateIcon';
import { getDocumentApi } from '@services/DocumentApi';
const DocumentItem = ({ documentItem, referenceType, referenceId }) => {
    const handleViewClickHandler = () => {
        if (!documentItem.documentReferenceId)
            return;
        getDocumentApi(documentItem.documentReferenceId, { download: false }, referenceType, referenceId);
    };
    return (_jsx(GridContainer, { className: "p-0", children: _jsx(GridRow, { className: "m-0 items-center", children: _jsx(GridColumn, { xs: 9, className: "p-0", children: _jsxs(GridRow, { className: "my-2 mx-0 justify-between items-center bg-secondary-grey rounded", children: [_jsx(GridColumn, { xs: 10, children: _jsxs(GridRow, { className: "items-center", children: [_jsx(GridColumn, { xs: 1, children: _jsx(PdfIcon, {}) }), _jsx(GridColumn, { xs: 8, children: _jsxs(GridRow, { children: [_jsx(GridColumn, { className: "p-0 text-ellipsis", children: documentItem.documentName }), _jsx(GridColumn, { className: "p-0 text-ellipsis text-primary-grey", children: getFormattedDate(documentItem.uploadDate, ISODateFormatTimeSec, BusinessDateFormat) })] }) })] }) }), _jsx(GridColumn, { xs: 2, children: _jsx(Button, { startAdornment: _jsx(ArrowNavigateIcon, {}), variant: "text", onClick: handleViewClickHandler, children: "Open" }) })] }) }) }) }));
};
export default DocumentItem;
