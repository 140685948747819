// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yvR_36kPuaaqddahFEUA{position:relative}.yvR_36kPuaaqddahFEUA .RDWPSQG6g2juJd72IXA4{position:absolute;z-index:100}", "",{"version":3,"sources":["webpack://./src/common/components/CustomSelectInput/CustomSelectInput.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGA,4CACE,iBAAA,CACA,WAAA","sourcesContent":[".select-wrapper {\n  position: relative;\n}\n\n  .select-wrapper .picker {\n    position: absolute;\n    z-index: 100;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-wrapper": "yvR_36kPuaaqddahFEUA",
	"picker": "RDWPSQG6g2juJd72IXA4"
};
export default ___CSS_LOADER_EXPORT___;
