import { BFF_API_BASE_URL } from '@const/ApiConstants';
import { USER_ROLES_TYPE } from '@enums/UserRolesType';
import { DEFAULT_TEXT } from '@const/Symbols';
export const USER_CONSTANTS = {
    header: 'Users',
    noUsersPresent: 'No users added yet',
    addUser: 'Add User',
    admin: 'Admin',
    agent: 'Agent',
    superAdmin: 'Super Admin',
    active: 'Active',
    inactive: 'Inactive'
};
export const SHURI_CREATE_USER_FORM_LINK = `${BFF_API_BASE_URL}/configuration/uri/hp/dw/user/create`;
export const SHURI_EDIT_USER_FORM_LINK = `${BFF_API_BASE_URL}/configuration/uri/hp/dw/edit/user`;
export const SHURI_USER_RESPONSE = 'USER_RESPONSE';
export const USER_CREATE_SUCCESS_MESSAGE = 'User created successfully';
export const USER_UPDATE_SUCCESS_MESSAGE = 'User updated successfully';
export const getUserType = (roles = []) => {
    if (roles.includes(USER_ROLES_TYPE.SUPER_ADMIN))
        return USER_CONSTANTS.superAdmin;
    else if (roles.includes(USER_ROLES_TYPE.ADMIN))
        return USER_CONSTANTS.admin;
    else if (roles.includes(USER_ROLES_TYPE.AGENT))
        return USER_CONSTANTS.agent;
    else
        return DEFAULT_TEXT;
};
