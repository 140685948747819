import { POLICY_RELATIONSHIP } from '@enums/Policy';
export const POLICY_RELATIONSHIP_MAP = {
    [POLICY_RELATIONSHIP.SELF]: 'Self',
    [POLICY_RELATIONSHIP.BROTHER]: 'Brother',
    [POLICY_RELATIONSHIP.SPOUSE]: 'Spouse',
    [POLICY_RELATIONSHIP.SON]: 'Son',
    [POLICY_RELATIONSHIP.DAUGHTER]: 'Daughter',
    [POLICY_RELATIONSHIP.MOTHER]: 'Mother',
    [POLICY_RELATIONSHIP.FATHER]: 'Father',
    [POLICY_RELATIONSHIP.MOTHER_IN_LAW]: 'Mother-In-Law',
    [POLICY_RELATIONSHIP.FATHER_IN_LAW]: 'Father-In-Law'
};
