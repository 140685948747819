import { PRE_AUTH_ACTION_TYPES } from './ActionTypes';
export const setPreAuthList = (payload) => ({
    type: PRE_AUTH_ACTION_TYPES.SET_PRE_AUTH_LIST,
    payload
});
export const setPreAuthListPaginationDetails = (payload) => ({
    type: PRE_AUTH_ACTION_TYPES.SET_PRE_AUTH_LIST_PAGINATION_DETAILS,
    payload
});
export const resetPreAuthList = () => ({
    type: PRE_AUTH_ACTION_TYPES.RESET_PRE_AUTH_LIST
});
