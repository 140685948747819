import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { UploadIcon } from '@navi/web-ui/lib/icons';
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Button, Typography } from '@navi/web-ui/lib/primitives';
import { selectClaimDetails, selectDocuments } from '@redux/claimJourney/claimJourney.selector';
import { useAppSelector } from '@redux/hooks';
import { uploadDocumentApi } from '@services/DocumentApi';
import { useDispatch } from 'react-redux';
import { removeDocument, resetDocuments, setDocumentTags } from '@redux/claimJourney/claimJourney.actions';
import ViewDocuments from './ViewDocuments';
import DocumentsTable from '@components/DocumentsTable';
import viewIcon from '@assets/icons/view-icon.svg';
import { DocumentItemModes } from '@components/DocumentsTable/types';
const UploadDocuments = props => {
    const { title, documentTagOptions, uploadBtnDisabled = false, isMissingInfo = false, documentsError = false } = props;
    const documents = useAppSelector(selectDocuments);
    const claimDetails = useAppSelector(selectClaimDetails);
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const [showDocuments, setShowDocuments] = React.useState(false);
    const handleSelectLocalDocument = () => {
        fileInputRef?.current?.click();
    };
    const handleUploadDocument = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile)
            dispatch(uploadDocumentApi(selectedFile));
    };
    const handleDocumentTagsChange = (documentReferenceId, selectedTags) => {
        dispatch(setDocumentTags(documentReferenceId, selectedTags));
    };
    const handleRemoveDocumentTag = (document, tagValue) => {
        const newTags = document.tags.filter((item) => item.value !== tagValue);
        dispatch(setDocumentTags(document.documentReferenceId, newTags));
    };
    const handleRemoveFile = (documentReferenceId) => {
        dispatch(removeDocument(documentReferenceId));
    };
    // Remove all the uploaded documents on component unmount.
    React.useEffect(() => {
        return () => {
            dispatch(resetDocuments());
        };
    }, []);
    return (_jsxs(GridContainer, { className: "p-0", children: [_jsx(GridRow, { className: "mt-3 ml-0", children: _jsxs(GridColumn, { className: "flex justify-between", children: [_jsx(Typography, { className: "text-regal-grey", variant: "h2", children: title }), _jsx(Button, { disabled: uploadBtnDisabled, startAdornment: _jsx(UploadIcon, { color: "white" }), onClick: handleSelectLocalDocument, children: "Upload Document" }), _jsx("input", { type: "file", accept: "image/jpeg,image/jpg,image/png,application/pdf", name: "claim-document-upload", ref: fileInputRef, onChange: handleUploadDocument, hidden: true })] }) }), _jsx(GridRow, { className: "mt-6 pl-0", children: _jsx(GridColumn, { className: "p-0", children: _jsx(DocumentsTable, { tagOptions: documentTagOptions, documents: documents, mode: DocumentItemModes.UPLOAD, onRemoveFile: handleRemoveFile, onRemoveTag: handleRemoveDocumentTag, onTagsChange: handleDocumentTagsChange, isMissingInfo: isMissingInfo, documentsError: documentsError }) }) }), _jsx(GridRow, { children: _jsxs(GridColumn, { children: [_jsxs(Button, { variant: "text", className: "my-2", onClick: () => setShowDocuments(true), children: [_jsx("img", { src: viewIcon, className: "mr-2 w-4" }), "View All Documents and Letters"] }), _jsx(ViewDocuments, { open: showDocuments, onClose: () => setShowDocuments(false), documents: claimDetails?.documentDetails })] }) })] }));
};
export default UploadDocuments;
