import { POLICY_API_BASE_URL } from '@const/ApiConstants';
import { ApiService } from './ApiService';
const ENDPOINTS = {
    POLICY_STATUS: {
        endpoint: (policyId, date) => `${POLICY_API_BASE_URL}/timeline/status/${policyId}?at=${date}`
    },
    POLICY_DETAILS: {
        endpoint: (policyId) => `${POLICY_API_BASE_URL}/${policyId}`
    }
};
export const getPolicyDetails = (policyId) => {
    return ApiService.get(`${ENDPOINTS.POLICY_DETAILS.endpoint(policyId)}/detail/lite`);
};
export const getPolicyExtraDetails = (policyId, customerId) => {
    return ApiService.getWithHeader(ENDPOINTS.POLICY_DETAILS.endpoint(policyId), {
        'X-Navi-Customer-Id': customerId
    });
};
export const getPolicyStatus = (policyId, dateOfAdmission) => {
    return ApiService.get(ENDPOINTS.POLICY_STATUS.endpoint(policyId, dateOfAdmission));
};
