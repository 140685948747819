import { createSelector } from 'reselect';
const selectClaimJourneyDetails = (state) => state.claimJourneyDetails;
export const selectPolicyStatus = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.policyStatus);
export const selectPolicyStatusError = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.policyError);
export const selectPolicyDetails = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.policyDetails);
export const selectPolicyMembersDetails = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.policyDetails?.members);
export const selectFetchingPolicyDetails = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.isPolicyDetailsFetching);
export const selectClaimDetails = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.claimDetails);
export const selectFetchingClaimDetails = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.isClaimDetailsFetching);
export const selectClaimMasterAdmissionType = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.claimMasterData?.admissionType);
export const selectClaimMasterCaseType = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.claimMasterData?.caseType);
export const selectClaimMasterHospitalizationType = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.claimMasterData?.hospitalizationType);
export const selectClaimMasterRoomType = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.claimMasterData?.roomType);
export const selectIsFetchingClaimPolicyDetails = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.isClaimDetailsFetching ||
    selectClaimJourneyDetails?.isPolicyDetailsFetching);
export const selectDocuments = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.documents);
export const selectSubmitMissingInfoPayload = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.submitMissingInfoPayload);
export const selectDraftSelectedState = createSelector([selectClaimJourneyDetails], selectClaimJourneyDetails => selectClaimJourneyDetails?.draftSelectedAction);
