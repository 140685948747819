export const REQUEST_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};
export const STATUS_CODE = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    PARTNER_PORTAL_OK: '2000'
};
export const AUTH_API_BASE_URL = `${window.config.API_BASE_URL}/mjolnir`;
export const AUTH_CLIENT_ID = `${window.config.AUTH_CLIENT_ID}`;
export const USER_API_BASE_URL = `${window.config.API_BASE_URL}/mjolnir`;
export const POLICY_API_BASE_URL = `${window.config.API_BASE_URL}/insurance-policy-manager/policy`;
export const CLAIM_API_BASE_URL = `${window.config.API_BASE_URL}/claims-service/v1`;
export const BFF_API_BASE_URL = `${window.config.API_BASE_URL}`;
export const PARTNER_API_BASE_URL = `${window.config.API_BASE_URL}/partner`;
export const DOCUMENT_API_BASE_URL = `${window.config.API_BASE_URL}/document-service`;
