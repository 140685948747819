import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@navi/web-ui/lib/primitives/Typography';
import NotFoundPageIllustrationIcon from '@assets/icons/NotFoundPageIllustrationIcon';
import styles from './NotFound.module.scss';
const NotFoundDesktopMsg = 'Page not found. Please try after some time.';
const NotFound = () => {
    const returnMsg = () => {
        return (_jsx(Typography, { variant: "p2", className: styles['not-found-msg-wrapper'], children: NotFoundDesktopMsg }));
    };
    return (_jsx("div", { className: styles['not-found-wrapper'], children: _jsxs("div", { className: styles['not-found-illustration'], children: [_jsx(NotFoundPageIllustrationIcon, {}), returnMsg()] }) }));
};
export default NotFound;
