// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q5bW7njk8KVyfoXaQLvq{width:100px;height:100px;border-radius:50%}.HP73z6mQqlRQmPIy7Acs div{margin-bottom:8px !important}.OFAvW25kDTuI7RdcHVIK{width:16px;height:16px;margin-top:2px}.JdFoObeIH90e76uqn9rl{border:1px solid #f6f6f6;width:100%;margin:0}", "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dashboard.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,iBAAA,CAGF,0BACI,4BAAA,CAGJ,sBACE,UAAA,CACA,WAAA,CACA,cAAA,CAGF,sBACE,wBAAA,CACA,UAAA,CACA,QAAA","sourcesContent":[".pie {\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n}\n\n.header-details div {\n    margin-bottom: 8px !important;\n  }\n\n.pending-action-icon {\n  width: 16px;\n  height: 16px;\n  margin-top: 2px;\n}\n\n.horizontal-line {\n  border: 1px solid #f6f6f6;\n  width: 100%;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pie": "Q5bW7njk8KVyfoXaQLvq",
	"header-details": "HP73z6mQqlRQmPIy7Acs",
	"pending-action-icon": "OFAvW25kDTuI7RdcHVIK",
	"horizontal-line": "JdFoObeIH90e76uqn9rl"
};
export default ___CSS_LOADER_EXPORT___;
