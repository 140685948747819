import { combineReducers } from 'redux';
import userDetailsReducer from './userDetails/userDetails.reducer';
import authDetailsReducer from './auth/auth.reducer';
import claimJourneyDetailsReducer from './claimJourney/claimJourney.reducer';
import preAuthReducer from './preAuth/preAuth.reducer';
import globalFilterReducer from './globalFilter/globalFilter.reducer';
import commonReducer from './common/common.reducer';
export const RootReducer = combineReducers({
    userDetails: userDetailsReducer,
    authDetails: authDetailsReducer,
    claimJourneyDetails: claimJourneyDetailsReducer,
    preAuthDetails: preAuthReducer,
    filteredListDetails: globalFilterReducer,
    commonDetails: commonReducer
});
