import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Typography } from '@navi/web-ui/lib/primitives';
import { DEFAULT_TEXT, PERCENTAGE_SYMBOL } from '@src/common/const/Symbols';
const MouDetails = props => {
    const { hospitalDetails } = props;
    const { discountPercentage, discountExclusions } = hospitalDetails || {};
    const discountDetails = [
        {
            label: 'Discount (%)',
            value: discountPercentage ? `${discountPercentage} ${PERCENTAGE_SYMBOL}` : ''
        },
        {
            label: 'Discount Exclusions',
            value: discountExclusions?.map((exclusion) => exclusion.label)?.join(',')
        }
    ];
    return (_jsxs(GridContainer, { className: "p-0 mt-14", children: [_jsx(Typography, { variant: "h2", className: "mb-6 text-regal-grey font-semibold", children: "Memorandum of Understanding (MOU)" }), _jsxs(GridRow, { className: "m-0 custom-card px-6 pt-6 pb-2 w-1/2 block justify-between items-center", children: [_jsx(Typography, { variant: "h2", className: "mb-6 text-regal-grey font-medium", children: "Discounts" }), discountDetails.map((item, index) => (_jsxs(GridRow, { className: "m-0", children: [_jsx(GridColumn, { className: "p-0", xs: 6, children: _jsx(Typography, { variant: "p2", className: "mb-4 text-regal-grey", children: item.label }) }), _jsx(GridColumn, { className: "p-0", xs: 6, children: _jsx(Typography, { variant: "p2", className: "mb-4 text-regal-grey font-medium", children: item.value ? item.value : DEFAULT_TEXT }) })] }, index)))] })] }));
};
export default MouDetails;
