import { DOCUMENT_API_BASE_URL } from '@common/const/ApiConstants';
import { MbToBytes, toSearchParams } from '@common/utils';
import { ApiService } from './ApiService';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { getErrorMessage } from '@common/utils';
import { DocumentAccessTypes } from '@enums/Documents';
import { addDocument } from '@redux/claimJourney/claimJourney.actions';
import { hideLoader, showLoader } from '@redux/common/common.actions';
const ENDPOINTS = {
    UPLOAD_DOCUMENT: {
        endpoint: (params = {}) => `${DOCUMENT_API_BASE_URL}/upload-to-permanent?${toSearchParams(params)}`
    },
    GET_DOCUMENT: {
        endpoint: (documentReferenceId, query) => `${DOCUMENT_API_BASE_URL}/documents/${documentReferenceId}?${toSearchParams(query)}`
    }
};
export const uploadDocuments = (file) => {
    const params = {
        type: getFileType(file.name),
        accessType: DocumentAccessTypes.INTERNAL
    };
    const body = new FormData();
    body.append('file', file);
    const MAX_FILE_SIZE_MB = 25;
    if (file?.size > MbToBytes(MAX_FILE_SIZE_MB)) {
        toast.error(`File is too large. Please select a file less than ${MAX_FILE_SIZE_MB} MB`);
        return;
    }
    return ApiService.post(ENDPOINTS.UPLOAD_DOCUMENT.endpoint(params), body);
};
export const uploadDocumentApi = (file) => {
    return function (dispatch) {
        const response = uploadDocuments(file);
        if (response) {
            dispatch(showLoader());
            response
                .then((response) => {
                // getting statusCode only if any error occurs. Hence, checking with referenceId.
                if (response?.data?.referenceId) {
                    const payload = {
                        [response.data.referenceId]: {
                            ...response.data,
                            documentName: file.name,
                            documentReferenceId: response.data.referenceId
                        }
                    };
                    dispatch(addDocument(payload));
                    return;
                }
                throw response;
            })
                .catch((error) => {
                toast.error(getErrorMessage(error?.data?.message));
            })
                .finally(() => {
                dispatch(hideLoader());
            });
        }
    };
};
const getFileType = (fileName) => {
    return fileName?.split('.').pop() || '';
};
export const getDocumentApi = (documentReferenceId, query, referenceType, referenceId) => {
    const response = ApiService.getWithHeader(ENDPOINTS.GET_DOCUMENT.endpoint(documentReferenceId, query), {
        'x-reference-type': referenceType,
        'x-reference-id': referenceId
    });
    response
        .then((response) => {
        // getting statusCode only if any error occurs. Hence, checking with referenceId.
        if (response?.data?.referenceId) {
            window.open(response.data.uri);
            return;
        }
        throw response;
    })
        .catch((error) => {
        toast.error(getErrorMessage(error?.data?.message));
    });
};
