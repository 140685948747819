import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Tag } from '@navi/web-ui/lib/primitives';
import { PreAuthClaimStatus } from '@enums/ClaimEnums';
import { ClaimStatus } from '@enums/ClaimStatus';
import { StatusColorEnum, StatusLabelEnum } from './constant';
const StatusTagComponent = ({ preAuthStatus, claimStatus, isMissingInfoActive = false }) => {
    if (preAuthStatus === PreAuthClaimStatus.APPROVED && isMissingInfoActive) {
        return (_jsx(Tag, { label: StatusLabelEnum.CONDITIONAL_APPROVED, color: StatusColorEnum.YELLOW, variant: "transparent" }));
    }
    if (claimStatus === ClaimStatus.MISSING_INFO ||
        preAuthStatus === PreAuthClaimStatus.MISSING_INFO_REQUESTED) {
        return (_jsx(Tag, { label: StatusLabelEnum.MISSING_INFO, color: StatusColorEnum.ORANGE, variant: "transparent" }));
    }
    if (claimStatus === ClaimStatus.CLAIM_SETTLED ||
        (preAuthStatus === PreAuthClaimStatus.APPROVED && !isMissingInfoActive)) {
        return (_jsx(Tag, { label: StatusLabelEnum.APPROVED, color: StatusColorEnum.GREEN, variant: "transparent" }));
    }
    if (claimStatus === ClaimStatus.CLAIM_REJECTED) {
        return (_jsx(Tag, { label: StatusLabelEnum.REJECTED, color: StatusColorEnum.RED, variant: "transparent" }));
    }
    if (preAuthStatus === PreAuthClaimStatus.REJECTED) {
        return (_jsx(Tag, { label: StatusLabelEnum.TR_REJECTED, color: StatusColorEnum.RED, variant: "transparent" }));
    }
    return _jsx(_Fragment, {});
};
export default StatusTagComponent;
