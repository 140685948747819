import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { AgTable } from '@navi/web-ui/lib/components';
import { GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Typography } from '@navi/web-ui/lib/primitives';
import { DEFAULT_TEXT, RUPEE_SYMBOL } from '@const/Symbols';
import { selectClaimDetails } from '@redux/claimJourney/claimJourney.selector';
import { useAppSelector } from '@redux/hooks';
const BillLevelDeduction = () => {
    const claimDetails = useAppSelector(selectClaimDetails);
    const { claimBills } = claimDetails || {};
    const [deductionList, setDeductionList] = React.useState([]);
    React.useEffect(() => {
        if (claimBills?.length) {
            const chargeTypeDeductionMap = new Map();
            claimBills
                .filter((item) => item?.chargeType)
                .forEach((claimBillItem) => {
                if (!chargeTypeDeductionMap.has(claimBillItem.chargeType)) {
                    chargeTypeDeductionMap.set(claimBillItem.chargeType, {
                        amount: 0,
                        nonPayableAmount: 0,
                        reason: ''
                    });
                }
                const elem = chargeTypeDeductionMap.get(claimBillItem.chargeType) || {};
                const chargeTypeDeductionItem = {
                    amount: elem.amount +
                        Number(!isNaN(claimBillItem.amount) ? (claimBillItem.amount ?? 0)?.toFixed(2) : 0),
                    nonPayableAmount: elem.nonPayableAmount +
                        Number(!isNaN(claimBillItem.nonPayableAmount)
                            ? (claimBillItem.nonPayableAmount ?? 0)?.toFixed(2)
                            : 0),
                    reason: `${elem.reason}${elem.reason ? ` | ${claimBillItem.remark}` : claimBillItem.remark ?? DEFAULT_TEXT}`
                };
                chargeTypeDeductionMap.set(claimBillItem.chargeType, chargeTypeDeductionItem);
            });
            const deductionList = Array.from(chargeTypeDeductionMap, ([key, value]) => ({
                chargeType: key,
                ...value
            }));
            const billDeductionTotalSummationRow = {
                chargeType: 'Total',
                amount: Number(deductionList?.reduce((acc, item) => acc + item.amount, 0).toFixed(2)),
                nonPayableAmount: Number(deductionList
                    ?.reduce((acc, item) => acc + item.nonPayableAmount, 0)
                    .toFixed(2))
            };
            setDeductionList([...deductionList, billDeductionTotalSummationRow]);
        }
    }, [claimBills]);
    return (_jsxs(GridContainer, { className: "mb-14 p-0", children: [_jsx(Typography, { className: "text-primary-grey mb-4", variant: "h3", children: "Bill Level Deductions" }), _jsx(GridRow, { className: "m-0 w-full", children: _jsx(AgTable, { className: "bill-deduction-table", alternateRowColor: "white", columnDefs: [
                        {
                            field: 'Charge Type',
                            cellRenderer: (params) => {
                                return params?.data?.chargeType ?? DEFAULT_TEXT;
                            },
                            flex: 1
                        },
                        {
                            field: 'Bill Amount',
                            cellRenderer: (params) => {
                                return `${RUPEE_SYMBOL} ${params?.data?.amount?.toFixed(2)}` ?? DEFAULT_TEXT;
                            }
                        },
                        {
                            field: 'Payable Amount',
                            cellRenderer: (params) => {
                                return (`${RUPEE_SYMBOL} ${(params?.data?.amount - params?.data?.nonPayableAmount).toFixed(2)}` ?? DEFAULT_TEXT);
                            }
                        },
                        {
                            field: 'Non-Payable Amount',
                            cellRenderer: (params) => {
                                return (`${RUPEE_SYMBOL} ${params?.data?.nonPayableAmount?.toFixed(2)}` ?? DEFAULT_TEXT);
                            }
                        },
                        {
                            field: 'Reason for Non-Payment',
                            cellRenderer: (params) => {
                                return params?.data?.reason ?? DEFAULT_TEXT;
                            },
                            flex: 2
                        }
                    ], rowClassRules: {
                        'row-total': params => {
                            return params.rowIndex === deductionList.length - 1;
                        }
                    }, rowData: deductionList, domLayout: "autoHeight" }) })] }));
};
export default BillLevelDeduction;
