export const STATE_LIST = [
    { label: 'ANDAMAN AND NICOBAR ISLANDS', value: 'ANDAMAN AND NICOBAR ISLANDS' },
    { label: 'ANDHRA PRADESH', value: 'ANDHRA PRADESH' },
    { label: 'ARUNACHAL PRADESH', value: 'ARUNACHAL PRADESH' },
    { label: 'ASSAM', value: 'ASSAM' },
    { label: 'BIHAR', value: 'BIHAR' },
    { label: 'CHANDIGARH', value: 'CHANDIGARH' },
    { label: 'CHHATTISGARH', value: 'CHHATTISGARH' },
    { label: 'DADRA AND NAGAR HAVELI', value: 'DADRA AND NAGAR HAVELI' },
    { label: 'DAMAN AND DIU', value: 'DAMAN AND DIU' },
    { label: 'DELHI', value: 'DELHI' },
    { label: 'GOA', value: 'GOA' },
    { label: 'GUJARAT', value: 'GUJARAT' },
    { label: 'HARYANA', value: 'HARYANA' },
    { label: 'HIMACHAL PRADESH', value: 'HIMACHAL PRADESH' },
    { label: 'JAMMU AND KASHMIR', value: 'JAMMU AND KASHMIR' },
    { label: 'JHARKHAND', value: 'JHARKHAND' },
    { label: 'KARNATAKA', value: 'KARNATAKA' },
    { label: 'KERALA', value: 'KERALA' },
    { label: 'LAKSHADWEEP', value: 'LAKSHADWEEP' },
    { label: 'MADHYA PRADESH', value: 'MADHYA PRADESH' },
    { label: 'MAHARASHTRA', value: 'MAHARASHTRA' },
    { label: 'MANIPUR', value: 'MANIPUR' },
    { label: 'MEGHALAYA', value: 'MEGHALAYA' },
    { label: 'MIZORAM', value: 'MIZORAM' },
    { label: 'NAGALAND', value: 'NAGALAND' },
    { label: 'ODISHA', value: 'ODISHA' },
    { label: 'PONDICHERRY', value: 'PONDICHERRY' },
    { label: 'PUNJAB', value: 'PUNJAB' },
    { label: 'RAJASTHAN', value: 'RAJASTHAN' },
    { label: 'SIKKIM', value: 'SIKKIM' },
    { label: 'TAMILNADU', value: 'TAMILNADU' },
    { label: 'TELANGANA', value: 'TELANGANA' },
    { label: 'TRIPURA', value: 'TRIPURA' },
    { label: 'UTTAR PRADESH', value: 'UTTAR PRADESH' },
    { label: 'UTTARAKHAND', value: 'UTTARAKHAND' },
    { label: 'WEST BENGAL', value: 'WEST BENGAL' }
];
