export const LoginInputScreenConstants = {
    desktopTitle: 'Log in',
    subTitle: 'Use your registered mobile number to login',
    inputPlaceholder: 'Enter mobile number',
    buttonText: 'Get OTP',
    mobileNumberError: 'Enter 10 digit valid mobile number'
};
export const OTPInputScreenConstants = {
    title: 'Enter OTP',
    subTitle: 'OTP sent to ',
    changeButtonText: 'Change',
    OTPMessageBeforeTimeout: 'Re-sending OTP in ',
    ResendOTP: 'Resend OTP ',
    OTPMessageOnInput: 'Enter 4 digits OTP or ',
    InvalidOTP: 'Invalid OTP, Try again or ',
    VerifyOTP: 'Verify OTP',
    errorMessage: 'OTP could not be sent, some error occurred',
    successMessage: 'OTP sent successfully'
};
export const LogoutConstants = {
    logoutSuccess: 'Logged out successfully'
};
