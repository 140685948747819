import { ACTION_TYPES } from '@redux/ReduxActionTypes';
import { getClaimsListByFilter } from '@services/ClaimApi';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { getErrorMessage } from '@utils/';
import { checkTokenIssue } from '../utils';
const { GLOBAL_FILTER_TYPES } = ACTION_TYPES;
export const setFilteredListData = (payload) => ({
    type: GLOBAL_FILTER_TYPES.SET_FILTERED_DATA,
    payload
});
export const setDashBoardUrl = (payload) => ({
    type: GLOBAL_FILTER_TYPES.SET_DASHBOARD_URL,
    payload
});
export const setFilterDetailsFetching = (payload) => ({
    type: GLOBAL_FILTER_TYPES.SET_FILTER_DATA_FETCHING,
    payload
});
export const resetFilteredListData = () => ({
    type: GLOBAL_FILTER_TYPES.SET_FILTERED_DATA
});
export const getFilteredListAPI = (query) => {
    return function (dispatch) {
        dispatch(setFilterDetailsFetching(true));
        getClaimsListByFilter(query)
            .then((response) => {
            if (!response.data?.claims) {
                throw response;
            }
            dispatch(setFilteredListData(response?.data));
        })
            .catch((error) => {
            toast.error(getErrorMessage(error.message));
            checkTokenIssue(error, dispatch);
            dispatch(resetFilteredListData());
        })
            .finally(() => dispatch(setFilterDetailsFetching(false)));
    };
};
