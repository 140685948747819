import { COMMON_ACTION_TYPES } from './ActionTypes';
import { initialState as commonInitialState } from './constants';
const commonReducer = (state = commonInitialState, action) => {
    switch (action?.type) {
        case COMMON_ACTION_TYPES.SHOW_LOADER: {
            return {
                ...state,
                apisInProgress: state.apisInProgress + 1
            };
        }
        case COMMON_ACTION_TYPES.HIDE_LOADER: {
            return {
                ...state,
                apisInProgress: state.apisInProgress - 1
            };
        }
        case COMMON_ACTION_TYPES.SET_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.payload
            };
        }
        case COMMON_ACTION_TYPES.SET_UNREAD_NOTIFICATIONS_COUNT: {
            return {
                ...state,
                unreadNotificationsCount: action.payload
            };
        }
        default:
            return state;
    }
};
export default commonReducer;
