import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BottomSheet, Typography } from '@navi/web-ui/lib/primitives';
import React from 'react';
import CustomAccordion from '@common/components/CustomAccordion';
import { documentTagGroups } from '../constants';
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { TabItem, Tabs } from '@navi/web-ui/lib/components';
import DocumentsTable from '@common/components/DocumentsTable';
import { DocumentItemModes } from '@components/DocumentsTable/types';
import ArrowLeftIcon from '@assets/icons/ArrowLeftIcon';
const ViewDocuments = props => {
    const { open, onClose, documents } = props;
    const [segregatedDocuments, setSegregatedDocuments] = React.useState({});
    const [letters, setLetters] = React.useState([]);
    React.useEffect(() => {
        //  TODO: simplify the logic.
        const segregatedDocuments = {};
        Object.keys(documentTagGroups).map(tagBucket => {
            const docs = documents?.filter(document => documentTagGroups[tagBucket].tags.some((r) => Array.isArray(document.documentTag)
                ? document.documentTag?.includes(r)
                : document.documentTag?.split(',').includes(r)));
            if (tagBucket == 'LETTERS') {
                setLetters(docs);
            }
            else {
                segregatedDocuments[tagBucket] = docs;
            }
        });
        setSegregatedDocuments(segregatedDocuments);
    }, [documents]);
    const renderDocuments = () => Object.keys(segregatedDocuments).map(tagCategory => segregatedDocuments[tagCategory]?.length ? (_jsx(CustomAccordion, { title: documentTagGroups[tagCategory].label, children: _jsx(DocumentsTable, { documents: segregatedDocuments[tagCategory], mode: DocumentItemModes.VIEW_DOCUMENT }) })) : null);
    const renderLetters = () => (_jsx("div", { className: "bg-base-white p-6", children: _jsx(DocumentsTable, { documents: letters, mode: DocumentItemModes.VIEW_LETTER }) }));
    return (_jsx(BottomSheet, { fullScreen: true, onClose: onClose, open: open, children: _jsx(GridContainer, { children: _jsxs(GridRow, { children: [_jsx(GridColumn, { xs: 1, className: "flex justify-end pt-2", children: _jsx(ArrowLeftIcon, { color: "#585757", width: 32, height: 32, onClick: onClose, className: "cursor-pointer" }) }), _jsxs(GridColumn, { xs: 10, children: [_jsx(Typography, { variant: "h1", children: "Documents and Letters" }), _jsxs(Tabs, { tabsClassName: "mt-6", contentClassName: "mt-8", children: [_jsx(TabItem, { label: "Documents", children: renderDocuments() }, "documents"), _jsx(TabItem, { label: "Letters", children: renderLetters() }, "letters")] })] })] }) }) }));
};
export default ViewDocuments;
