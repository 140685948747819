import { useLocation } from 'react-router-dom';
import { omitBy, trim, mapValues } from 'lodash';
export const convertToTitleCase = (word) => {
    let result = '';
    if (word?.length) {
        const wordArr = word?.split('_');
        result = wordArr?.join(' ').toLowerCase();
        result = result?.replace(result[0], result[0]?.toUpperCase());
    }
    return result;
};
export const noopFunction = () => { };
const parseJSON = (err) => {
    try {
        if (typeof err === 'object' && err !== null && !Array.isArray(err))
            return { isJson: true, errData: err };
        return { isJson: true, errData: JSON.parse(err) };
    }
    catch (error) {
        return { isJson: false, errData: null };
    }
};
export const getErrorMessage = (err) => {
    const { isJson, errData } = parseJSON(err);
    const errMessage = 'Something went wrong!!';
    if (isJson) {
        if (errData?.errors?.length) {
            return errData.errors?.map((er) => er?.message ?? errMessage).join(', ');
        }
        if (errData?.message)
            return errData.message;
        if (errData?.data?.message)
            return errData.data.message;
        if (errData?.error) {
            return errData.error?.message ?? errMessage;
        }
        return errMessage;
    }
    return err ?? errMessage;
};
export const useURLSearchParams = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    return Object.fromEntries(params);
};
export const formatAmount = (amount) => {
    if (!amount)
        return;
    return `₹ ${amount.toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        currency: 'INR'
    })}`;
};
const trimSpaces = (parameters) => mapValues(parameters, trim);
const omitEmptyValues = (parameters) => omitBy(parameters, value => value === null || value === undefined || value === '');
export const toSearchParams = (parameters) => new URLSearchParams(trimSpaces(omitEmptyValues(parameters))).toString();
// Removes any key with null, undefined, or blank value
export const clean = (obj) => {
    for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
};
export const isEmptyObject = (obj) => {
    if (obj === null || obj === undefined) {
        return true;
    }
    for (const _ in obj)
        return false;
    return true;
};
export const MbToBytes = (val) => {
    return val * 1024 * 1024;
};
export const generateArrayOfYears = () => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const endYear = currentMonth > 2 ? currentYear : currentYear - 1;
    const startYear = 2021;
    const years = [];
    for (let i = endYear; i >= startYear; i--) {
        years.push({
            label: `F.Y. ${i}-${i + 1}`,
            value: `${i}-${i + 1}`
        });
    }
    return years;
};
export const getReadableString = (input, separator = '_') => {
    if (!input)
        return input;
    return input.split(separator).join(' ').toLowerCase();
};
export const convertStringToEllipsis = (text, length) => {
    return `${text.slice(0, length)}...`;
};
