import { ACTION_TYPES } from '@redux/ReduxActionTypes';
import { initialState as gloablFilterInitialState } from './constants';
const { GLOBAL_FILTER_TYPES } = ACTION_TYPES;
const globalFilterReducer = (state = gloablFilterInitialState, action) => {
    switch (action?.type) {
        case GLOBAL_FILTER_TYPES.SET_FILTERED_DATA: {
            return {
                ...state,
                filteredList: action?.payload
            };
        }
        case GLOBAL_FILTER_TYPES.SET_DASHBOARD_URL: {
            return {
                ...state,
                dashboardUrl: action?.payload
            };
        }
        case GLOBAL_FILTER_TYPES.SET_FILTER_DATA_FETCHING: {
            return {
                ...state,
                isFilterDataFetching: action?.payload
            };
        }
        default:
            return state;
    }
};
export default globalFilterReducer;
