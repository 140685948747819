// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ytc2PvPAuAxeZGoThaHG input[type=number]::-webkit-outer-spin-button,.ytc2PvPAuAxeZGoThaHG input[type=number]::-webkit-inner-spin-button{-webkit-appearance:none}.ytc2PvPAuAxeZGoThaHG input[type=number]{-moz-appearance:textfield}.TChERPmgIYuw0BD6YCiG div{margin-bottom:12px !important}.zXpLCNLTme6YoSlhg7lq{box-shadow:0px 4px 24px rgba(0,0,0,.08)}", "",{"version":3,"sources":["webpack://./src/pages/ClaimJourney/ClaimJourney.module.scss"],"names":[],"mappings":"AACI,wIAEE,uBAAA,CAEF,yCACA,yBAAA,CAQJ,0BACI,6BAAA,CAGJ,sBACE,uCAAA","sourcesContent":["@mixin hideArrowsOnNumberInput() {\n    input[type='number']::-webkit-outer-spin-button,\n    input[type='number']::-webkit-inner-spin-button {\n      -webkit-appearance: none;\n    }\n    input[type='number'] {\n    -moz-appearance: textfield;\n    }\n}\n\n.number-only {\n  @include hideArrowsOnNumberInput();\n}\n\n.header-details div {\n    margin-bottom: 12px !important;\n  }\n\n.header-card {\n  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"number-only": "ytc2PvPAuAxeZGoThaHG",
	"header-details": "TChERPmgIYuw0BD6YCiG",
	"header-card": "zXpLCNLTme6YoSlhg7lq"
};
export default ___CSS_LOADER_EXPORT___;
