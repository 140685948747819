import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography } from '@navi/web-ui/lib/primitives';
import React from 'react';
import { EditIcon, UploadIcon } from '@navi/web-ui/lib/icons';
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { getErrorMessage, isEmptyObject } from '@utils/';
import HospitalDocumentItem from './HospitalDocumentItem';
import { DocumentItemModes } from '@components/DocumentsTable/types';
import { uploadDocuments } from '@services/DocumentApi';
import { hideLoader, showLoader } from '@redux/common/common.actions';
import { useDispatch } from 'react-redux';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { format, ISODateFormatTimeSec } from '@utils/Date';
import { DocumentStatus } from '../types';
import { uploadHospitalDocuments } from '@services/HospitalApi';
import { getHospitalDocumentsPayload } from '../utils';
import { STATUS_CODE } from '@const/ApiConstants';
const renderDocumentHeader = () => (_jsxs(_Fragment, { children: [_jsx(Typography, { className: "flex-1 text-primary-grey", variant: "p3", children: "Document Name" }), _jsx(Typography, { className: "flex-1 text-primary-grey", variant: "p3", children: "Upload Date" }), _jsx(Typography, { className: "flex-1 text-primary-grey", variant: "p3", children: "Document Type" }), _jsx(Typography, { className: "flex-1 text-primary-grey", variant: "p3", children: "Valid From" }), _jsx(Typography, { className: "flex-1 text-primary-grey", variant: "p3", children: "Valid Till" }), _jsx(Typography, { className: "flex-1 text-primary-grey", variant: "p3", children: "Status" }), _jsx(Typography, { className: "flex-1 text-primary-grey", variant: "p3", children: "Actions" })] }));
const Documents = props => {
    const { hospitalDetails, setHospitalDetails } = props;
    const { documents = [], hospitalId } = hospitalDetails || {};
    const [hospitalDocuments, setHospitalDocuments] = React.useState(documents);
    const [newDocumentsUploaded, setNewDocumentsUploaded] = React.useState(false);
    const [documentsError, setDocumentsError] = React.useState(false);
    const [isEditMode, setIsEditMode] = React.useState(false);
    React.useEffect(() => {
        if (documents?.length) {
            const pendingDocuments = documents?.filter(doc => doc.status === DocumentStatus.PENDING);
            const otherDocuments = documents?.filter(doc => doc.status !== DocumentStatus.PENDING);
            setHospitalDocuments([...pendingDocuments, ...otherDocuments]);
            setIsEditMode(!documents?.filter(doc => doc.status == DocumentStatus.PENDING).length);
        }
    }, [documents]);
    const fileInputRef = React.useRef(null);
    const dispatch = useDispatch();
    const onRemoveFile = (referenceId) => {
        setHospitalDocuments(hospitalDocuments?.filter((document) => document.documentReferenceId !== referenceId));
    };
    const handleUploadDocument = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const Api = uploadDocuments(selectedFile);
            if (Api) {
                dispatch(showLoader());
                Api.then((response) => {
                    if (response?.data?.referenceId) {
                        setNewDocumentsUploaded(true);
                        setIsEditMode(true);
                        setHospitalDocuments([
                            {
                                fileName: selectedFile.name,
                                createdAt: format(new Date(), ISODateFormatTimeSec),
                                tag: '',
                                validFrom: '',
                                validTill: '',
                                status: DocumentStatus.PENDING,
                                documentReferenceId: response.data.referenceId,
                                mode: DocumentItemModes.UPLOAD
                            },
                            ...hospitalDocuments
                        ]);
                        return;
                    }
                    throw response;
                })
                    .catch((error) => {
                    toast.error(getErrorMessage(error?.data?.message));
                })
                    .finally(() => {
                    dispatch(hideLoader());
                });
            }
        }
    };
    const submitHandler = () => {
        const docDetails = hospitalDocuments?.filter((doc) => !doc.tag || !doc.validFrom || !doc.validTill);
        if (docDetails.length) {
            setDocumentsError(true);
            return;
        }
        const payload = getHospitalDocumentsPayload(hospitalDocuments);
        dispatch(showLoader());
        uploadHospitalDocuments(hospitalId, payload)
            .then((response) => {
            if (response?.data?.code === STATUS_CODE.PARTNER_PORTAL_OK) {
                setHospitalDetails(response.data?.data);
                toast.success('Documents submitted successfully');
                return;
            }
            throw response;
        })
            .catch((error) => {
            toast.error(getErrorMessage(error?.data?.message));
        })
            .finally(() => {
            setNewDocumentsUploaded(false);
            setIsEditMode(false);
            dispatch(hideLoader());
        });
    };
    return (_jsxs(GridContainer, { className: "p-0 mt-8", children: [_jsxs(GridRow, { className: "m-0", children: [_jsx(GridColumn, { className: "p-0", xs: 6, children: _jsxs(Typography, { variant: "h2", className: "mb-6 text-regal-grey font-semibold", children: ["Documents", _jsx(Button, { startAdornment: _jsx(UploadIcon, { color: "#0276FE" }), variant: "secondary", onClick: () => fileInputRef?.current?.click(), className: "ml-6 text-primary-blue border-primary-blue", 
                                    // Remove disabled once Ops Portal flow is completed
                                    disabled: true, children: "Upload New" }), _jsx("input", { type: "file", accept: "image/jpeg,image/jpg,image/png,application/pdf", name: "claim-document-upload", ref: fileInputRef, onChange: handleUploadDocument, hidden: true })] }) }), newDocumentsUploaded && (_jsx(GridColumn, { className: "p-0 text-right", xs: 6, children: _jsx(Button, { variant: "primary", onClick: submitHandler, className: "ml-6", children: "Submit" }) })), !isEditMode && (_jsx(GridColumn, { className: "p-0 text-right", xs: 6, children: _jsx(Button, { startAdornment: _jsx(EditIcon, { color: "#FFFFFF" }), variant: "primary", onClick: () => {
                                setIsEditMode(true);
                                setNewDocumentsUploaded(true);
                            }, className: "ml-6", 
                            // Remove disabled once Ops Portal flow is completed
                            disabled: true, children: "Edit" }) }))] }), _jsx(GridRow, { className: "m-0 block", style: { boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)' }, children: _jsxs(_Fragment, { children: [_jsx("div", { className: "flex justify-between py-7 px-8 bg-white", children: renderDocumentHeader() }), isEmptyObject(hospitalDocuments) ? (_jsx(Typography, { variant: "p3", className: "text-center py-[34px] bg-regal-blue-40 text-primary-grey", children: "No documents to show" })) : (Object.keys(hospitalDocuments).map((item, index) => (_jsx(HospitalDocumentItem, { mode: hospitalDocuments[item].mode, document: hospitalDocuments[item], onRemoveFile: onRemoveFile, setHospitalDocuments: setHospitalDocuments, documents: hospitalDocuments, index: index, documentsError: documentsError, hospitalId: hospitalId, editMode: isEditMode }, hospitalDocuments[item].documentReferenceId))))] }) })] }));
};
export default Documents;
