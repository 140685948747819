import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AgTable } from '@navi/web-ui/lib/components';
import { formatAmount } from '@utils/';
import { DEFAULT_TEXT } from '@const/Symbols';
import { BusinessDateFormatTime, getFormattedDate, ISODateFormatTimeSec } from '@utils/Date';
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import Typography from '@navi/web-ui/lib/primitives/Typography';
import Card from '@navi/web-ui/lib/primitives/Card';
import { StatusColorEnum } from './StatusRemark/constant';
import { Tag } from '@navi/web-ui/lib/primitives';
import { PreAuthClaimStatus } from '@enums/ClaimEnums';
const getAuthStatus = (authStatus) => {
    switch (authStatus) {
        case PreAuthClaimStatus.APPROVED:
            return { label: 'Approved', color: StatusColorEnum.GREEN };
        case PreAuthClaimStatus.REJECTED:
            return { label: 'Rejected', color: StatusColorEnum.RED };
        case PreAuthClaimStatus.MISSING_INFO_REQUESTED:
            return { label: 'Missing Info', color: StatusColorEnum.ORANGE };
        case PreAuthClaimStatus.CANCELLED:
            return { label: 'Cancelled', color: StatusColorEnum.PRIMARY };
        default:
            return { label: 'Pending', color: StatusColorEnum.YELLOW };
    }
};
const tableColumnDefinition = [
    {
        headerName: 'Authorization No.',
        field: 'preAuthId'
    },
    {
        headerName: 'Billed Amount',
        field: 'budget',
        cellRenderer: (row) => {
            return formatAmount(row.data.budget) ?? DEFAULT_TEXT;
        }
    },
    {
        headerName: 'Raised Date and Time',
        field: 'receivedAt',
        cellRenderer: (row) => {
            return (getFormattedDate(row.data.receivedAt, ISODateFormatTimeSec, BusinessDateFormatTime) ??
                DEFAULT_TEXT);
        }
    },
    {
        headerName: 'Authorization Type',
        field: 'type'
    },
    {
        headerName: 'Status',
        cellRenderer: (row) => {
            const authStatus = getAuthStatus(row.data.status);
            return _jsx(Tag, { label: authStatus.label, color: authStatus.color, variant: "transparent" });
        }
    },
    {
        headerName: 'Approved Amount',
        field: 'approvedAmount',
        cellRenderer: (row) => {
            return formatAmount(parseInt(row.data.approvedAmount, 10)) ?? DEFAULT_TEXT;
        }
    },
    {
        headerName: 'Processed Date and Time',
        field: 'dateProcessed',
        cellRenderer: (row) => {
            return (getFormattedDate(row.data.dateProcessed, ISODateFormatTimeSec, BusinessDateFormatTime) ??
                DEFAULT_TEXT);
        }
    }
];
export const columnDefinitions = {};
const AuthorizationTable = ({ authorizationList }) => {
    const defaultColDef = {
        cellStyle: {
            lineHeight: 2
        },
        wrapText: true,
        autoHeight: true
    };
    return (_jsx(Card, { className: "custom-card", children: _jsx(GridContainer, { children: _jsxs(GridRow, { children: [_jsx(GridColumn, { className: "pb-8", children: _jsx(Typography, { className: "text-regal-grey", variant: "h2", children: "Authorization Table" }) }), _jsx(GridColumn, { children: _jsx(AgTable, { columnDefs: tableColumnDefinition, rowData: authorizationList, theme: "alpine", sizeColumnsToFit: true, defaultColDef: defaultColDef, domLayout: "autoHeight" }) })] }) }) }));
};
export default AuthorizationTable;
