import { ClaimStatus } from '@common/enums/ClaimStatus';
import { PreAuthType } from '@common/enums/PreAuthType';
import { PreAuthClaimStatus } from '@enums/ClaimEnums';
import { columnDefinitions } from '@const/Claim';
export const DashboardBucketDetails = {
    DRAFT: {
        label: 'Draft Requests',
        params: {},
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.STATUS
        ]
    },
    PRE_AUTH_PENDING_WITH_NAVI: {
        label: 'Pending with NAVI',
        params: {
            preAuthClaimStatuses: [PreAuthClaimStatus.PENDING_ADJUDICATION]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.SUBMITTED_ON,
            columnDefinitions.AGEING,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.PRE_AUTH_TYPE
        ]
    },
    PRE_AUTH_APPROVED: {
        label: 'Pre-auth Approved',
        params: {
            preAuthClaimStatuses: [PreAuthClaimStatus.APPROVED],
            preAuthClaimTypes: [PreAuthType.INITIAL, PreAuthType.ENHANCEMENT]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.APPROVED_ON,
            columnDefinitions.PROCESSING_TIME,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.PRE_AUTH_TYPE
        ]
    },
    PRE_AUTH_MISSING_INFO: {
        label: 'Missing Info',
        params: {
            preAuthClaimStatuses: [PreAuthClaimStatus.MISSING_INFO_REQUESTED]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.RAISED_ON,
            columnDefinitions.AGEING,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.PRE_AUTH_TYPE
        ]
    },
    DISCHARGE_APPROVED: {
        label: 'Discharge Approved',
        params: {
            claimStatus: [ClaimStatus.DISCHARGE_REQUEST_APPROVED]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.APPROVED_ON,
            columnDefinitions.PROCESSING_TIME,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.APPROVED_AMOUNT
        ]
    },
    TRANSACTION_REJECTED: {
        label: 'Transaction rejected',
        params: {
            preAuthClaimStatuses: [PreAuthClaimStatus.REJECTED],
            preAuthClaimTypes: [PreAuthType.INITIAL, PreAuthType.ENHANCEMENT]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.REJECTED_ON,
            columnDefinitions.PROCESSING_TIME,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.PRE_AUTH_TYPE
        ]
    },
    MAIN_CLAIM_REJECTED: {
        label: 'Claim Rejected',
        params: {
            claimStatus: [ClaimStatus.CLAIM_REJECTED]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.REJECTED_ON,
            columnDefinitions.PROCESSING_TIME,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT
        ]
    },
    MAIN_CLAIM_DOCUMENTS_AWAITED: {
        label: 'Claim Documents Awaited',
        params: {
            claimStatus: [ClaimStatus.CASHLESS_DOCUMENT_AWAITED]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.RAISED_ON,
            columnDefinitions.AGEING,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.APPROVED_AMOUNT
        ]
    },
    MAIN_CLAIM_PENDING_WITH_NAVI: {
        label: 'Pending with NAVI',
        params: {
            claimStatus: [PreAuthClaimStatus.PENDING_ADJUDICATION]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.SUBMITTED_ON,
            columnDefinitions.AGEING,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.APPROVED_AMOUNT
        ]
    },
    MAIN_CLAIM_MISSING_INFO: {
        label: 'Missing Info',
        params: {
            claimStatus: [ClaimStatus.MISSING_INFO]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.RAISED_ON,
            columnDefinitions.AGEING,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.APPROVED_AMOUNT
        ]
    },
    MAIN_CLAIM_APPROVED: {
        label: 'Claim Approved',
        params: {
            claimStatus: [ClaimStatus.CLAIM_APPROVED]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.APPROVED_ON,
            columnDefinitions.PROCESSING_TIME,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.APPROVED_AMOUNT
        ]
    },
    MAIN_CLAIM_SETTLED: {
        label: 'Claim Settled',
        params: {
            claimStatus: [ClaimStatus.CLAIM_SETTLED]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.SETTLED_ON,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT,
            columnDefinitions.SETTLED_AMOUNT
        ]
    },
    MAIN_CLAIM_CANCELLED: {
        label: 'Claim Cancelled',
        params: {
            claimStatus: [ClaimStatus.CLAIM_CANCELLED]
        },
        columns: [
            columnDefinitions.PATIENT_NAME,
            columnDefinitions.CLAIM_ID,
            columnDefinitions.IP_NUMBER,
            columnDefinitions.MEMBER_ID,
            columnDefinitions.CANCELLED_ON,
            columnDefinitions.PROCESSING_TIME,
            columnDefinitions.DOA,
            columnDefinitions.DOD,
            columnDefinitions.CLAIM_AMOUNT
        ]
    }
};
export const DashboardBuckets = {
    DRAFT: 'DRAFT',
    PRE_AUTH_PENDING_WITH_NAVI: 'PRE_AUTH_PENDING_WITH_NAVI',
    PRE_AUTH_MISSING_INFO: 'PRE_AUTH_MISSING_INFO',
    PRE_AUTH_APPROVED: 'PRE_AUTH_APPROVED',
    DISCHARGE_APPROVED: 'DISCHARGE_APPROVED',
    TRANSACTION_REJECTED: 'TRANSACTION_REJECTED',
    MAIN_CLAIM_REJECTED: 'MAIN_CLAIM_REJECTED',
    MAIN_CLAIM_DOCUMENTS_AWAITED: 'MAIN_CLAIM_DOCUMENTS_AWAITED',
    MAIN_CLAIM_PENDING_WITH_NAVI: 'MAIN_CLAIM_PENDING_WITH_NAVI',
    MAIN_CLAIM_MISSING_INFO: 'MAIN_CLAIM_MISSING_INFO',
    MAIN_CLAIM_APPROVED: 'MAIN_CLAIM_APPROVED',
    MAIN_CLAIM_SETTLED: 'MAIN_CLAIM_SETTLED',
    MAIN_CLAIM_CANCELLED: 'MAIN_CLAIM_CANCELLED'
};
