import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { BorderedInput, Card, Typography } from '@navi/web-ui/lib/primitives';
import React from 'react';
import CustomSelectInput from '@components/CustomSelectInput';
import { useDispatch } from 'react-redux';
import { getClaimMasterDataRequest } from '@redux/claimJourney/claimJourney.actions';
import { ClaimMasterListEnum } from '@enums/ClaimMasterListEnum';
import { selectClaimMasterAdmissionType, selectClaimMasterCaseType, selectClaimMasterHospitalizationType } from '@redux/claimJourney/claimJourney.selector';
import { useAppSelector } from '@redux/hooks';
import { ACCIDENTAL_ADMISSION_TYPE, convertMasterValueToOptionList, MATERNITY_ADMISSION_TYPE } from '../constants';
import { FormItemVisibilityEnum, HospitalizationDetailsConfig } from '../ClaimJourneyConfig';
import AlertOutlineIcon from '@src/assets/icons/AlertOutlineIcon';
const HospitalizationDetails = ({ handleChange, errors, values, touched, setFieldValue, selectedAction }) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (!AdmissionTypeList?.length) {
            dispatch(getClaimMasterDataRequest(ClaimMasterListEnum.ADMISSION_TYPE));
        }
        if (!CaseTypeList?.length) {
            dispatch(getClaimMasterDataRequest(ClaimMasterListEnum.CASE_TYPE));
        }
        if (!HospitalizationTypeList?.length) {
            dispatch(getClaimMasterDataRequest(ClaimMasterListEnum.HOSPITALIZATION_TYPE));
        }
    }, []);
    const AdmissionTypeList = convertMasterValueToOptionList(useAppSelector(selectClaimMasterAdmissionType));
    const CaseTypeList = convertMasterValueToOptionList(useAppSelector(selectClaimMasterCaseType));
    const HospitalizationTypeList = convertMasterValueToOptionList(useAppSelector(selectClaimMasterHospitalizationType));
    return (_jsx(Card, { className: "custom-card", children: _jsxs(GridContainer, { children: [_jsx(GridRow, { children: _jsx(GridColumn, { className: "pb-8", children: _jsx(Typography, { className: "text-regal-grey", variant: "h2", children: "Hospitalization Details" }) }) }), _jsxs(GridRow, { children: [_jsx(GridColumn, { xs: 5, className: "input-box", children: _jsx(CustomSelectInput, { inputLabel: "Hospitalization Type", placeholder: "Hospitalization Type", options: HospitalizationTypeList, value: values.ailmentDetails?.hospitalisationType, name: "ailmentDetails.hospitalisationType", multiSelect: false, readOnly: true, setSelectedValue: (e) => setFieldValue('ailmentDetails.hospitalisationType', e), disabled: HospitalizationDetailsConfig.hospitalisationType?.[selectedAction]?.[FormItemVisibilityEnum.isDisabled], error: touched?.ailmentDetails?.hospitalisationType &&
                                    errors?.ailmentDetails?.hospitalisationType
                                    ? errors.ailmentDetails.hospitalisationType
                                    : '', required: true }) }), _jsx(GridColumn, { xs: 5, className: "input-box", children: _jsx(CustomSelectInput, { inputLabel: "Treatment Type", placeholder: "Treatment Type", options: CaseTypeList, value: values.ailmentDetails?.treatment, name: "ailmentDetails.treatment", multiSelect: false, readOnly: true, setSelectedValue: (e) => setFieldValue('ailmentDetails.treatment', e), disabled: HospitalizationDetailsConfig.treatment[selectedAction]?.[FormItemVisibilityEnum.isDisabled], error: touched?.ailmentDetails?.treatment && errors?.ailmentDetails?.treatment
                                    ? errors.ailmentDetails.treatment
                                    : '', required: true }) }), _jsx(GridColumn, { xs: 5, className: "input-box", children: _jsx(CustomSelectInput, { inputLabel: "Admission Type", placeholder: "Admission Type", options: AdmissionTypeList, value: values.ailmentDetails?.tpaAdmissionType, name: "ailmentDetails.tpaAdmissionType", multiSelect: false, readOnly: true, setSelectedValue: (e) => setFieldValue('ailmentDetails.tpaAdmissionType', e), disabled: HospitalizationDetailsConfig.tpaAdmissionType[selectedAction]?.[FormItemVisibilityEnum.isDisabled], error: touched?.ailmentDetails?.tpaAdmissionType &&
                                    errors?.ailmentDetails?.tpaAdmissionType
                                    ? errors.ailmentDetails.tpaAdmissionType
                                    : '', required: true }) }), _jsxs(GridColumn, { xs: 5, className: "input-box", children: [values.ailmentDetails?.tpaAdmissionType === ACCIDENTAL_ADMISSION_TYPE && (_jsx(BorderedInput, { placeholder: "Date of Accident", type: "date", onChange: handleChange, value: values.ailmentDetails?.dateOfAccident || '', name: "ailmentDetails.dateOfAccident", inputLabel: "Date of Accident", required: true, disabled: HospitalizationDetailsConfig.dateOfAccident[selectedAction]?.[FormItemVisibilityEnum.isDisabled], error: touched?.ailmentDetails?.dateOfAccident && errors?.ailmentDetails?.dateOfAccident
                                        ? errors.ailmentDetails.dateOfAccident
                                        : '', Icon: _jsx(AlertOutlineIcon, {}) })), values.ailmentDetails?.tpaAdmissionType === MATERNITY_ADMISSION_TYPE && (_jsx(BorderedInput, { placeholder: "Date of Delivery", type: "date", onChange: handleChange, value: values.ailmentDetails?.dateOfDelivery || '', name: "ailmentDetails.dateOfDelivery", inputLabel: "Date of Delivery", disabled: HospitalizationDetailsConfig.dateOfDelivery[selectedAction]?.[FormItemVisibilityEnum.isDisabled], error: touched?.ailmentDetails?.dateOfDelivery && errors?.ailmentDetails?.dateOfDelivery
                                        ? errors.ailmentDetails.dateOfDelivery
                                        : '', Icon: _jsx(AlertOutlineIcon, {}) })), ![MATERNITY_ADMISSION_TYPE, ACCIDENTAL_ADMISSION_TYPE].includes(values.ailmentDetails?.tpaAdmissionType) && (_jsx(BorderedInput, { placeholder: "Date of first Consultation", type: "date", onChange: handleChange, value: values.ailmentDetails?.dateOfDiagnosis || '', name: "ailmentDetails.dateOfDiagnosis", inputLabel: "Date of first Consultation", required: true, disabled: HospitalizationDetailsConfig.dateOfDiagnosis[selectedAction]?.[FormItemVisibilityEnum.isDisabled], error: touched?.ailmentDetails?.dateOfDiagnosis &&
                                        errors?.ailmentDetails?.dateOfDiagnosis
                                        ? errors.ailmentDetails.dateOfDiagnosis
                                        : '', Icon: _jsx(AlertOutlineIcon, {}) }))] }), _jsx(GridColumn, { xs: 5, children: _jsx(BorderedInput, { placeholder: "IP Number", type: "text", onChange: handleChange, maxLength: 15, value: values.ailmentDetails?.inPatientNumber, disabled: HospitalizationDetailsConfig.inPatientNumber[selectedAction]?.[FormItemVisibilityEnum.isDisabled], error: touched?.ailmentDetails?.inPatientNumber && errors?.ailmentDetails?.inPatientNumber
                                    ? errors.ailmentDetails.inPatientNumber
                                    : '', name: "ailmentDetails.inPatientNumber", inputLabel: "IP Number", Icon: _jsx(AlertOutlineIcon, {}) }) })] })] }) }));
};
export default HospitalizationDetails;
