// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lBOGFn4vHLKiRiSt08cT{padding:14px 18px;background-color:rgba(230,241,255,.4);border-bottom:1px solid rgba(202,206,213,.5);position:relative;cursor:pointer}.lBOGFn4vHLKiRiSt08cT:first-child{border-top:1px solid rgba(202,206,213,.5)}.lBOGFn4vHLKiRiSt08cT::after{content:\"\";position:absolute;right:24px;top:12px;width:8px;height:8px;background-color:#0276fe;border-radius:50%}.lBOGFn4vHLKiRiSt08cT.oyUWQaMrj23gvAxVQdws{background-color:#fff}.lBOGFn4vHLKiRiSt08cT.oyUWQaMrj23gvAxVQdws::after{display:none}", "",{"version":3,"sources":["webpack://./src/pages/Notifications/Notifications.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,qCAAA,CACA,4CAAA,CACA,iBAAA,CACA,cAAA,CAEA,kCACE,yCAAA,CAEF,6BACE,UAAA,CACA,iBAAA,CACA,UAAA,CACA,QAAA,CACA,SAAA,CACA,UAAA,CACA,wBAAA,CACA,iBAAA,CAEF,2CACE,qBAAA,CAEF,kDACI,YAAA","sourcesContent":[".notification-item {\n  padding: 14px 18px;\n  background-color: rgb(230, 241, 255, 0.4);\n  border-bottom: 1px solid rgba(202, 206, 213, 0.5);\n  position: relative;\n  cursor: pointer;\n}\n  .notification-item:first-child {\n    border-top: 1px solid rgba(202, 206, 213, 0.5);\n  }\n  .notification-item::after {\n    content: '';\n    position: absolute;\n    right: 24px;\n    top: 12px;\n    width: 8px;\n    height: 8px;\n    background-color: #0276fe;\n    border-radius: 50%;\n  }\n  .notification-item.read {\n    background-color: white;\n  }\n  .notification-item.read::after {\n      display: none;\n    }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification-item": "lBOGFn4vHLKiRiSt08cT",
	"read": "oyUWQaMrj23gvAxVQdws"
};
export default ___CSS_LOADER_EXPORT___;
