import { jsx as _jsx } from "react/jsx-runtime";
import UserDetails from '@pages/UserManagement';
import ClaimJourney from '@pages/ClaimJourney';
import { ROUTE_MAPPING } from '@const/Routes';
import SearchPage from '@pages/SearchPage';
import Dashboard from '@pages/Dashboard';
import PreAuth from '@pages/PreAuth';
import HospitalDetails from '@pages/HospitalDetails';
const routes = [
    {
        id: 'Dashboard',
        path: ROUTE_MAPPING.DASHBOARD,
        element: _jsx(Dashboard, {})
    },
    {
        id: 'User Management',
        path: ROUTE_MAPPING.USER_MANAGEMENT,
        element: _jsx(UserDetails, {})
    },
    {
        id: 'Claim Journey',
        path: ROUTE_MAPPING.CLAIM_JOURNEY,
        element: _jsx(ClaimJourney, {})
    },
    {
        id: 'New Claim Journey',
        path: ROUTE_MAPPING.NEW_CLAIM_JOURNEY,
        element: _jsx(ClaimJourney, {})
    },
    {
        id: 'Draft Claim Journey',
        path: ROUTE_MAPPING.DRAFT_CLAIM_JOURNEY,
        element: _jsx(ClaimJourney, {})
    },
    {
        id: 'Pre-auth',
        path: ROUTE_MAPPING.PRE_AUTH_LISTING,
        element: _jsx(PreAuth, {})
    },
    {
        id: 'Network-Reimbursement',
        path: ROUTE_MAPPING.NETWORK_REIMBURSEMENT_LISTING,
        element: _jsx(PreAuth, {}) // rendering the same component as preauth listing
    },
    {
        id: 'Search',
        path: ROUTE_MAPPING.SEARCH,
        element: _jsx(SearchPage, {})
    },
    {
        id: 'Hospital Details',
        path: ROUTE_MAPPING.HOSPITAL_DETAILS,
        element: _jsx(HospitalDetails, {})
    }
];
export default routes;
