import { CLAIM_API_BASE_URL } from '@const/ApiConstants';
import { toSearchParams } from '@utils/';
import { ApiService } from './ApiService';
const ENDPOINTS = {
    CLAIM_ENDPOINT: {
        endpoint: () => `${CLAIM_API_BASE_URL}/claims`
    },
    MASTER_VALUES_ENDPOINT: {
        endpoint: (masterType) => `${CLAIM_API_BASE_URL}/master-data/cached/${masterType}?`
    },
    ICD_LIST_ENDPOINT: {
        endpoint: (queryText) => `${CLAIM_API_BASE_URL}/icd?query=${encodeURIComponent(queryText)}`
    },
    CASHLESS_CLAIM_ENDPOINT: {
        endpoint: () => `${CLAIM_API_BASE_URL}/cashless`
    },
    CLAIM_DETAILS_ENDPOINT: {
        endpoint: () => CLAIM_API_BASE_URL
    }
};
export const getClaimDetails = (claimId) => {
    return ApiService.get(`${ENDPOINTS.CLAIM_ENDPOINT.endpoint()}/claimByIdV2/${claimId}`);
};
export const getClaimMasterValue = (masterType) => {
    return ApiService.get(ENDPOINTS.MASTER_VALUES_ENDPOINT.endpoint(masterType));
};
export const getICDListDetails = (text) => {
    return ApiService.get(ENDPOINTS.ICD_LIST_ENDPOINT.endpoint(text));
};
export const createCashlessClaim = (claimPayload, draftId) => {
    return ApiService.postWithHeader(`${ENDPOINTS.CASHLESS_CLAIM_ENDPOINT.endpoint()}/`, claimPayload, draftId ? { draftId } : {});
};
export const cancelCashlessClaim = (cancelClaimPayload, claimId) => {
    return ApiService.putWithHeader(`${ENDPOINTS.CLAIM_ENDPOINT.endpoint()}/${claimId}/cancel`, cancelClaimPayload, { claimId });
};
export const requestDischargeCashlessClaim = (claimPayload, claimId) => {
    return ApiService.postWithHeader(`${ENDPOINTS.CASHLESS_CLAIM_ENDPOINT.endpoint()}/${claimId}/discharge`, claimPayload, { claimId });
};
export const requestEnhancementCashlessClaim = (claimPayload, claimId) => {
    return ApiService.postWithHeader(`${ENDPOINTS.CASHLESS_CLAIM_ENDPOINT.endpoint()}/${claimId}/enhancement`, claimPayload, { claimId });
};
export const submitNetworkCashlessClaim = (claimPayload, claimId) => {
    return ApiService.postWithHeader(`${ENDPOINTS.CASHLESS_CLAIM_ENDPOINT.endpoint()}/${claimId}/receive-cashless-document`, claimPayload, { claimId });
};
export const getDraftPreAuthClaim = (draftId) => {
    return ApiService.get(`${ENDPOINTS.CASHLESS_CLAIM_ENDPOINT.endpoint()}/drafts/${draftId}`);
};
export const deleteDraftPreAuthClaim = (draftId) => {
    return ApiService.delete(`${ENDPOINTS.CASHLESS_CLAIM_ENDPOINT.endpoint()}/drafts/${draftId}`);
};
export const saveDraftPreAuthClaim = (draftPayload) => {
    return ApiService.post(`${ENDPOINTS.CASHLESS_CLAIM_ENDPOINT.endpoint()}/drafts`, draftPayload);
};
export const getClaimsListByFilter = (query) => {
    return ApiService.get(`${ENDPOINTS.CASHLESS_CLAIM_ENDPOINT.endpoint()}/search?${toSearchParams(query)}`);
};
export const submitMissingInfoClaim = (claimId, payload) => {
    return ApiService.putWithHeader(`${ENDPOINTS.CLAIM_ENDPOINT.endpoint()}/${claimId}/submit-missing-info`, payload, { claimId });
};
export const getHomePageClaimDetails = (hospitalId) => {
    return ApiService.get(`${ENDPOINTS.CLAIM_DETAILS_ENDPOINT.endpoint()}/cashless/aggregate/hospital/${hospitalId}/claim-status`);
};
export const getHomePageFinanceDetails = (hospitalId, query) => {
    return ApiService.get(`${ENDPOINTS.CLAIM_DETAILS_ENDPOINT.endpoint()}/cashless/aggregate/hospital/${hospitalId}/finance?${toSearchParams(query)}`);
};
export const getPendingActionTickets = (hospitalId) => {
    return ApiService.get(`${ENDPOINTS.CLAIM_DETAILS_ENDPOINT.endpoint()}/cashless/aggregate/hospital/${hospitalId}/pending-claims?size=30`);
};
