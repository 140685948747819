import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertIcon } from '@navi/web-ui/lib/icons';
import { GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Card, KeyValueLabel, Tooltip, Typography } from '@navi/web-ui/lib/primitives';
import { RUPEE_SYMBOL } from '@const/Symbols';
const DeductionCard = (props) => {
    const { title, tooltipText, tooltipTitle, mainAmount, subHeaderTitle, deductionDetails } = props;
    return (_jsxs(Card, { className: "base-white p-6 shadow-lg rounded-xl w-3/12", children: [_jsxs(GridRow, { className: "flex justify-between items-center m-0", children: [_jsx(Typography, { className: "text-regal-grey", variant: "h2", children: title }), tooltipText && tooltipTitle && (_jsx(Tooltip, { text: tooltipText, position: "top", children: _jsxs(Typography, { className: "text-primary-blue flex items-center cursor-pointer", variant: "h5", children: [_jsx(AlertIcon, { className: "w-5", color: "#0276FE" }), " ", tooltipTitle] }) }))] }), mainAmount && (_jsxs(Typography, { className: "text-base-black mt-3", variant: "h2", children: [RUPEE_SYMBOL, " ", mainAmount] })), subHeaderTitle && (_jsx(Typography, { className: "text-regal-grey mt-7 mb-3", variant: "h5", children: subHeaderTitle })), _jsx(KeyValueLabel, { className: "bg-transparent px-0 pb-0 text-regal-grey", dataArray: deductionDetails })] }));
};
export default DeductionCard;
