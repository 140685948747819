import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import { CloseIcon } from '@navi/web-ui/lib/icons';
import Button from '@navi/web-ui/lib/primitives/Button';
import ReactPortal from '@navi/web-ui/lib/primitives/ReactPortal';
import Typography from '@navi/web-ui/lib/primitives/Typography';
import styles from './Dialog.module.scss';
const Dialog = props => {
    const { open, onClose, header = '', showFooter, footerButtons, children, ...restProps } = props;
    const dialogContainerStyles = cx(styles['dialog-container'], {
        [styles['is-open']]: open
    });
    const handleClose = () => {
        document.body.style.overflow = 'unset';
        onClose();
    };
    const renderHeader = () => {
        const headerStyles = cx(styles.header, {
            [styles['header--without-text']]: header === ''
        });
        return (_jsxs("div", { className: headerStyles, children: [header !== '' && _jsx(Typography, { variant: "h3", children: header }), _jsx(CloseIcon, { size: "md", onClick: handleClose, className: styles['close-icon'] })] }));
    };
    const renderFooter = () => {
        return (showFooter && (_jsx("div", { className: styles.footer, children: footerButtons.map((cta, index) => {
                const { label, onClick } = cta;
                return (_jsx(Button, { variant: index % 2 === 0 ? 'secondary' : 'primary', onClick: onClick, children: label }, label));
            }) })));
    };
    if (open)
        document.body.style.overflow = 'hidden';
    return (_jsx(ReactPortal, { wrapperId: "dialogPortal", children: _jsx("main", { className: dialogContainerStyles, onClick: handleClose, children: _jsxs("section", { className: styles.dialog, onClick: e => e.stopPropagation(), ...restProps, children: [renderHeader(), children, renderFooter()] }) }) }));
};
export default Dialog;
