import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AgTable, Pagination } from '@navi/web-ui/lib/components';
import SearchBar from '@components/SearchBar';
import { getFilteredListAPI } from '@redux/globalFilter/globalFilter.actions';
import { getFilterDataFetchingStatus, getFilteredList, getFilteredListPageDetails } from '@redux/globalFilter/globalFilter.selectors';
import { useAppSelector } from '@redux/hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import { GridColumn, GridContainer } from '@navi/web-ui/lib/layouts/Grid';
import { useURLSearchParams } from '@utils/';
import { useNavigate } from 'react-router-dom';
import { Fallback } from '@components/';
import { columnDefinitions } from '@const/Claim';
const defaultColDef = {
    cellStyle: {
        lineHeight: 2,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    wrapText: true,
    autoHeight: true
};
const SearchPage = () => {
    const dispatch = useDispatch();
    const { searchBy = '', searchValue = '' } = useURLSearchParams();
    const filteredData = useAppSelector(getFilteredList);
    const pageDetails = useAppSelector(getFilteredListPageDetails);
    const isFilteredDataFetching = useAppSelector(getFilterDataFetchingStatus);
    const navigate = useNavigate();
    const { page = 0, totalElements = 0, size = 10 } = pageDetails || {};
    React.useEffect(() => {
        // This API call is for the users who directly hit the search url
        if (searchBy && searchValue)
            dispatch(getFilteredListAPI({ [searchBy]: searchValue, page: 0, size: 10 }));
    }, []);
    const handlePageChange = (changedPageNo) => {
        // Added -1 because the web ui pagination starts from 1
        dispatch(getFilteredListAPI({ [searchBy]: searchValue, page: changedPageNo - 1, size }));
    };
    const handlePageSizeChange = (pageSize) => {
        dispatch(getFilteredListAPI({
            [searchBy]: searchValue,
            page: 0,
            size: pageSize
        }));
    };
    const searchBar = React.useMemo(() => _jsx(SearchBar, { searchValue: searchValue, searchBy: searchBy, searchPage: true }), [searchValue]);
    return (_jsx(React.Suspense, { fallback: _jsx(Fallback, {}), children: _jsxs(GridContainer, { children: [searchBar, searchValue && !isFilteredDataFetching ? (_jsxs(_Fragment, { children: [_jsx(GridColumn, { className: `text-xl text-base-black pt-5 px-6 pb-6`, children: `Search Results for "${searchValue}"` }), _jsxs(GridColumn, { className: "box-border mb-14 px-6", children: [_jsx(AgTable, { columnDefs: [
                                        columnDefinitions.PATIENT_NAME,
                                        columnDefinitions.CLAIM_ID,
                                        columnDefinitions.IP_NUMBER,
                                        columnDefinitions.MEMBER_ID,
                                        columnDefinitions.DOA,
                                        columnDefinitions.DOD,
                                        columnDefinitions.STATUS
                                    ], rowData: filteredData, theme: "alpine", sizeColumnsToFit: true, defaultColDef: defaultColDef, domLayout: "autoHeight", onRowClicked: event => navigate(`/claim-journey/${event.data.claimId}`) }), _jsx(Pagination, { totalCount: totalElements, onPageChange: handlePageChange, onPageSizeChange: handlePageSizeChange, currentPage: page + 1, pageSize: size })] })] })) : (_jsx(Fallback, { customClassName: "w-full h-[calc(100vh_-_15rem)]" }))] }) }));
};
export default SearchPage;
