import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Card, KeyValueLabel, Typography } from '@navi/web-ui/lib/primitives';
import { RUPEE_SYMBOL } from '@const/Symbols';
const ApprovalDetails = (props) => {
    const { premiumRecovery, payableByInsured, payableByNavi, otherAdjustmentAmount, finalPayableAmount } = props;
    return (_jsxs(GridContainer, { children: [_jsx(Typography, { className: "text-primary-grey mb-4 p-0", variant: "h3", children: "Approval related Details" }), _jsxs(Card, { className: "base-white p-6 shadow-lg rounded-xl", children: [_jsxs(GridRow, { className: "m-0 mb-10 flex justify-between w-[35%]", children: [_jsxs(Typography, { className: "text-regal-grey", variant: "h2", children: ["Final Approved Amount (", RUPEE_SYMBOL, ")"] }), ' ', _jsxs(Typography, { className: "text-regal-grey", variant: "h2", children: [RUPEE_SYMBOL, " ", finalPayableAmount?.toFixed(2)] })] }), _jsxs(GridRow, { className: "flex m-0", children: [_jsx(GridColumn, { className: "p-0 w-[35%] mr-56", children: _jsx(KeyValueLabel, { className: "bg-transparent p-0 text-regal-grey", dataArray: [
                                        {
                                            key: `Premium recovery (${RUPEE_SYMBOL})`,
                                            value: `${RUPEE_SYMBOL} ${premiumRecovery ? premiumRecovery.toFixed(2) : 0}`
                                        },
                                        {
                                            key: `Other Adjustments (${RUPEE_SYMBOL})`,
                                            value: `${RUPEE_SYMBOL} ${otherAdjustmentAmount.toFixed(2)}`
                                        }
                                    ] }) }), _jsx(GridColumn, { className: "p-0 w-[35%]", children: _jsx(KeyValueLabel, { className: "bg-transparent p-0 text-regal-grey", dataArray: [
                                        {
                                            key: `Payable by Insured (${RUPEE_SYMBOL})`,
                                            value: `${RUPEE_SYMBOL} ${payableByInsured.toFixed(2)}`
                                        },
                                        {
                                            key: `Payable by NAVI (${RUPEE_SYMBOL})`,
                                            value: `${RUPEE_SYMBOL} ${payableByNavi.toFixed(2)}`
                                        }
                                    ] }) })] })] })] }));
};
export default ApprovalDetails;
