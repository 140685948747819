export var POLICY_RELATIONSHIP;
(function (POLICY_RELATIONSHIP) {
    POLICY_RELATIONSHIP["BROTHER"] = "BROTHER";
    POLICY_RELATIONSHIP["SON"] = "SON";
    POLICY_RELATIONSHIP["MOTHER"] = "MOTHER";
    POLICY_RELATIONSHIP["SISTER"] = "SISTER";
    POLICY_RELATIONSHIP["SPOUSE"] = "SPOUSE";
    POLICY_RELATIONSHIP["DAUGHTER"] = "DAUGHTER";
    POLICY_RELATIONSHIP["FATHER_IN_LAW"] = "FATHER_IN_LAW";
    POLICY_RELATIONSHIP["FATHER"] = "FATHER";
    POLICY_RELATIONSHIP["MOTHER_IN_LAW"] = "MOTHER_IN_LAW";
    POLICY_RELATIONSHIP["SELF"] = "SELF";
})(POLICY_RELATIONSHIP || (POLICY_RELATIONSHIP = {}));
export var PolicyStatus;
(function (PolicyStatus) {
    PolicyStatus["IN_PROGRESS"] = "IN_PROGRESS";
    PolicyStatus["ISSUED"] = "ISSUED";
    PolicyStatus["ENDORSED"] = "ENDORSED";
    PolicyStatus["CANCELLED"] = "CANCELLED";
    PolicyStatus["LAPSED"] = "LAPSED";
    PolicyStatus["EXPIRED"] = "EXPIRED";
    PolicyStatus["RENEWED"] = "RENEWED";
    PolicyStatus["PROVISIONAL"] = "PROVISIONAL";
    PolicyStatus["CANCELLATION_IN_PROGRESS"] = "CANCELLATION_IN_PROGRESS";
})(PolicyStatus || (PolicyStatus = {}));
