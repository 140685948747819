// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jWHkCER84rAdiMSqiTCR{width:100vw;height:100vh;position:fixed;left:0;display:flex;justify-content:center;align-items:center;z-index:1001;background-color:rgba(255,255,255,.7)}", "",{"version":3,"sources":["webpack://./src/common/components/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,cAAA,CACA,MAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,qCAAA","sourcesContent":[".loader-container {\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1001;\n  background-color: rgba(255, 255, 255, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-container": "jWHkCER84rAdiMSqiTCR"
};
export default ___CSS_LOADER_EXPORT___;
