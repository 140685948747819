import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { BorderedInput, Button, Tag, Typography } from '@navi/web-ui/lib/primitives';
import CustomRadioGroup from '@components/CustomRadioGroup';
import CustomSelectInput from '@components/CustomSelectInput';
import { PolicyValidationRegex } from '@const/Regex';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { NEW_PRE_AUTH_CLAIM_TYPE_OPTIONS } from '../constants';
import { ISODate } from '@utils/Date';
import { useDispatch } from 'react-redux';
import { getPolicyDetailsRequest, getPolicyStatusRequest, resetPolicyStatus, resetPolicyStatusError } from '@redux/claimJourney/claimJourney.actions';
import { useAppSelector } from '@redux/hooks';
import { selectPolicyMembersDetails, selectPolicyStatus, selectPolicyStatusError } from '@redux/claimJourney/claimJourney.selector';
import { ValidPolicyStatusList } from '@const/ValidPolicyStatusList';
import { convertToTitleCase, toSearchParams } from '@utils/';
import { useNavigate } from 'react-router-dom';
import { AlertIcon, LoadingIcon } from '@navi/web-ui/lib/icons';
import AlertOutlineIcon from '@src/assets/icons/AlertOutlineIcon';
const invalidPolicyErrorMsg = (policyStatus) => {
    return `This policy is ${convertToTitleCase(policyStatus)}. Hence, new claim
  cannot be created for this.`;
};
const NewClaimForm = () => {
    const [memberList, setMemberList] = React.useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const policyStatus = useAppSelector(selectPolicyStatus);
    const policyStatusErr = useAppSelector(selectPolicyStatusError);
    const policyMembers = useAppSelector(selectPolicyMembersDetails);
    const [isValidPolicyEntered, setValidPolicyEntered] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        dispatch(resetPolicyStatusError());
    }, []);
    React.useEffect(() => {
        if (ValidPolicyStatusList.includes(policyStatus) && values?.policyId) {
            dispatch(getPolicyDetailsRequest(values?.policyId, false));
        }
    }, [policyStatus]);
    React.useEffect(() => {
        if (policyMembers?.length) {
            setMemberList(policyMembers.map(member => ({
                label: `${member.name}, ${member.memberId}`,
                value: member.memberId
            })));
        }
    }, [policyMembers]);
    const { handleChange, errors, values, handleSubmit, handleBlur, dirty, setFieldValue } = useFormik({
        initialValues: {
            preAuthClaimType: undefined,
            policyId: '',
            memberId: ''
        },
        onSubmit: (values) => {
            return navigate(`/claim-journey?${toSearchParams(values)}`);
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            preAuthClaimType: Yup.string().trim().required('Required'),
            policyId: Yup.string().trim().matches(PolicyValidationRegex, 'Should be a valid PolicyId'),
            memberId: Yup.string().trim().required('Required')
        })
    });
    React.useEffect(() => {
        const isValidPolicy = !!values?.policyId?.match(PolicyValidationRegex);
        setValidPolicyEntered(isValidPolicy);
        if (isValidPolicy) {
            dispatch(resetPolicyStatus());
            const tempPolicyId = values?.policyId.trim();
            setFieldValue('memberId', '');
            if (tempPolicyId) {
                dispatch(getPolicyStatusRequest(tempPolicyId, ISODate(Date.now()), setIsLoading));
            }
        }
    }, [errors?.policyId, values?.policyId]);
    return (_jsxs(GridContainer, { className: "px-0 flex flex-col justify-between h-full", children: [_jsxs(GridRow, { className: "m-0", children: [_jsx(GridColumn, { className: "px-0", children: _jsx(CustomRadioGroup, { onChange: handleChange, value: values.preAuthClaimType, name: "preAuthClaimType", options: NEW_PRE_AUTH_CLAIM_TYPE_OPTIONS?.map(option => ({
                                ...option,
                                id: option.value
                            })), labelText: "Type of new claim", labelClassName: "text-regal-grey pb-4", required: true }) }), _jsxs(GridColumn, { className: "px-0 pt-4", children: [_jsx(BorderedInput, { placeholder: "Policy ID", type: "text", onChange: handleChange, onBlur: handleBlur, disabled: !values.preAuthClaimType, value: values.policyId, error: (dirty || policyStatusErr) && (errors?.policyId || policyStatusErr)
                                    ? errors.policyId || policyStatusErr
                                    : '', Icon: _jsx(AlertOutlineIcon, {}), name: "policyId", inputLabel: "Policy ID", RightInputAdornment: isLoading ? _jsx(LoadingIcon, {}) : null, required: true }), isValidPolicyEntered && policyStatus && (_jsxs(_Fragment, { children: [_jsx(Typography, { as: "span", className: "text-regal-grey", variant: "p4", children: "Status:\u00A0" }), _jsx(Typography, { as: "span", color: ValidPolicyStatusList.includes(policyStatus) ? 'green' : 'red', variant: "p4", children: convertToTitleCase(policyStatus) }), ValidPolicyStatusList.includes(policyStatus) && (_jsx(GridColumn, { className: "px-0 pt-6", children: _jsx(CustomSelectInput, { inputLabel: "Member Id", placeholder: "Select Member Id", options: memberList, value: values.memberId, name: "memberId", readOnly: true, setSelectedValue: (e) => setFieldValue('memberId', e), multiSelect: false, required: true }) }))] }))] })] }), _jsxs(GridRow, { className: "m-0", children: [isValidPolicyEntered && policyStatus && !ValidPolicyStatusList.includes(policyStatus) && (_jsx(GridColumn, { className: "px-0 py-4", children: _jsx(Tag, { color: "red", label: invalidPolicyErrorMsg(policyStatus), variant: "transparent", startAdornment: _jsx(AlertIcon, { color: "red", size: "md" }) }) })), _jsx(Button, { fullWidth: true, disabled: !dirty || !!Object.keys(errors)?.length, onClick: () => handleSubmit(), children: "Proceed" })] })] }));
};
export default NewClaimForm;
