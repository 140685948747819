import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Typography } from '@navi/web-ui/lib/primitives';
import { ClaimStatus } from '@enums/ClaimStatus';
import DocumentItem from '@components/DocumentItem';
import { findLetterDocument } from './constant';
import { AgTable } from '@navi/web-ui/lib/components';
import { useAppSelector } from '@redux/hooks';
import { selectClaimDetails } from '@redux/claimJourney/claimJourney.selector';
import { useParams } from 'react-router-dom';
import { ReferenceType } from '@pages/HospitalDetails/types';
const tableColumnDefinition = [
    {
        headerName: 'Policy Term Reference',
        field: 'policyTermReference'
    },
    {
        headerName: 'Rejection Clause Description',
        field: 'description'
    }
];
const RejectedStatusRemark = ({ latestPreAuthDetails, claimStatus, documentList }) => {
    const claimDetails = useAppSelector(selectClaimDetails);
    const defaultColDef = {
        cellStyle: {
            lineHeight: 2
        },
        wrapText: true,
        autoHeight: true
    };
    const { claimId = '', draftId = '' } = useParams();
    const document = findLetterDocument(documentList);
    return (_jsx(GridRow, { children: _jsx(GridColumn, { xs: 12, className: "p-0", children: _jsxs(GridRow, { className: "h-full", children: [_jsx(GridColumn, { xs: 12, children: _jsx(Typography, { variant: "h3", className: "py-0 text-regal-grey", children: "Rejection Type" }) }), _jsx(GridColumn, { className: "pt-0 text-regal-grey", xs: 12, children: _jsx(Typography, { variant: "p2", children: claimStatus === ClaimStatus.CLAIM_REJECTED
                                ? 'Network Reimbursement'
                                : 'Pre-Authorization' }) }), _jsx(GridColumn, { xs: 12, children: _jsx(AgTable, { columnDefs: tableColumnDefinition, rowData: claimDetails?.rejectionReasons, theme: "alpine", sizeColumnsToFit: true, defaultColDef: defaultColDef, domLayout: "autoHeight" }) }), _jsx(GridColumn, { className: "text-regal-grey", xs: 12, children: _jsx(Typography, { variant: "p2", children: "Rejection Remarks" }) }), _jsx(GridColumn, { className: "text-regal-grey pt-0", xs: 12, children: _jsx(Typography, { variant: "p2", children: latestPreAuthDetails.description }) }), _jsxs(GridColumn, { xs: 9, children: [_jsx(Typography, { variant: "p2", className: "text-regal-grey", children: "Rejection Letter" }), _jsx(Typography, { variant: "p2", className: "text-regal-grey", children: document ? (_jsx(DocumentItem, { documentItem: document, referenceType: claimId ? ReferenceType.CLAIM : ReferenceType.DRAFT, referenceId: claimId ? claimId : draftId })) : ('Letter is not generated yet.') })] })] }) }) }));
};
export default RejectedStatusRemark;
