import { DocumentLetterMapping } from '@enums/ClaimEnums';
export var StatusLabelEnum;
(function (StatusLabelEnum) {
    StatusLabelEnum["MISSING_INFO"] = "Missing Info";
    StatusLabelEnum["APPROVED"] = "Approved";
    StatusLabelEnum["TR_REJECTED"] = "Transaction Rejected";
    StatusLabelEnum["REJECTED"] = "Claim Rejected";
    StatusLabelEnum["CONDITIONAL_APPROVED"] = "Conditionally approved";
})(StatusLabelEnum || (StatusLabelEnum = {}));
export var StatusColorEnum;
(function (StatusColorEnum) {
    StatusColorEnum["ORANGE"] = "orange";
    StatusColorEnum["GREEN"] = "green";
    StatusColorEnum["RED"] = "red";
    StatusColorEnum["YELLOW"] = "yellow";
    StatusColorEnum["VOILET"] = "violet";
    StatusColorEnum["PRIMARY"] = "primary";
})(StatusColorEnum || (StatusColorEnum = {}));
export const findLetterDocument = (documentList, findLatest = true) => {
    if (!documentList?.length)
        return [];
    const letterTag = Object.values(DocumentLetterMapping);
    const returnedDocumentList = documentList
        .filter(docItem => letterTag.find(letter => docItem.documentTag?.includes(letter)))
        .sort((a, b) => a.uploadDate < b.uploadDate ? 1 : -1);
    return findLatest ? returnedDocumentList?.[0] || [] : returnedDocumentList;
};
