import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SelectPicker } from '@navi/web-ui/lib/components';
import { useOutsideClick } from '@navi/web-ui/lib/hooks';
import { ArrowDownSolidIcon } from '@navi/web-ui/lib/icons';
import { BorderedInput } from '@navi/web-ui/lib/primitives';
import AlertOutlineIcon from '@icons/AlertOutlineIcon';
import React, { useRef, useState } from 'react';
import styles from './CustomSelectInput.module.scss';
export const CustomSelectInput = props => {
    const { onSearchChange, showOptions = true, value, name, inputLabel, error, inputSize, options, placeholder, required, setSelectedValue, readOnly = false, helperTextClass, inputClassName, selectPickerClassName, disabled = false, showRightInputAdornment = true, containerClassName, ...restProps } = props;
    const [showPicker, setShowPicker] = useState(false);
    const [inputValue, setInputValue] = useState(options?.find(option => option.value === value)?.label || '');
    React.useEffect(() => {
        setInputValue(getLabelFromValue(value));
    }, [value, options]);
    const inputRef = useRef(null);
    useOutsideClick(inputRef, () => {
        setShowPicker(false);
    });
    const getLabelFromValue = (value) => {
        if (!value)
            return value;
        return options?.find(option => option.value === value)?.label;
    };
    const handleSelectionChange = (option) => {
        if (!Array.isArray(option)) {
            const { value } = option;
            const newLabel = getLabelFromValue(value);
            setInputValue(newLabel);
            setSelectedValue(value);
            setShowPicker(false);
        }
    };
    const handleChange = (e) => {
        const newInputVal = e.target.value;
        setInputValue(newInputVal);
        onSearchChange?.(newInputVal);
    };
    return (_jsxs("div", { className: `${styles['select-wrapper']} ${containerClassName}`, ref: inputRef, children: [_jsx("div", { onClick: () => {
                    if (!disabled)
                        setShowPicker(true);
                }, children: _jsx(BorderedInput, { name: name, value: inputValue, inputLabel: inputLabel, inputSize: inputSize, onChange: handleChange, error: error, placeholder: placeholder, containerClassName: inputClassName, RightInputAdornment: showRightInputAdornment && _jsx(ArrowDownSolidIcon, { className: "w-3" }), readOnly: readOnly, helperTextClasses: helperTextClass, required: required, disabled: disabled, Icon: _jsx(AlertOutlineIcon, {}) }) }), showOptions && showPicker && (_jsx(SelectPicker, { onSelectionChange: handleSelectionChange, ...restProps, options: options, wrapperClasses: `${styles.picker} ${selectPickerClassName}` }))] }));
};
