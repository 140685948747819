import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import { RootReducer } from './rootReducer';
import { initialState as authDetailsInitState } from './auth/constants';
import { initialState as userDetailsInitState } from './userDetails/constants';
import { initialState as claimJourneyDetailsInitState } from './claimJourney/constants';
import { initialState as globalFiltersInitState } from './globalFilter/constants';
import { ENV } from '@enums/Env';
/* Store */
export const store = configureStore({
    reducer: RootReducer,
    preloadedState: {
        authDetails: authDetailsInitState,
        userDetails: userDetailsInitState,
        claimJourneyDetails: claimJourneyDetailsInitState,
        filteredListDetails: globalFiltersInitState
    },
    devTools: false,
    middleware: getDefaultMiddleware => {
        if (window.config.ENV !== ENV.PRODUCTION) {
            return getDefaultMiddleware({ serializableCheck: false }).concat(logger);
        }
        return getDefaultMiddleware({ serializableCheck: false });
    }
});
