import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Card, Typography } from '@navi/web-ui/lib/primitives';
import CustomSelectInput from '@components/CustomSelectInput';
import { DEFAULT_TEXT } from '@const/Symbols';
import { hideLoader, showLoader } from '@redux/common/common.actions';
import { getHomePageFinanceDetails } from '@services/ClaimApi';
import React from 'react';
import { useDispatch } from 'react-redux';
import ClaimDetailsCard from './ClaimDetailsCard';
import { STATUS_CODE } from '@const/ApiConstants';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { generateArrayOfYears, getErrorMessage } from '@utils/';
const defaultMonthOptions = [
    {
        label: 'Jan - Mar',
        value: '1-3'
    },
    {
        label: 'Apr - Jun',
        value: '4-6'
    },
    {
        label: 'Jul - Sep',
        value: '7-9'
    },
    {
        label: 'Oct - Dec',
        value: '10-12'
    },
    {
        label: 'Apr - Mar',
        value: '4-3'
    }
];
const ClaimDetails = (props) => {
    const { hospitalId } = props;
    const defaultYearOptions = generateArrayOfYears();
    const [searchByYear, setSearchByYear] = React.useState(defaultYearOptions[0]?.value);
    const [searchByMonth, setSearchByMonth] = React.useState(defaultMonthOptions[0].value);
    const [financeDetails, setFinanceDetails] = React.useState({
        claimCount: 0,
        approvedClaimCount: 0,
        totalClaimedAmount: 0,
        totalSettledAmount: 0,
        totalApprovedAmount: 0,
        totalTDS: 0
    });
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (hospitalId) {
            dispatch(showLoader());
            const [startMonth, endMonth] = searchByMonth.split('-');
            const [startYear, endYear] = searchByYear.split('-');
            const query = startMonth > endMonth
                ? { financialYear: `${startYear}-${endYear}` }
                : {
                    year: Number(startMonth) > 3 ? startYear : endYear,
                    startMonth,
                    endMonth
                };
            getHomePageFinanceDetails(hospitalId, query)
                .then((response) => {
                if (response.data?.statusCode === STATUS_CODE.OK) {
                    setFinanceDetails(response.data?.data);
                    return;
                }
                throw response;
            })
                .catch((error) => {
                toast.error(getErrorMessage(error.message));
            })
                .finally(() => {
                dispatch(hideLoader());
            });
        }
    }, [hospitalId, searchByMonth, searchByYear]);
    return (_jsx(GridContainer, { className: "m-2", children: _jsxs(Card, { className: `w pb-6 pt-4 px-6 rounded-xl homepage-custom-card`, children: [_jsxs(GridRow, { className: "items-center", children: [_jsx(Typography, { variant: "p2", className: "font-semibold mr-5", color: "#1C1C1C", children: "Claim Details" }), _jsx(CustomSelectInput, { inputClassName: "w-44 rounded-md mr-4", selectPickerClassName: "min-w-fit", value: searchByYear, setSelectedValue: (value) => setSearchByYear(value), inputSize: "medium", options: defaultYearOptions, multiSelect: false, readOnly: true, helperTextClass: "absolute" }), _jsx(CustomSelectInput, { inputClassName: "w-36 rounded-md", selectPickerClassName: "min-w-fit", value: searchByMonth, setSelectedValue: (value) => setSearchByMonth(value), inputSize: "medium", options: defaultMonthOptions, multiSelect: false, readOnly: true, helperTextClass: "absolute" })] }), _jsx(GridRow, { className: "justify-between m-0 mt-6", children: [
                        {
                            title: 'Total Claims (Nos.)',
                            value: financeDetails.claimCount?.toLocaleString() ?? DEFAULT_TEXT
                        },
                        {
                            title: 'Total Approved Claims (Nos.)',
                            value: financeDetails.approvedClaimCount?.toLocaleString() ?? DEFAULT_TEXT
                        },
                        {
                            title: 'Total Claimed Amount (₹)',
                            value: `₹ ${financeDetails.totalClaimedAmount?.toLocaleString()}`
                        },
                        {
                            title: 'Total Approved Amount (₹)',
                            value: `₹ ${financeDetails.totalApprovedAmount?.toLocaleString()}`
                        },
                        {
                            title: 'Total Settled Amount (₹)',
                            value: `₹ ${financeDetails.totalSettledAmount?.toLocaleString()}`
                        },
                        {
                            title: 'TDS (₹)',
                            value: `₹ ${financeDetails.totalTDS?.toLocaleString()}`
                        }
                    ].map((item, index) => (_jsx(ClaimDetailsCard, { title: item.title, value: item.value }, index))) })] }) }));
};
export default ClaimDetails;
