import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@navi/web-ui/lib/primitives';
import { isEmptyObject } from '@utils/';
import DocumentItem from './DocumentItem';
import { DocumentItemModes } from './types';
const DocumentsTable = props => {
    const { documents, mode, tagOptions, onRemoveFile, onRemoveTag, onTagsChange, isMissingInfo = false, documentsError = false } = props;
    const renderHeader = () => (_jsxs(_Fragment, { children: [_jsx(Typography, { className: "w-64 text-primary-grey mr-2", variant: "p3", children: "Document Name" }), _jsx(Typography, { className: "w-36 text-primary-grey", variant: "p3", children: "Upload Date" }), mode === DocumentItemModes.UPLOAD && (_jsx(Typography, { className: "w-36 text-primary-grey", variant: "p3", children: isMissingInfo ? 'Select Queries' : 'Select Tags' })), _jsx(Typography, { className: "flex-1 text-primary-grey mr-2", variant: "p3", children: isMissingInfo ? 'Queries' : 'Tags' }), _jsx(Typography, { className: "w-36 text-primary-grey", variant: "p3", children: "Actions" })] }));
    const renderLetterModeHeader = () => (_jsxs(_Fragment, { children: [_jsx(Typography, { className: "w-64 text-primary-grey", variant: "p3", children: "Letter Name" }), _jsx(Typography, { className: "flex-1 text-primary-grey", variant: "p3", children: "Delivered Date and Time" }), _jsx(Typography, { className: "w-36 text-primary-grey", variant: "p3", children: "Actions" })] }));
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "flex justify-between py-4 px-6 bg-white", children: mode === DocumentItemModes.VIEW_LETTER ? renderLetterModeHeader() : renderHeader() }), isEmptyObject(documents) ? (_jsx(Typography, { variant: "p3", className: "text-center py-[34px] bg-regal-blue-40 text-primary-grey", children: "No documents to show in the current status" })) : (Object.keys(documents).map((item) => (_jsx(DocumentItem, { tagOptions: tagOptions, mode: mode, document: documents[item], onRemoveFile: onRemoveFile, onTagsChange: onTagsChange, onRemoveTag: onRemoveTag, isMissingInfo: isMissingInfo, documentsError: documentsError }, documents[item].documentReferenceId))))] }));
};
export default DocumentsTable;
