import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { BottomSheet, Typography } from '@navi/web-ui/lib/primitives';
import { NON_PAYABLE_REASON_BILL } from '@common/types/Claim';
import { selectClaimDetails } from '@redux/claimJourney/claimJourney.selector';
import { useAppSelector } from '@redux/hooks';
import React from 'react';
import ApprovalDetails from './ApprovalDetails';
import BillLevelDeduction from './BillLevelDeduction';
import ClaimLevelDeduction from './ClaimLevelDeduction';
import ArrowLeftIcon from '@assets/icons/ArrowLeftIcon';
const DeductionDetailsInitialValues = {
    hospitalDiscountAmount: 0,
    hospitalOtherDiscount: 0,
    copayPercent: 0,
    copayAmount: 0,
    hospitalDiscountPercentage: 0,
    deductible: 0,
    excesDefinedAilment: 0,
    policyExcessSI: 0,
    otherAdjustmentAmount: 0,
    roomRentDeduction: 0,
    nonMedicalDeduction: 0,
    proportionateDeduction: 0,
    ucrDeduction: 0,
    noDocuments: 0
};
const DeductionDetails = (props) => {
    const { showDeductions, setShowDeductions, premiumRecovery = 0 } = props;
    const [deductionDetails, setDeductionDetails] = React.useState(DeductionDetailsInitialValues);
    const [claimedAmount, setClaimedAmount] = React.useState(0);
    const [admissibleAmount, setAdmissibleAmount] = React.useState(0);
    const [finalPayableAmount, setFinalPayableAmount] = React.useState(0);
    const claimDetails = useAppSelector(selectClaimDetails);
    const { claimBills, claimLevelDeduction } = claimDetails || {};
    React.useEffect(() => {
        setClaimedAmount(Number(claimBills?.reduce((a, c) => a + c.amount, 0).toFixed(2)));
        const chargeTypeDeductionMap = new Map();
        claimBills?.forEach((claimBillItem) => {
            if (!chargeTypeDeductionMap.has(claimBillItem.reason)) {
                chargeTypeDeductionMap.set(claimBillItem.reason, {
                    nonPayableAmount: claimBillItem.nonPayableAmount
                });
            }
            else {
                const deductionData = chargeTypeDeductionMap.get(claimBillItem.reason) || {
                    amount: 0,
                    nonPayableAmount: 0
                };
                chargeTypeDeductionMap.set(claimBillItem.reason, {
                    nonPayableAmount: deductionData.nonPayableAmount + claimBillItem.nonPayableAmount
                });
            }
        });
        setAdmissibleAmount(Number(claimBills
            ?.reduce((a, c) => a + c.amount - c.nonPayableAmount, 0)
            .toFixed(2)));
        const roomRentDeduction = chargeTypeDeductionMap.get(NON_PAYABLE_REASON_BILL.ROOM_RENT)?.nonPayableAmount || 0;
        const nonMedicalDeduction = chargeTypeDeductionMap.get(NON_PAYABLE_REASON_BILL.NME)?.nonPayableAmount || 0;
        const proportionateDeduction = chargeTypeDeductionMap.get(NON_PAYABLE_REASON_BILL.PROPORTIONATE_DEDUCTION)
            ?.nonPayableAmount || 0;
        const ucrDeduction = chargeTypeDeductionMap.get(NON_PAYABLE_REASON_BILL.UCR)?.nonPayableAmount || 0;
        const noDocuments = chargeTypeDeductionMap.get(NON_PAYABLE_REASON_BILL.NO_DOCUMENTS)?.nonPayableAmount || 0;
        setDeductionDetails(deductionDetails => ({
            ...deductionDetails,
            roomRentDeduction,
            nonMedicalDeduction,
            proportionateDeduction,
            ucrDeduction,
            noDocuments
        }));
    }, [claimBills]);
    React.useEffect(() => {
        let hospitalOtherDiscount = 0, hospitalDiscountAmount = 0, copayAmount = 0, otherAdjustmentAmount = 0, deductible = 0, policyExcessSI = 0, excesDefinedAilment = 0;
        claimLevelDeduction?.forEach(deductionItem => {
            switch (deductionItem.deductionType) {
                case 'Hospital Discount (Others)':
                    hospitalOtherDiscount = deductionItem.deductionAmount ?? 0;
                    break;
                case 'Hospital Discount':
                    hospitalDiscountAmount = deductionItem.deductionAmount ?? 0;
                    break;
                case 'Copay':
                    copayAmount = deductionItem.deductionAmount ?? 0;
                    break;
                case 'Any Other Adjustment':
                    otherAdjustmentAmount = deductionItem.deductionAmount ?? 0;
                    break;
                case 'Deductible':
                    deductible = deductionItem.deductionAmount ?? 0;
                    break;
                case 'Policy Excess of SI':
                    policyExcessSI = deductionItem.deductionAmount ?? 0;
                    break;
                case 'Excess of defined ailment limit':
                    excesDefinedAilment = deductionItem.deductionAmount ?? 0;
                    break;
                default:
                    break;
            }
        });
        setDeductionDetails(deductionDetails => ({
            ...deductionDetails,
            hospitalDiscountAmount,
            hospitalOtherDiscount,
            copayAmount,
            otherAdjustmentAmount,
            deductible,
            policyExcessSI,
            excesDefinedAilment
        }));
    }, [claimLevelDeduction]);
    React.useEffect(() => {
        const copayPercent = Number(deductionDetails.copayAmount * 100) /
            Number((admissibleAmount -
                deductionDetails.hospitalOtherDiscount -
                deductionDetails.hospitalDiscountAmount).toFixed(2));
        const hospitalDiscountPercentage = Number(((deductionDetails.hospitalDiscountAmount * 100) / claimedAmount).toFixed(2));
        setDeductionDetails(deductionDetails => ({
            ...deductionDetails,
            copayPercent,
            hospitalDiscountPercentage
        }));
    }, [
        admissibleAmount,
        deductionDetails.hospitalOtherDiscount,
        deductionDetails.hospitalDiscountAmount,
        claimedAmount
    ]);
    React.useEffect(() => {
        setFinalPayableAmount(admissibleAmount -
            deductionDetails.hospitalOtherDiscount -
            deductionDetails.hospitalDiscountAmount -
            (deductionDetails.copayAmount +
                deductionDetails.deductible +
                deductionDetails.excesDefinedAilment +
                deductionDetails.policyExcessSI +
                deductionDetails.otherAdjustmentAmount));
    }, [admissibleAmount, deductionDetails]);
    const payableByInsured = claimedAmount -
        (finalPayableAmount +
            deductionDetails.hospitalDiscountAmount +
            deductionDetails.hospitalOtherDiscount) +
        premiumRecovery;
    const payableByNavi = Math.floor(finalPayableAmount - premiumRecovery);
    return (_jsx(BottomSheet, { fullScreen: true, open: showDeductions, onClose: () => setShowDeductions(false), children: _jsxs(GridRow, { className: "p-6", children: [_jsxs(GridRow, { className: "m-0 mb-8 flex items-center", children: [_jsx(ArrowLeftIcon, { color: "#585757", width: 32, height: 32, onClick: () => setShowDeductions(false), className: "cursor-pointer" }), _jsx(Typography, { className: "text-regal-grey ml-2", variant: "h1", children: "Deductions Details" })] }), _jsx(BillLevelDeduction, {}), _jsx(ClaimLevelDeduction, { deductionDetails: deductionDetails, claimedAmount: claimedAmount, admissibleAmount: admissibleAmount }), _jsx(ApprovalDetails, { finalPayableAmount: finalPayableAmount, otherAdjustmentAmount: deductionDetails.otherAdjustmentAmount, premiumRecovery: premiumRecovery, payableByInsured: payableByInsured, payableByNavi: payableByNavi })] }) }));
};
export default DeductionDetails;
