import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AgTable } from '@navi/web-ui/lib/components';
import { GridColumn, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Typography } from '@navi/web-ui/lib/primitives';
import DocumentItem from '@components/DocumentItem';
import { DEFAULT_TEXT } from '@const/Symbols';
import { MissingInfoStatusEnum } from '@common/types/Claim';
import { selectClaimDetails, selectDocuments } from '@redux/claimJourney/claimJourney.selector';
import { useAppSelector } from '@redux/hooks';
import React from 'react';
import { findLetterDocument } from './constant';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setSubmitMissingInfoPayload } from '@redux/claimJourney/claimJourney.actions';
import { DocumentCategory } from '@common/enums/ClaimEnums';
import { ActionOptionEnum, missingInfoColumnDefinitions } from '../../constants';
import UploadDocuments from '../UploadDocuments';
import { convertStringToEllipsis } from '@utils/';
import { useParams } from 'react-router-dom';
import { ReferenceType } from '@pages/HospitalDetails/types';
const tableColumnDefinition = [...missingInfoColumnDefinitions];
const MissingInfoStatusRemark = ({ latestPreAuthDetails, claimStatus, documentList, selectedAction, documentsError }) => {
    const dispatch = useDispatch();
    const { claimId = '', draftId = '' } = useParams();
    const claimDetails = useAppSelector(selectClaimDetails);
    const missingInfoDetails = useAppSelector(selectDocuments);
    const [missingInfoList, setMissingInfoList] = React.useState(claimDetails?.missingInfoList);
    const [documentLetters] = React.useState(findLetterDocument(documentList));
    const [queryTagOptions] = React.useState(missingInfoList
        ?.filter((item) => !item.documentName)
        ?.map(item => ({
        label: item.remark?.length > 39 ? convertStringToEllipsis(item.remark, 39) : item.remark,
        value: item.remark
    })) || []);
    React.useEffect(() => {
        const allUploadedQueryMap = Object.values(missingInfoDetails)?.reduce((acc, curr) => {
            if (curr?.tags?.length) {
                curr.tags.forEach((item) => {
                    acc[item.value] = curr;
                });
            }
            return acc;
        }, {}) || {};
        const tempMissingInfoList = claimDetails?.missingInfoList?.map((item) => {
            if (item.remark in allUploadedQueryMap) {
                const documentName = allUploadedQueryMap?.[item.remark]?.documentName || DEFAULT_TEXT;
                const documentReferenceId = allUploadedQueryMap?.[item.remark]?.referenceId;
                return {
                    ...item,
                    status: MissingInfoStatusEnum.UPLOADED,
                    documentName,
                    documentReferenceId
                };
            }
            return item;
        });
        setMissingInfoList(tempMissingInfoList);
    }, [missingInfoDetails]);
    React.useEffect(() => {
        const missingInfoFilteredList = missingInfoList?.filter(data => data.status !== MissingInfoStatusEnum.CLOSED);
        const submitMissingInfoList = missingInfoFilteredList?.map(item => ({
            missingInfoId: item.missingInfoId,
            document: {
                documentTag: DocumentCategory.MISSING_INFO,
                documentName: item.documentName,
                documentReferenceId: item.documentReferenceId
            }
        }));
        dispatch(setSubmitMissingInfoPayload(submitMissingInfoList));
    }, [missingInfoList]);
    const defaultColDef = {
        cellStyle: {
            lineHeight: 2
        },
        wrapText: true,
        autoHeight: true
    };
    return (_jsx(GridRow, { children: _jsx(GridColumn, { xs: 12, className: "p-0", children: _jsxs(GridRow, { className: "h-full", children: [_jsx(GridColumn, { xs: 12, children: _jsx(Typography, { variant: "h3", className: "py-0 text-regal-grey", children: "Query Details" }) }), _jsx(GridColumn, { xs: 12, children: _jsx(AgTable, { columnDefs: tableColumnDefinition, rowData: missingInfoList, theme: "alpine", sizeColumnsToFit: true, defaultColDef: defaultColDef, domLayout: "autoHeight" }) }), _jsx(GridColumn, { className: "text-regal-grey", xs: 12, children: _jsx(Typography, { variant: "p2", children: "Query Letters" }) }), _jsx(GridColumn, { xs: 9, children: _jsx(Typography, { variant: "p2", className: "text-regal-grey", children: documentLetters ? (_jsx(DocumentItem, { documentItem: documentLetters, referenceType: claimId ? ReferenceType.CLAIM : ReferenceType.DRAFT, referenceId: claimId ? claimId : draftId }, _.uniqueId())) : ('Letter is not generated yet.') }) }), _jsx(GridColumn, { className: "text-regal-grey", xs: 12, children: _jsx(UploadDocuments, { title: "Attach Documents", isMissingInfo: true, documentTagOptions: queryTagOptions, uploadBtnDisabled: ![
                                ActionOptionEnum.MISSING_INFO_SUBMISSION,
                                ActionOptionEnum.MISSING_INFO_SUBMISSION_NR
                            ].includes(selectedAction), documentsError: documentsError }) })] }) }) }));
};
export default MissingInfoStatusRemark;
