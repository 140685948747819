import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography } from '@navi/web-ui/lib/primitives';
import React from 'react';
import dialpadIcon from '@assets/icons/dialpad-icon.svg';
import locationIcon from '@assets/icons/location-icon.svg';
import bankIcon from '@assets/icons/bank-icon.svg';
import DocumentItem from '@common/components/DocumentItem';
import { AlertIcon, EditIcon } from '@navi/web-ui/lib/icons';
import { DEFAULT_TEXT } from '@const/Symbols';
import { ReferenceType } from '../types';
import EditAccountDetails from './EditAccountDetails';
import { CANCELLED_CHEQUE } from '../constants';
import { GridRow } from '@navi/web-ui/lib/layouts/Grid';
const BankDetailsItem = (props) => {
    const { hospitalDetails } = props;
    const { bankName, ifsCode, bankAddress } = hospitalDetails || {};
    return (_jsxs("div", { className: "custom-card py-6 px-8 w-2/3 mb-4", children: [_jsx(Typography, { variant: "h3", className: "mb-6", children: "Bank Details" }), _jsxs("div", { className: "flex items-center mb-6", children: [_jsx("img", { src: bankIcon, alt: "bank", className: "mr-[18px]" }), _jsx(Typography, { variant: "p2", className: "w-32 text-regal-grey", children: "Name" }), _jsx(Typography, { variant: "h3", className: "text-regal-grey font-medium", children: bankName ?? DEFAULT_TEXT })] }), _jsxs("div", { className: "flex items-center mb-6", children: [_jsx("img", { src: locationIcon, alt: "bank", className: "mr-[21px]" }), _jsx(Typography, { variant: "p2", className: "w-32 text-regal-grey", children: "Address" }), _jsx(Typography, { variant: "h3", className: "text-regal-grey font-medium", children: bankAddress ?? DEFAULT_TEXT })] }), _jsxs("div", { className: "flex items-center", children: [_jsx("img", { src: dialpadIcon, alt: "bank", className: "mr-[22px]" }), _jsx(Typography, { variant: "p2", className: "w-32 text-regal-grey", children: "IFSC" }), _jsx(Typography, { variant: "h3", className: "text-regal-grey font-medium", children: ifsCode ?? DEFAULT_TEXT })] })] }));
};
const AccountDetailsItem = (props) => {
    const { hospitalDetails } = props;
    const { bankAccountNumber, accountType, chequePayableName, panNumber, cancelledChequeReferenceId, hospitalId } = hospitalDetails || {};
    return (_jsxs("div", { className: "custom-card py-6 px-8 w-2/3", children: [_jsx(Typography, { variant: "h3", className: "mb-6", children: "Bank Account Details" }), _jsxs("div", { className: "flex items-start mb-6", children: [_jsx(Typography, { variant: "p2", className: "w-56 text-regal-grey", children: "Bank Account Number" }), _jsx(Typography, { variant: "h3", className: "text-regal-grey font-medium", children: bankAccountNumber ?? DEFAULT_TEXT })] }), _jsxs("div", { className: "flex items-start mb-6", children: [_jsx(Typography, { variant: "p2", className: "w-56 text-regal-grey", children: "Bank Account Type" }), _jsx(Typography, { variant: "h3", className: "text-regal-grey font-medium", children: accountType ?? DEFAULT_TEXT })] }), _jsxs("div", { className: "flex items-start mb-6", children: [_jsx(Typography, { variant: "p2", className: "w-56 text-regal-grey", children: "Cheque Payable Name" }), _jsx(Typography, { variant: "h3", className: "text-regal-grey font-medium", children: chequePayableName ?? DEFAULT_TEXT })] }), _jsxs("div", { className: "flex items-start", children: [_jsx(Typography, { variant: "p2", className: "w-56 text-regal-grey", children: "PAN" }), _jsx(Typography, { variant: "h3", className: "text-regal-grey font-medium", children: panNumber ?? DEFAULT_TEXT })] }), _jsx(Typography, { variant: "h3", className: "mb-4 mt-10", children: "Bank Document" }), cancelledChequeReferenceId ? (_jsx(DocumentItem, { documentItem: {
                    documentName: CANCELLED_CHEQUE,
                    documentReferenceId: cancelledChequeReferenceId
                }, referenceType: ReferenceType.HOSPITAL, referenceId: hospitalId })) : (_jsxs(GridRow, { className: "m-0 items-center px-4 py-3 bg-orange-40 rounded-lg w-max", children: [_jsx(AlertIcon, { color: "#F98600" }), _jsxs(Typography, { variant: "p3", className: "text-orange pl-2", children: ["To get approval, document upload is necessary. Kindly upload document.", ' '] })] }))] }));
};
const AccountDetails = props => {
    const { hospitalDetails, setHospitalDetails } = props;
    const [isEditAccountDetailsOpen, setIsEditAccountDetailsOpen] = React.useState(false);
    return (_jsxs("div", { className: "mt-14", children: [_jsxs(Typography, { variant: "h2", className: "mb-6 text-regal-grey font-semibold", children: ["Account Details", _jsx(Button, { startAdornment: _jsx(EditIcon, { color: "#0276FE" }), variant: "secondary", onClick: () => setIsEditAccountDetailsOpen(true), className: "ml-6 text-primary-blue border-primary-blue", 
                        // Remove disabled once Ops Portal flow is completed
                        disabled: true, children: "Edit" })] }), _jsx(EditAccountDetails, { open: isEditAccountDetailsOpen, onClose: () => setIsEditAccountDetailsOpen(false), hospitalDetails: hospitalDetails, setHospitalDetails: setHospitalDetails }), _jsx(BankDetailsItem, { hospitalDetails: hospitalDetails }), _jsx(AccountDetailsItem, { hospitalDetails: hospitalDetails })] }));
};
export default AccountDetails;
