export const initialState = {
    claimId: '',
    policyId: '',
    isPolicyDetailsFetching: false,
    isClaimDetailsFetching: false,
    claimMasterData: {
        hospitalizationType: [],
        admissionType: [],
        caseType: [],
        roomType: []
    },
    documents: {},
    submitMissingInfoPayload: [],
    draftSelectedAction: ''
};
