import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { BorderedInput, Button, Card, Typography } from '@navi/web-ui/lib/primitives';
import React from 'react';
import { getICDListDetails } from '@services/ClaimApi';
import useDebounce from '@utils/Debounce';
import { AddIcon, DeleteIconOutlined } from '@navi/web-ui/lib/icons';
import { DiseaseDetailsConfig, FormItemVisibilityEnum } from '../ClaimJourneyConfig';
import AlertOutlineIcon from '@src/assets/icons/AlertOutlineIcon';
import { useDispatch } from 'react-redux';
import { setFetchingClaimDetails } from '@src/redux/claimJourney/claimJourney.actions';
import { Autocomplete, Paper } from '@mui/material';
const DiseaseDetails = ({ handleChange, errors, values, touched, setFieldValue, selectedAction }) => {
    const [primaryIcdList, setPrimaryIcdList] = React.useState([]);
    const [secondaryIcdList, setSecondaryIcdList] = React.useState([]);
    const [showSecondaryIcd, setShowSecondaryIcd] = React.useState(false);
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (!primaryIcdList?.length && values?.ailmentDetails?.ailmentDescription) {
            getICDList(values.ailmentDetails.ailmentDescription);
        }
        if (!secondaryIcdList?.length && values?.ailmentDetails?.secondaryAilmentDescription) {
            getICDList(values.ailmentDetails.secondaryAilmentDescription, false);
        }
    }, []);
    React.useEffect(() => {
        if (!showSecondaryIcd && values?.ailmentDetails?.secondaryAilmentDescription) {
            setShowSecondaryIcd(true);
        }
    }, [values?.ailmentDetails?.secondaryAilmentDescription]);
    React.useEffect(() => {
        if (values?.ailmentDetails?.ailmentDescription && primaryIcdList.length) {
            handleSelectIcdCodeEvent(values.ailmentDetails.ailmentDescription);
        }
        if (values?.ailmentDetails?.secondaryAilmentDescription && secondaryIcdList.length) {
            handleSelectIcdCodeEvent(values.ailmentDetails.secondaryAilmentDescription, false);
        }
    }, [primaryIcdList, secondaryIcdList]);
    const getICDList = React.useCallback((query, isPrimary = true) => {
        dispatch(setFetchingClaimDetails(true));
        getICDListDetails(query)
            .then((resp) => {
            if (resp?.data?.matchedIcdCodes?.length) {
                const icdDataList = resp?.data?.matchedIcdCodes.map((optionItem) => ({
                    label: optionItem.whoDescription,
                    value: optionItem.whoDescription,
                    ...optionItem
                }));
                isPrimary ? setPrimaryIcdList(icdDataList) : setSecondaryIcdList(icdDataList);
            }
            else {
                isPrimary ? setPrimaryIcdList([]) : setSecondaryIcdList([]);
            }
        })
            .finally(() => {
            dispatch(setFetchingClaimDetails(false));
        });
    }, []);
    const filterICDList = useDebounce(getICDList, 300);
    const handleSelectIcdCodeEvent = (value, isPrimary = true) => {
        const targetIcdList = isPrimary ? primaryIcdList : secondaryIcdList;
        const icdItem = targetIcdList.find((item) => item.value === value);
        if (icdItem) {
            if (isPrimary) {
                setFieldValue('ailmentDetails.ailmentDescription', icdItem?.whoDescription);
                setFieldValue('ailmentDetails.ailmentGroup', icdItem?.groupDescription);
                setFieldValue('ailmentDetails.icdCode', icdItem?.icd10Code);
                setFieldValue('ailmentDetails.icdGroup', icdItem?.groupCode);
            }
            else {
                setFieldValue('ailmentDetails.secondaryAilmentDescription', icdItem?.whoDescription);
                setFieldValue('ailmentDetails.secondaryAilmentGroup', icdItem?.groupDescription);
                setFieldValue('ailmentDetails.secondaryIcdCode', icdItem?.icd10Code);
                setFieldValue('ailmentDetails.secondaryIcdGroup', icdItem?.groupCode);
            }
        }
    };
    const textChangeHandlerEvent = (e, isPrimary = true) => {
        const text = e?.target?.value;
        if (isPrimary) {
            setFieldValue('ailmentDetails.ailmentDescription', text);
            setFieldValue('ailmentDetails.ailmentGroup', '-');
            setFieldValue('ailmentDetails.icdCode', '-');
            setFieldValue('ailmentDetails.icdGroup', '-');
        }
        else {
            setFieldValue('ailmentDetails.secondaryAilmentDescription', text);
            setFieldValue('ailmentDetails.secondaryAilmentGroup', '-');
            setFieldValue('ailmentDetails.secondaryIcdCode', '-');
            setFieldValue('ailmentDetails.secondaryIcdGroup', '-');
        }
        if (text?.length > 0) {
            filterICDList(text, isPrimary);
            return;
        }
    };
    return (_jsx(Card, { className: "custom-card", children: _jsxs(GridContainer, { children: [_jsx(GridRow, { children: _jsx(GridColumn, { className: "pb-8", children: _jsx(Typography, { className: "text-regal-grey", variant: "h2", children: "Disease Details" }) }) }), _jsxs(GridRow, { children: [_jsx(GridColumn, { xs: 12, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Diagnosis and Symptoms", type: "text", onChange: handleChange, value: values.ailmentDetails?.finalDiagnosis, error: touched?.ailmentDetails?.finalDiagnosis && errors?.ailmentDetails?.finalDiagnosis
                                    ? errors?.ailmentDetails?.finalDiagnosis
                                    : '', disabled: DiseaseDetailsConfig.finalDiagnosis[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), name: "ailmentDetails.finalDiagnosis", inputLabel: "Diagnosis and Symptoms", required: true }) }), _jsx(GridColumn, { xs: 5, className: "input-box z-10", children: _jsx(Autocomplete, { options: primaryIcdList, filterOptions: options => options.filter(({ label }) => label
                                    ?.toLowerCase()
                                    .startsWith(values.ailmentDetails?.ailmentDescription?.toLowerCase())), value: values.ailmentDetails?.ailmentDescription, freeSolo: true, disablePortal: true, style: { width: 'fit-content' }, onChange: (e, newVal) => handleSelectIcdCodeEvent(newVal.value), PaperComponent: ({ children }) => (_jsx(Paper, { style: { marginTop: '8px', fontFamily: 'Inter', fontSize: '14px' }, children: children })), renderInput: params => (_jsx("div", { ref: params.InputProps.ref, children: _jsx(BorderedInput, { ...params.inputProps, inputLabel: "Primary Ailment Description ( ICD 10 Master )", placeholder: "Primary Ailment Description", type: "text", onChange: (e) => textChangeHandlerEvent(e), value: values.ailmentDetails?.ailmentDescription, name: "ailmentDetails.ailmentDescription", error: touched?.ailmentDetails?.ailmentDescription &&
                                            errors?.ailmentDetails?.ailmentDescription
                                            ? errors.ailmentDetails.ailmentDescription
                                            : '', disabled: DiseaseDetailsConfig.ailmentDescription[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), required: true }) })) }) }), _jsx(GridColumn, { xs: 5, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Primary ICD code", type: "text", value: values.ailmentDetails?.icdCode, name: "ailmentDetails.icdCode", inputLabel: "Primary ICD code", disabled: true }) }), _jsx(GridColumn, { xs: 5, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Primary Group Description", type: "text", value: values.ailmentDetails?.ailmentGroup, name: "ailmentDetails.ailmentGroup", inputLabel: "Primary Group Description", disabled: true }) })] }), _jsx(GridRow, { className: "pb-2", children: !showSecondaryIcd ? (_jsx(Button, { variant: "text", startAdornment: _jsx(AddIcon, { color: "#0276FE" }), onClick: () => setShowSecondaryIcd(showSecondaryIcd => !showSecondaryIcd), disabled: DiseaseDetailsConfig.secondaryAilmentDescription[selectedAction]?.[FormItemVisibilityEnum.isDisabled], children: "Add Secondary Disease Details" })) : (_jsxs(GridColumn, { className: "flex", children: [_jsx(Typography, { variant: "p2", children: "Secondary Disease" }), _jsx(Button, { variant: "text", className: "pl-4", startAdornment: _jsx(DeleteIconOutlined, { color: "#0276FE" }), onClick: () => {
                                    handleSelectIcdCodeEvent('', false);
                                    setShowSecondaryIcd(showSecondaryIcd => !showSecondaryIcd);
                                }, disabled: DiseaseDetailsConfig.secondaryAilmentDescription[selectedAction]?.[FormItemVisibilityEnum.isDisabled], children: "Delete" })] })) }), showSecondaryIcd && (_jsxs(GridRow, { children: [_jsx(GridColumn, { xs: 5, className: "input-box z-10", children: _jsx(Autocomplete, { options: secondaryIcdList, filterOptions: options => options.filter(({ label }) => label
                                    ?.toLowerCase()
                                    .startsWith(values.ailmentDetails?.secondaryAilmentDescription?.toLowerCase())), value: values.ailmentDetails?.secondaryAilmentDescription, freeSolo: true, disablePortal: true, style: { width: 'fit-content' }, onChange: (e, newVal) => handleSelectIcdCodeEvent(newVal.value, false), PaperComponent: ({ children }) => (_jsx(Paper, { style: { marginTop: '8px', fontFamily: 'Inter', fontSize: '14px' }, children: children })), renderInput: params => (_jsx("div", { ref: params.InputProps.ref, children: _jsx(BorderedInput, { ...params.inputProps, inputLabel: "Secondary Ailment Description", placeholder: "Secondary Ailment Description", type: "text", onChange: (e) => textChangeHandlerEvent(e, false), value: values.ailmentDetails?.secondaryAilmentDescription, name: "ailmentDetails.secondaryAilmentDescription", error: touched?.ailmentDetails?.secondaryAilmentDescription &&
                                            errors?.ailmentDetails?.secondaryAilmentDescription
                                            ? errors.ailmentDetails.secondaryAilmentDescription
                                            : '', disabled: DiseaseDetailsConfig.secondaryAilmentDescription[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), required: true }) })) }) }), _jsx(GridColumn, { xs: 5, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Secondary ICD code", type: "text", value: values.ailmentDetails?.secondaryIcdCode, name: "ailmentDetails.secondaryIcdCode", inputLabel: "Secondary ICD code", disabled: true }) }), _jsx(GridColumn, { xs: 5, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Secondary Group Description", type: "text", value: values.ailmentDetails?.secondaryAilmentGroup, name: "ailmentDetails.secondaryAilmentGroup", inputLabel: "Secondary Group Description", disabled: true }) })] }))] }) }));
};
export default DiseaseDetails;
