import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from '@navi/web-ui/lib/primitives';
import Count from '@navi/web-ui/lib/primitives/Counter/Counter';
import { selectNotifications, selectUnreadNotificationsCount } from '@redux/common/common.selector';
import { useAppSelector } from '@redux/hooks';
import React from 'react';
import NotificationItem from './NotificationItem';
import doubleTickIcon from '@assets/icons/double-tick.svg';
import { useDispatch } from 'react-redux';
import { readAllNotificationsApi, searchNotificationsApi } from '@services/NotificationsApi';
import { selectLoginUserGroups } from '@redux/userDetails/userDetails.selector';
import { DEFAULT_NOTIFICATION_PAGE_SIZE } from './constants';
import { LoadingIcon } from '@navi/web-ui/lib/icons';
const NotificationsPopup = () => {
    const notifications = useAppSelector(selectNotifications);
    const unreadNotificationsCount = useAppSelector(selectUnreadNotificationsCount);
    const dispatch = useDispatch();
    const groups = useAppSelector(selectLoginUserGroups);
    const hospitalId = groups[0]?.toUpperCase();
    const [isLoading, setIsLoading] = React.useState(false);
    const handleMarkAllRead = () => {
        dispatch(readAllNotificationsApi(hospitalId));
    };
    React.useEffect(() => {
        const request = { page: '0', size: DEFAULT_NOTIFICATION_PAGE_SIZE };
        dispatch(searchNotificationsApi(request, setIsLoading));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center justify-between px-6 pt-6 pb-5", children: [_jsxs("div", { children: [_jsx(Typography, { as: "span", variant: "h4", className: "mr-2", children: "Notifications" }), !!unreadNotificationsCount && (_jsx(Count, { value: unreadNotificationsCount, size: "sm", color: "red", overflowCount: 9 }))] }), !!unreadNotificationsCount && (_jsxs("div", { className: "flex cursor-pointer", onClick: handleMarkAllRead, children: [_jsx("img", { src: doubleTickIcon, alt: "tick", className: "mr-2" }), _jsx(Typography, { variant: "h5", className: "text-primary-blue", children: "Mark all read" })] }))] }), _jsx("div", { children: isLoading ? (_jsx("div", { className: "text-center py-4", children: _jsx(LoadingIcon, { size: "lg" }) })) : notifications?.length ? (notifications.map(notification => (_jsx(NotificationItem, { notification: notification }, notification.notificationId)))) : (_jsx("div", { className: "text-center py-4 px-1 opacity-50", children: _jsx(Typography, { variant: "p3", children: "No new notifications" }) })) })] }));
};
export default NotificationsPopup;
