import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { BorderedInput, Card, Typography } from '@navi/web-ui/lib/primitives';
import React from 'react';
import CustomSelectInput from '@components/CustomSelectInput';
import { useDispatch } from 'react-redux';
import { getClaimMasterDataRequest } from '@redux/claimJourney/claimJourney.actions';
import { ClaimMasterListEnum } from '@enums/ClaimMasterListEnum';
import { selectClaimMasterRoomType } from '@redux/claimJourney/claimJourney.selector';
import { useAppSelector } from '@redux/hooks';
import { convertMasterValueToOptionList, numberOnlyStyle } from '../constants';
import { RUPEE_SYMBOL } from '@const/Symbols';
import { FormItemVisibilityEnum, RoomDetailsConfig } from '../ClaimJourneyConfig';
import AlertOutlineIcon from '@assets/icons/AlertOutlineIcon';
import { getDateDiffInDays } from '@utils/Date';
const RoomDetails = ({ handleChange, errors, values, touched, setFieldValue, selectedAction }) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (!RoomTypeList?.length) {
            dispatch(getClaimMasterDataRequest(ClaimMasterListEnum.ROOM_TYPE));
        }
    }, []);
    React.useEffect(() => {
        if (values.dateOfDischarge &&
            values.dateOfAdmission &&
            values.dateOfAdmissionTime &&
            values.dateOfDischargeTime) {
            setFieldValue('ailmentDetails.lengthOfStay', getDateDiffInDays(`${values.dateOfAdmission} ${values.dateOfAdmissionTime}`, `${values.dateOfDischarge} ${values.dateOfDischargeTime}`, true));
        }
    }, [
        values.dateOfAdmission,
        values.dateOfAdmissionTime,
        values.dateOfDischarge,
        values.dateOfDischargeTime
    ]);
    const RoomTypeList = convertMasterValueToOptionList(useAppSelector(selectClaimMasterRoomType));
    return (_jsx(Card, { className: "custom-card", children: _jsxs(GridContainer, { children: [_jsx(GridRow, { children: _jsx(GridColumn, { className: "pb-8", children: _jsx(Typography, { className: "text-regal-grey", variant: "h2", children: "Room Details" }) }) }), _jsxs(GridRow, { children: [_jsx(GridColumn, { xs: 5, className: "input-box", children: _jsx(CustomSelectInput, { inputLabel: "Room Type", placeholder: "Room Type", options: RoomTypeList, value: values.ailmentDetails?.roomType, name: "ailmentDetails.roomType", multiSelect: false, readOnly: true, setSelectedValue: (e) => setFieldValue('ailmentDetails.roomType', e), error: touched?.ailmentDetails?.roomType && errors?.ailmentDetails?.roomType
                                    ? errors.ailmentDetails.roomType
                                    : '', disabled: RoomDetailsConfig.roomType[selectedAction]?.[FormItemVisibilityEnum.isDisabled], required: true }) }), _jsx(GridColumn, { xs: 5, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Room Rent per day", LeftInputAdornment: RUPEE_SYMBOL, type: "number", onChange: handleChange, value: values.ailmentDetails?.perDayRoomRent, error: touched?.ailmentDetails?.perDayRoomRent && errors?.ailmentDetails?.perDayRoomRent
                                    ? errors.ailmentDetails.perDayRoomRent
                                    : '', disabled: RoomDetailsConfig.perDayRoomRent[selectedAction]?.[FormItemVisibilityEnum.isDisabled], Icon: _jsx(AlertOutlineIcon, {}), containerClassName: numberOnlyStyle, name: "ailmentDetails.perDayRoomRent", inputLabel: "Room Rent per day", required: true }) }), _jsx(GridColumn, { xs: 5, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Length of stay (LOS)", type: "number", onChange: handleChange, value: values.ailmentDetails?.lengthOfStay, error: touched?.ailmentDetails?.lengthOfStay && errors?.ailmentDetails?.lengthOfStay
                                    ? errors.ailmentDetails.lengthOfStay
                                    : '', disabled: RoomDetailsConfig.lengthOfStay[selectedAction]?.[FormItemVisibilityEnum.isDisabled], containerClassName: numberOnlyStyle, name: "ailmentDetails.lengthOfStay", inputLabel: "Length of stay (LOS)", Icon: _jsx(AlertOutlineIcon, {}), required: true }) })] })] }) }));
};
export default RoomDetails;
