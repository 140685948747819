import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Card, Typography } from '@navi/web-ui/lib/primitives';
import styles from '../Dashboard.module.scss';
const ClosedClaimsChart = (props) => {
    const { homePageDetails } = props;
    const { settledClaims = 0, rejectedClaims = 0, cancelledClaims = 0 } = homePageDetails || {};
    const claimsChart = [
        {
            title: 'Settled',
            count: settledClaims,
            color: '#0088FE'
        },
        {
            title: 'Rejected',
            count: rejectedClaims,
            color: '#FFBB28'
        },
        {
            title: 'Cancelled',
            count: cancelledClaims,
            color: '#FF8042'
        }
    ];
    const totalClaims = settledClaims + rejectedClaims + cancelledClaims;
    const settledClaimsPercent = Math.round((settledClaims / totalClaims) * 100);
    const rejectedClaimsPercent = Math.round((rejectedClaims / totalClaims) * 100);
    return (_jsx(GridContainer, { className: "px-0", children: _jsxs(Card, { className: `w p-6 rounded-xl homepage-custom-card`, children: [_jsx(GridRow, { className: "", children: _jsx(Typography, { variant: "p2", className: "font-semibold", color: "#1C1C1C", children: "Closed Claims" }) }), _jsxs(GridRow, { className: "m-0 mt-7", children: [_jsx(GridColumn, { xs: 6, className: "p-0", children: _jsx("div", { className: styles['pie'], style: {
                                    backgroundImage: totalClaims
                                        ? `conic-gradient(
                  from 0deg,
                  #0088fe 0deg calc(3.6deg * ${settledClaimsPercent}),
                  #ffbb28 calc(3.6deg * ${settledClaimsPercent}) calc(3.6deg * ${settledClaimsPercent + rejectedClaimsPercent}),
                  #ff8042 calc(3.6deg * ${settledClaimsPercent + rejectedClaimsPercent}) calc(3.6deg * 100)
                )`
                                        : 'conic-gradient(from 0deg, #E8E8E8 0deg 360deg)'
                                } }) }), _jsx(GridColumn, { xs: 6, className: "p-0 mt-3", children: claimsChart.map((item, index) => (_jsxs(GridRow, { className: "m-0 justify-between mt-2", children: [_jsxs(Typography, { variant: "p5", color: "#1C1C1C", className: "flex items-center", children: [_jsx("div", { className: "w-2.5 h-2.5 rounded-sm mr-2", style: { background: item.color } }), ' ', item.title] }), _jsx(Typography, { variant: "p5", className: "font-medium", color: "#1C1C1C", children: item.count })] }, index))) })] })] }) }));
};
export default ClosedClaimsChart;
