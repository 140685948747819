import { ActionOptionEnum } from './constants';
export var FormItemVisibilityEnum;
(function (FormItemVisibilityEnum) {
    FormItemVisibilityEnum[FormItemVisibilityEnum["isVisible"] = 0] = "isVisible";
    FormItemVisibilityEnum[FormItemVisibilityEnum["isDisabled"] = 1] = "isDisabled";
})(FormItemVisibilityEnum || (FormItemVisibilityEnum = {}));
const DisabledActionConfiguration = {
    [ActionOptionEnum.VIEW]: {
        [FormItemVisibilityEnum.isDisabled]: true
    },
    [ActionOptionEnum.CANCEL]: {
        [FormItemVisibilityEnum.isDisabled]: true
    },
    [ActionOptionEnum.NEW_INITIAL]: {
        [FormItemVisibilityEnum.isDisabled]: true
    },
    [ActionOptionEnum.NEW_DISCHARGE]: {
        [FormItemVisibilityEnum.isDisabled]: true
    },
    [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
        [FormItemVisibilityEnum.isDisabled]: true
    },
    [ActionOptionEnum.REQUEST_DISCHARGE]: {
        [FormItemVisibilityEnum.isDisabled]: true
    },
    [ActionOptionEnum.MISSING_INFO_SUBMISSION]: {
        [FormItemVisibilityEnum.isDisabled]: true
    },
    [ActionOptionEnum.MISSING_INFO_SUBMISSION_NR]: {
        [FormItemVisibilityEnum.isDisabled]: true
    },
    [ActionOptionEnum.SUBMIT_NETWORK_CLAIM]: {
        [FormItemVisibilityEnum.isDisabled]: true
    }
};
export const ClaimDetailsConfig = {
    claimedAmount: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    dateOfAdmission: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    dateOfAdmissionTime: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    dateOfDischarge: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    dateOfDischargeTime: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    }
};
export const PatientDetailsConfig = {
    mobileNumber: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    emailId: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    currentAddress: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    city: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    state: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    pincode: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    kycDocumentDetailsType: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    kycDocumentDetailsNumber: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    panCardNumber: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    }
};
export const HospitalizationDetailsConfig = {
    hospitalisationType: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    treatment: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    tpaAdmissionType: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    dateOfAccident: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    dateOfDelivery: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    dateOfDiagnosis: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    inPatientNumber: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    }
};
export const DiseaseDetailsConfig = {
    finalDiagnosis: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    ailmentDescription: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    secondaryAilmentDescription: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    }
};
export const RoomDetailsConfig = {
    roomType: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    perDayRoomRent: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: false
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: false
        }
    },
    lengthOfStay: {
        ...DisabledActionConfiguration,
        [ActionOptionEnum.NEW_INITIAL]: {
            [FormItemVisibilityEnum.isDisabled]: true
        },
        [ActionOptionEnum.NEW_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: true
        },
        [ActionOptionEnum.REQUEST_ENHANCEMENT]: {
            [FormItemVisibilityEnum.isDisabled]: true
        },
        [ActionOptionEnum.REQUEST_DISCHARGE]: {
            [FormItemVisibilityEnum.isDisabled]: true
        }
    }
};
