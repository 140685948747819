export var PendingActionTicketStatus;
(function (PendingActionTicketStatus) {
    PendingActionTicketStatus["DISCHARGE_AWAITED"] = "DISCHARGE_AWAITED";
    PendingActionTicketStatus["CASHLESS_DOCUMENT_AWAITED"] = "CASHLESS_DOCUMENT_AWAITED";
    PendingActionTicketStatus["MORE_INFORMATION_NEEDED"] = "MORE_INFORMATION_NEEDED";
})(PendingActionTicketStatus || (PendingActionTicketStatus = {}));
export var ProgressDetailsTitleMapping;
(function (ProgressDetailsTitleMapping) {
    ProgressDetailsTitleMapping["claimApproved"] = "Claim Approved";
    ProgressDetailsTitleMapping["pendingNavi"] = "Pending with NAVI";
    ProgressDetailsTitleMapping["claimRejected"] = "Claim Rejected";
    ProgressDetailsTitleMapping["moreInformationNeeded"] = "Missing Info";
    ProgressDetailsTitleMapping["claimCancelled"] = "Claim Cancelled";
    ProgressDetailsTitleMapping["enhancementApproved"] = "Enhancement Approved";
    ProgressDetailsTitleMapping["dischargeRejected"] = "Discharge Rejected";
    ProgressDetailsTitleMapping["enhancementRejected"] = "Enhancement Rejected";
    ProgressDetailsTitleMapping["dischargeApproved"] = "Discharge Approved";
    ProgressDetailsTitleMapping["initialApproved"] = "Initial Approved";
    ProgressDetailsTitleMapping["claimSettled"] = "Claim Settled";
    ProgressDetailsTitleMapping["preAuthApproved"] = "Pre-Auth Approved";
    ProgressDetailsTitleMapping["transactionRejected"] = "Transaction Rejected";
    ProgressDetailsTitleMapping["cashlessDocumentAwaited"] = "Cashless Document Awaited";
})(ProgressDetailsTitleMapping || (ProgressDetailsTitleMapping = {}));
