import { ApiService } from './ApiService';
import { AUTH_CLIENT_ID, AUTH_API_BASE_URL } from '@const/ApiConstants';
const ENDPOINTS = {
    LOGIN: {
        endpoint: () => `${AUTH_API_BASE_URL}/auth/otp/generate/${AUTH_CLIENT_ID}`
    },
    LOGOUT: {
        endpoint: () => `${AUTH_API_BASE_URL}/auth/logout/${AUTH_CLIENT_ID}`
    },
    VERIFY_LOGIN: {
        endpoint: () => `${AUTH_API_BASE_URL}/auth/otp/verify/${AUTH_CLIENT_ID}`
    }
};
export const requestOTP = (phoneNumber) => {
    const requestBody = {
        phoneNumber
    };
    return ApiService.post(ENDPOINTS.LOGIN.endpoint(), requestBody);
};
export const verifyOTP = (otp, phoneNumber, otpToken) => {
    const requestBody = {
        otp,
        otpToken
    };
    return ApiService.post(ENDPOINTS.VERIFY_LOGIN.endpoint(), requestBody);
};
export const logOutUser = () => {
    return ApiService.get(ENDPOINTS.LOGOUT.endpoint());
};
