import { ACTION_TYPES } from '@redux/ReduxActionTypes';
import { initialState as claimJourneyDetailsInitState } from './constants';
const { CLAIM_JOURNEY_ACTION_TYPES } = ACTION_TYPES;
const claimJourneyDetailsReducer = (state = claimJourneyDetailsInitState, action) => {
    switch (action?.type) {
        case CLAIM_JOURNEY_ACTION_TYPES.SET_POLICY_STATUS_SUCCESS: {
            return {
                ...state,
                policyStatus: action?.payload
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_POLICY_STATUS_ERROR: {
            return {
                ...state,
                policyError: action?.payload
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.RESET_POLICY_STATUS: {
            return {
                ...state,
                policyStatus: undefined
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.RESET_POLICY_STATUS_ERROR: {
            return {
                ...state,
                policyError: undefined
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_POLICY_DETAIL_SUCCESS: {
            return {
                ...state,
                policyDetails: action?.payload
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.RESET_POLICY_DETAILS: {
            return {
                ...state,
                policyDetails: undefined
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_POLICY_DETAILS_FETCHING: {
            return {
                ...state,
                isPolicyDetailsFetching: action?.payload
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_DETAIL_SUCCESS: {
            return {
                ...state,
                claimDetails: action?.payload
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.RESET_CLAIM_DETAILS: {
            return {
                ...state,
                claimDetails: undefined
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_DETAILS_FETCHING: {
            return {
                ...state,
                isClaimDetailsFetching: action?.payload
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_MASTER_HOSPITALIZATION_TYPE_SUCCESS: {
            return {
                ...state,
                claimMasterData: {
                    ...state.claimMasterData,
                    hospitalizationType: action?.payload
                }
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_MASTER_CASE_TYPE_SUCCESS: {
            return {
                ...state,
                claimMasterData: {
                    ...state.claimMasterData,
                    caseType: action?.payload
                }
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_MASTER_ADMISSION_TYPE_SUCCESS: {
            return {
                ...state,
                claimMasterData: {
                    ...state.claimMasterData,
                    admissionType: action?.payload
                }
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_CLAIM_MASTER_ROOM_TYPE_SUCCESS: {
            return {
                ...state,
                claimMasterData: {
                    ...state.claimMasterData,
                    roomType: action?.payload
                }
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.RESET_CLAIM_MASTER_DATA: {
            return {
                ...state,
                claimMasterData: {
                    admissionType: [],
                    caseType: [],
                    hospitalizationType: [],
                    roomType: []
                }
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.ADD_DOCUMENT: {
            return {
                ...state,
                documents: { ...state.documents, ...action?.payload }
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.ADD_ALL_DOCUMENTS: {
            return {
                ...state,
                documents: { ...action?.payload }
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.REMOVE_DOCUMENT: {
            const newDocuments = { ...state.documents };
            delete newDocuments[action?.payload];
            return {
                ...state,
                documents: newDocuments
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_DOCUMENT_TAGS: {
            return {
                ...state,
                documents: {
                    ...state.documents,
                    [action?.payload?.documentReferenceId]: {
                        ...state.documents[action?.payload?.documentReferenceId],
                        tags: action?.payload?.tags
                    }
                }
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.RESET_DOCUMENTS: {
            return {
                ...state,
                documents: {}
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_SUBMIT_MISSING_INFO_PAYLOAD: {
            return {
                ...state,
                submitMissingInfoPayload: action?.payload
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.RESET_SUBMIT_MISSING_INFO_PAYLOAD: {
            return {
                ...state,
                submitMissingInfoPayload: []
            };
        }
        case CLAIM_JOURNEY_ACTION_TYPES.SET_DRAFT_SELECTED_ACTION: {
            return {
                ...state,
                draftSelectedAction: action?.payload
            };
        }
        default:
            return state;
    }
};
export default claimJourneyDetailsReducer;
