import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense, useEffect } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@redux/hooks';
import { setIsUserLoggedIn, startFetchUserInfo } from '@redux/auth/auth.actions';
import { selectIsUserAuthenticated } from '@redux/auth/auth.selectors';
import { getCookie } from '@utils/Cookies';
import { Fallback } from '@components/';
import { selectLoginUserRoles } from '@redux/userDetails/userDetails.selector';
const RequireAuth = () => {
    const isUserAuthenticated = useAppSelector(selectIsUserAuthenticated);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const roles = useAppSelector(selectLoginUserRoles);
    const isCookiePresent = getCookie() !== '';
    useEffect(() => {
        if (!isUserAuthenticated) {
            dispatch(setIsUserLoggedIn(true));
        }
        if (!roles.length) {
            dispatch(startFetchUserInfo());
        }
    }, []);
    if (!isUserAuthenticated && !isCookiePresent) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return _jsx(Navigate, { to: "/login", state: { from: location }, replace: true });
    }
    return (_jsx(Suspense, { fallback: _jsx(Fallback, {}), children: _jsx(Outlet, {}) }));
};
export default RequireAuth;
