export const PreAuthClaimsData = [
    'pendingNavi',
    'dischargeApproved',
    'preAuthApproved',
    'moreInformationNeeded',
    'transactionRejected'
];
export const NetworkReimbursementClaimsData = [
    'pendingNavi',
    'dischargeApproved',
    'claimApproved',
    'moreInformationNeeded',
    'cashlessDocumentAwaited'
];
