import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@navi/web-ui/lib/primitives';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { useAppSelector } from '@redux/hooks';
import { selectLoginUserGroups } from '@redux/userDetails/userDetails.selector';
import { getHospitalDetails } from '@services/HospitalApi';
import { STATUS_CODE } from '@const/ApiConstants';
import { getErrorMessage } from '@utils/';
import { hideLoader, showLoader } from '@redux/common/common.actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import AccountDetails from './partials/AccountDetails';
import Banner from './partials/Banner';
import { hospitalInitialValues } from './constants';
import ContactDetails from './partials/ContactDetails';
import Documents from './partials/Documents';
import MouDetails from './partials/MouDetails';
const HospitalDetails = () => {
    const groups = useAppSelector(selectLoginUserGroups);
    const hospitalId = groups[0]?.toUpperCase();
    const dispatch = useDispatch();
    const [hospitalDetails, setHospitalDetails] = React.useState(hospitalInitialValues);
    React.useEffect(() => {
        if (hospitalId) {
            dispatch(showLoader());
            getHospitalDetails(hospitalId)
                .then((response) => {
                if (response.data?.statusCode === STATUS_CODE.OK) {
                    setHospitalDetails(response.data?.data);
                    return;
                }
                throw response;
            })
                .catch((error) => {
                toast.error(getErrorMessage(error));
            })
                .finally(() => {
                dispatch(hideLoader());
            });
        }
    }, [hospitalId]);
    return (_jsxs("div", { className: "hospital-details px-14 py-6 m-auto", children: [_jsx(Typography, { variant: "h1", className: "mb-8", children: "Hospital Details" }), _jsx(Banner, { hospitalDetails: hospitalDetails }), _jsx(MouDetails, { hospitalDetails: hospitalDetails }), _jsx(ContactDetails, { hospitalDetails: hospitalDetails, setHospitalDetails: setHospitalDetails }), _jsx(Documents, { hospitalDetails: hospitalDetails, setHospitalDetails: setHospitalDetails }), _jsx(AccountDetails, { hospitalDetails: hospitalDetails, setHospitalDetails: setHospitalDetails })] }));
};
export default HospitalDetails;
