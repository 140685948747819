export var ClaimStatus;
(function (ClaimStatus) {
    ClaimStatus["NEW"] = "NEW";
    ClaimStatus["CLAIM_CREATED"] = "CLAIM_CREATED";
    ClaimStatus["DETAILS_COMPLETE"] = "DETAILS_COMPLETE";
    ClaimStatus["PENDING_ADJUDICATION"] = "PENDING_ADJUDICATION";
    ClaimStatus["APPROVED"] = "APPROVED";
    ClaimStatus["PHYSICAL_DOCUMENT_AWAITED"] = "PHYSICAL_DOCUMENT_AWAITED";
    ClaimStatus["DOCUMENT_UNDER_PROCESS"] = "DOCUMENT_UNDER_PROCESS";
    ClaimStatus["PAYMENT_IN_PROCESS"] = "PAYMENT_IN_PROCESS";
    ClaimStatus["PAYMENT_PROCESSED"] = "PAYMENT_PROCESSED";
    ClaimStatus["PENDING_PAYMENT"] = "PENDING_PAYMENT";
    ClaimStatus["CLAIM_CLOSED"] = "CLAIM_CLOSED";
    ClaimStatus["CLAIM_REPUDIATED"] = "CLAIM_REPUDIATED";
    ClaimStatus["IR"] = "IR";
    ClaimStatus["CLAIM_DENIED"] = "CLAIM_DENIED";
    ClaimStatus["DISPUTE_RAISED"] = "CLAIM_DENIED";
    ClaimStatus["INVESTIGATION"] = "INVESTIGATION";
    ClaimStatus["CANCELLED"] = "CANCELLED";
    ClaimStatus["DOCUMENT_AWAITED"] = "DOCUMENT_AWAITED";
    ClaimStatus["APPROVED_PENDING_INSURER_APPROVAL"] = "APPROVED_PENDING_INSURER_APPROVAL";
    ClaimStatus["REJECTED_PENDING_INSURER_APPROVAL"] = "REJECTED_PENDING_INSURER_APPROVAL";
    ClaimStatus["MISSING_INFO"] = "MISSING_INFO";
    ClaimStatus["CLAIM_REJECTED"] = "CLAIM_REJECTED";
    ClaimStatus["CLAIM_CANCELLED"] = "CLAIM_CANCELLED";
    ClaimStatus["INVESTIGATION_RAISED"] = "INVESTIGATION_RAISED";
    ClaimStatus["DISCUSSION_WITH_HOSPITAL"] = "DISCUSSION_WITH_HOSPITAL";
    ClaimStatus["CLAIM_APPROVED"] = "CLAIM_APPROVED";
    ClaimStatus["PAYMENT_INITIATED"] = "PAYMENT_INITIATED";
    ClaimStatus["PAYMENT_FAILED"] = "PAYMENT_FAILED";
    ClaimStatus["PAYMENT_RETRY"] = "PAYMENT_RETRY";
    ClaimStatus["READY_FOR_PAYMENT"] = "READY_FOR_PAYMENT";
    ClaimStatus["CLAIM_SETTLED"] = "CLAIM_SETTLED";
    ClaimStatus["CASHLESS_DOCUMENT_RECEIVED"] = "CASHLESS_DOCUMENT_RECEIVED";
    ClaimStatus["CASHLESS_DOCUMENT_REQUESTED"] = "CASHLESS_DOCUMENT_REQUESTED";
    ClaimStatus["CASHLESS_DOCUMENT_AWAITED"] = "CASHLESS_DOCUMENT_AWAITED";
    ClaimStatus["CLAIM_INITIATED"] = "CLAIM_INITIATED";
    ClaimStatus["PENDING_DATA_ENTRY"] = "PENDING_DATA_ENTRY";
    ClaimStatus["CASHLESS_PROCESSED"] = "CASHLESS_PROCESSED";
    ClaimStatus["PENDING_DATA_ENTRY_ME"] = "PENDING_DATA_ENTRY_ME";
    ClaimStatus["PENDING_DATA_ENTRY_RM"] = "PENDING_DATA_ENTRY_RM";
    ClaimStatus["INITIAL_REQUEST_RECEIVED"] = "INITIAL_REQUEST_RECEIVED";
    ClaimStatus["INITIAL_REQUEST_APPROVED"] = "INITIAL_REQUEST_APPROVED";
    ClaimStatus["ENHANCEMENT_REQUEST_RECEIVED"] = "ENHANCEMENT_REQUEST_RECEIVED";
    ClaimStatus["ENHANCEMENT_REQUEST_APPROVED"] = "ENHANCEMENT_REQUEST_APPROVED";
    ClaimStatus["DISCHARGE_REQUEST_RECEIVED"] = "DISCHARGE_REQUEST_RECEIVED";
    ClaimStatus["DISCHARGE_REQUEST_APPROVED"] = "DISCHARGE_REQUEST_APPROVED";
    ClaimStatus["PENDING_REJECTION_LETTER_REVIEW"] = "PENDING_REJECTION_LETTER_REVIEW";
    ClaimStatus["PENDING_REJECTION_LETTER_CREATION"] = "PENDING_REJECTION_LETTER_CREATION";
    ClaimStatus["REJECTION_INITIATED"] = "REJECTION_INITIATED";
    ClaimStatus["PENDING_INTERNAL_PROCESSING"] = "PENDING_INTERNAL_PROCESSING";
})(ClaimStatus || (ClaimStatus = {}));
export const TerminalClaimStatus = {
    [ClaimStatus.CLAIM_SETTLED]: ClaimStatus.CLAIM_SETTLED,
    [ClaimStatus.CLAIM_CLOSED]: ClaimStatus.CLAIM_CLOSED,
    [ClaimStatus.CLAIM_CANCELLED]: ClaimStatus.CLAIM_CANCELLED,
    [ClaimStatus.CLAIM_REJECTED]: ClaimStatus.CLAIM_REJECTED
};
