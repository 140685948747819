import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { BorderedInput, Card, Typography } from '@navi/web-ui/lib/primitives';
import { getPolicyExtraDetails } from '@src/services/PolicyApi';
import React from 'react';
import DeductionDetails from './DeductionDetails';
import viewIcon from '@assets/icons/view-icon.svg';
import { RUPEE_SYMBOL } from '@const/Symbols';
const HospitalDiscountDeductionType = 'Hospital Discount';
const OtherHospitalDiscountDeductionType = 'Hospital Discount (Others)';
const ApprovedDetails = ({ values, claimDetails }) => {
    const [premiumRecovery, setPremiumRecoveryAmount] = React.useState(0);
    const [payableByPatient, setPayableByPatient] = React.useState(0);
    const [showDeductions, setShowDeductions] = React.useState(false);
    React.useEffect(() => {
        if (!claimDetails?.claimInfo?.policyId || !claimDetails?.claimInfo?.customerId)
            return;
        getPolicyExtraDetails(claimDetails.claimInfo.policyId, claimDetails.claimInfo.customerId).then((resp) => {
            if (!resp?.data?.subscription?.fullPaymentInfo?.amount)
                return;
            setPremiumRecoveryAmount(resp.data.subscription.fullPaymentInfo.amount);
        });
    }, [claimDetails?.claimInfo]);
    React.useEffect(() => {
        if (claimDetails?.claimInfo?.approvedAmount || claimDetails?.lastApprovedAmount) {
            const billedAmount = claimDetails.claimBills?.reduce((a, c) => a + c.amount, 0) || 0;
            const approvedAmount = claimDetails.claimInfo.approvedAmount || claimDetails.lastApprovedAmount || 0;
            const hospitalDiscountDeductionAmount = claimDetails.claimLevelDeduction?.find((item) => item.deductionType === HospitalDiscountDeductionType)?.deductionAmount || 0;
            const otherHospitalDiscountDeductionAmount = claimDetails.claimLevelDeduction?.find((item) => item.deductionType === OtherHospitalDiscountDeductionType)?.deductionAmount || 0;
            setPayableByPatient(billedAmount -
                (approvedAmount +
                    hospitalDiscountDeductionAmount +
                    otherHospitalDiscountDeductionAmount) +
                premiumRecovery);
        }
        else {
            setPayableByPatient(0);
        }
    }, [premiumRecovery, claimDetails]);
    return (_jsxs(_Fragment, { children: [_jsx(Card, { className: "custom-card mt-4", children: _jsxs(GridContainer, { children: [_jsxs(GridRow, { className: "flex items-center justify-between pb-8", children: [_jsx(Typography, { className: "text-regal-grey", variant: "h2", children: "Approval Details" }), !!claimDetails?.claimBills?.length && claimDetails?.lastApprovedAmount > 0 && (_jsxs(GridColumn, { className: "text-primary-blue flex items-center w-auto cursor-pointer", variant: "h5", onClick: () => setShowDeductions(true), children: [_jsx("img", { src: viewIcon, className: "mr-1" }), "Show Deduction Details"] }))] }), _jsxs(GridRow, { children: [_jsx(GridColumn, { xs: 6, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Billed Amount", type: "text", LeftInputAdornment: RUPEE_SYMBOL, value: claimDetails?.claimBills
                                            ?.reduce((a, c) => a + c.amount, 0)
                                            .toFixed(2), inputLabel: "Billed Amount", disabled: true }) }), _jsx(GridColumn, { xs: 6, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Approved room type", type: "text", value: values.ailmentDetails?.approvedRoomType, inputLabel: "Approved room type", disabled: true }) }), _jsx(GridColumn, { xs: 6, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Admissible Amount", type: "text", LeftInputAdornment: RUPEE_SYMBOL, value: claimDetails?.claimBills
                                            ?.reduce((a, c) => a + (c.amount - (c.nonPayableAmount ?? 0)), 0)
                                            .toFixed(2), inputLabel: "Admissible Amount", disabled: true }) }), _jsx(GridColumn, { xs: 6, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Approved LOS", type: "text", value: values.ailmentDetails?.approvedLengthOfStay, inputLabel: "Approved LOS", disabled: true }) }), _jsx(GridColumn, { xs: 6, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Final Approved Amount", type: "text", LeftInputAdornment: RUPEE_SYMBOL, value: claimDetails?.lastApprovedAmount, inputLabel: "Final Approved Amount", disabled: true }) }), _jsx(GridColumn, { xs: 6, className: "input-box", children: _jsx(BorderedInput, { placeholder: "Approved Admission Type", type: "text", value: claimDetails?.lastApprovedAmount > 0 ? values.ailmentDetails?.admissionType : '', inputLabel: "Approved Admission Type", disabled: true }) }), _jsx(GridColumn, { xs: 6, children: _jsx(BorderedInput, { placeholder: "Amount Payable by Patient", type: "text", LeftInputAdornment: RUPEE_SYMBOL, value: payableByPatient, inputLabel: "Amount Payable by Patient", disabled: true }) }), _jsx(GridColumn, { xs: 6, children: _jsx(BorderedInput, { placeholder: "Eligible Room Rent", type: "text", value: values.ailmentDetails?.approvedPerDayRoomRent, inputLabel: "Eligible Room Rent", disabled: true }) })] })] }) }), !!claimDetails?.claimBills?.length && (_jsx(DeductionDetails, { showDeductions: showDeductions, setShowDeductions: setShowDeductions, premiumRecovery: premiumRecovery }))] }));
};
export default ApprovedDetails;
