import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Card, KeyValueLabel, Tag, Typography } from '@navi/web-ui/lib/primitives';
import { getBusinessDateFormatTime, getFormattedDate } from '@utils/Date';
import { convertToTitleCase } from '@utils/';
import { getClaimStatusToJourneyType } from '../constants';
import { DEFAULT_TEXT } from '@const/Symbols';
import { useAppSelector } from '@redux/hooks';
import { selectClaimDetails, selectPolicyDetails } from '@redux/claimJourney/claimJourney.selector';
import { getHospitalPortalStatus } from '@const/Claim';
import styles from '../ClaimJourney.module.scss';
const SeparatorComponent = () => (_jsx(Typography, { variant: "p2", as: "span", className: "text-primary-blue", children: "\u00A0|\u00A0" }));
const Header = ({ isNewPreAuthRequest, memberId, latestPreAuthDetails }) => {
    const claimDetails = useAppSelector(selectClaimDetails);
    const policyDetails = useAppSelector(selectPolicyDetails);
    const memberDetails = policyDetails?.members?.find(member => member.memberId === memberId);
    return (_jsx(Card, { className: `bg-regal-blue w p-0 ${styles['header-card']}`, children: _jsx(GridContainer, { className: "p-0", children: _jsxs(GridRow, { children: [_jsxs(GridColumn, { xs: 8, className: "py-6", children: [_jsx(GridRow, { className: "pb-7", children: _jsxs(Typography, { className: "text-regal-grey", variant: "p2", as: "span", children: ["Policy ID:\u00A0", _jsx(Typography, { className: "text-primary-blue", variant: "p2", as: "span", children: policyDetails?.policyId }), _jsx(SeparatorComponent, {}), "Member ID: ", memberId] }) }), _jsx(GridRow, { className: "pb-1", children: _jsx(Typography, { variant: "p1", className: "font-bold text-base-black", children: memberDetails?.name }) }), _jsx(GridRow, { className: "pb-6", children: _jsxs(Typography, { className: "text-base-black", variant: "p2", as: "span", children: [memberDetails?.gender, ", ", memberDetails?.age, _jsx(SeparatorComponent, {}), "DOB: ", getFormattedDate(memberDetails?.dob, getBusinessDateFormatTime('/'))] }) }), _jsx(GridRow, { children: _jsxs(Typography, { className: "text-regal-grey", variant: "p2", as: "span", children: ["Policy holder: ", memberDetails?.relation, _jsx(SeparatorComponent, {}), "Policy Validity:\u00A0", getFormattedDate(policyDetails?.policyIssueDate, getBusinessDateFormatTime('/')), "\u00A0to\u00A0", getFormattedDate(policyDetails?.expiryDate, getBusinessDateFormatTime('/')), policyDetails?.status && (_jsx(Typography, { variant: "p2", as: "span", className: "ml-[10px]", children: _jsx(Tag, { color: "green", label: convertToTitleCase(policyDetails.status) }) }))] }) })] }), _jsxs(GridColumn, { xs: 4, className: "pt-5 pb-2", children: [_jsx(GridRow, { className: "px-4", children: _jsx(Typography, { variant: "p1", className: "font-medium text-base-black", children: "Claims Details" }) }), _jsx(GridRow, { children: _jsx(KeyValueLabel, { className: `bg-regal-blue pt-3 pb-1 ${styles['header-details']}`, dataArray: [
                                        {
                                            key: 'Claim Number:',
                                            value: claimDetails?.claimInfo?.claimId || DEFAULT_TEXT
                                        },
                                        {
                                            key: 'Journey:',
                                            value: getClaimStatusToJourneyType(claimDetails?.claimStatus) ||
                                                DEFAULT_TEXT
                                        },
                                        {
                                            key: 'Status:',
                                            value: getHospitalPortalStatus(latestPreAuthDetails.type, latestPreAuthDetails.status, claimDetails?.claimStatus) || DEFAULT_TEXT
                                        },
                                        {
                                            key: 'Latest authorisation no.',
                                            value: latestPreAuthDetails.preAuthId?.toString() || DEFAULT_TEXT
                                        }
                                    ] }) })] })] }) }) }));
};
export default Header;
