import { validateDigitOptional } from '@const/Regex';
import { ErrValidationMessage, ErrValidationType, ValidationConst } from '@const/Validations';
import * as Yup from 'yup';
import { BankAccountType, PackageType } from './types';
export const hospitalInitialValues = {
    bankName: '',
    ifsCode: '',
    bankAddress: '',
    bankAccountNumber: '',
    accountType: '',
    chequePayableName: '',
    panNumber: '',
    cancelledChequeReferenceId: '',
    hospitalName: '',
    addressArea: '',
    addressBuildingStreet: '',
    addressLandmark: '',
    city: '',
    pincode: '',
    hospitalId: '',
    rohinicode: '',
    speciality: '',
    registrationNumber: '',
    bedCount: 0,
    allottedNaviBranch: '',
    hospitalType: '',
    state: '',
    email: '',
    website: '',
    phoneNumbers: [],
    ceoName: '',
    ceoEmailId: '',
    financeManagerName: '',
    financeManagerEmailId: '',
    headOfOperationsName: '',
    headOfOperationsEmailId: '',
    tpaCoordinatorName: '',
    tpaCoordinatorEmailId: '',
    discountExclusions: [],
    discountPercentage: 0,
    documents: []
};
export const HospitalDetailsSchema = Yup.object().shape({
    hospitalContact: Yup.object().shape({
        phoneNumber: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .test(ErrValidationType.NUMERIC, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER, validateDigitOptional)
            .length(ValidationConst.PHONE_NUMBER_LENGTH, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER),
        email: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .email(ErrValidationMessage.ENTER_VALID_EMAIL),
        website: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED)
    }),
    managingDirector: Yup.object().shape({
        name: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        phoneNumber: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .test(ErrValidationType.NUMERIC, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER, validateDigitOptional)
            .length(ValidationConst.PHONE_NUMBER_LENGTH, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER),
        email: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .email(ErrValidationMessage.ENTER_VALID_EMAIL)
    }),
    financeManager: Yup.object().shape({
        name: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        phoneNumber: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .test(ErrValidationType.NUMERIC, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER, validateDigitOptional)
            .length(ValidationConst.PHONE_NUMBER_LENGTH, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER),
        email: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .email(ErrValidationMessage.ENTER_VALID_EMAIL)
    }),
    operationsHead: Yup.object().shape({
        name: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        phoneNumber: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .test(ErrValidationType.NUMERIC, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER, validateDigitOptional)
            .length(ValidationConst.PHONE_NUMBER_LENGTH, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER),
        email: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .email(ErrValidationMessage.ENTER_VALID_EMAIL)
    }),
    tpaCoordinator: Yup.object().shape({
        name: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
        phoneNumber: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .test(ErrValidationType.NUMERIC, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER, validateDigitOptional)
            .length(ValidationConst.PHONE_NUMBER_LENGTH, ErrValidationMessage.ENTER_VALID_PHONE_NUMBER),
        email: Yup.string()
            .nullable()
            .trim()
            .required(ErrValidationMessage.REQUIRED)
            .email(ErrValidationMessage.ENTER_VALID_EMAIL)
    })
});
export const AccountDetailsSchema = Yup.object().shape({
    bankName: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    bankAddress: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    ifscCode: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    accountNumber: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    accountType: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    chequePayableName: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    panNumber: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED),
    document: Yup.object().shape({
        documentReferenceId: Yup.string().nullable().trim().required(ErrValidationMessage.REQUIRED)
    })
});
export const bankAccountDropDown = [
    { label: BankAccountType.CURRENT, value: BankAccountType.CURRENT },
    { label: BankAccountType.SAVINGS, value: BankAccountType.SAVINGS }
];
export const CANCELLED_CHEQUE = 'Cancelled Cheque';
export const packageDetailsDropdown = [
    {
        label: 'Tariff',
        value: PackageType.TARRIF_CHART
    },
    {
        label: 'Package',
        value: PackageType.PACKAGE_DETAILS
    }
];
