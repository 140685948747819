// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QKrO4TXLfbkKYFTfrcCn{height:calc(100vh - 100px)}.EyeK6xGjL8W_OGCtRzly{border-bottom:solid #e8e8e8}", "",{"version":3,"sources":["webpack://./src/common/components/SideDrawer/SideDrawer.module.scss"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CAGF,sBACE,2BAAA","sourcesContent":[".side-drawer-content {\n  height: calc(100vh - 100px);\n}\n\n.header-container {\n  border-bottom: solid #e8e8e8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"side-drawer-content": "QKrO4TXLfbkKYFTfrcCn",
	"header-container": "EyeK6xGjL8W_OGCtRzly"
};
export default ___CSS_LOADER_EXPORT___;
