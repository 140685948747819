export const ErrValidationMessage = {
    REQUIRED: 'Required',
    ENTER_VALID_PHONE_NUMBER: 'Enter valid Phone Number',
    ENTER_VALID_EMAIL: 'Enter valid EmailId',
    ENTER_VALID_PIN_CODE: 'Enter valid PIN Code',
    ENTER_VALID_PAN_NUMBER: 'Enter valid PAN Number'
};
export const ErrValidationType = {
    NUMERIC: 'Numeric Only',
    ALPHA_NUMERIC: 'Alphanumeric Only'
};
export const ValidationConst = {
    PHONE_NUMBER_LENGTH: 10,
    PIN_CODE_LENGTH: 6,
    PAN_REQUIRED_CLAIMED_AMOUNT: 100000
};
