import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Typography } from '@navi/web-ui/lib/primitives';
import { defaultNotificationContent, statusMap } from './constants';
import styles from './Notifications.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTE_MAPPING } from '@const/Routes';
import { fromNow } from '@utils/Date';
import { useDispatch } from 'react-redux';
import { readNotificationApi } from '@services/NotificationsApi';
import { getReadableString } from '@src/common/utils';
import { ClaimRequestType } from '@enums/ClaimEnums';
const NotificationItem = props => {
    const { notification } = props;
    const [notificationContent, setNotificationContent] = React.useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getNotificationContent = (notification) => {
        const mappedContent = statusMap.find((item) => {
            return (item.toClaimStatus.includes(notification.toClaimStatus) ||
                item.toPreauthStatus.includes(notification.toPreAuthStatus));
        });
        const content = mappedContent ? { ...mappedContent } : { ...defaultNotificationContent };
        replacePlaceholders(content);
        setNotificationContent(content);
    };
    const replacePlaceholders = (content) => {
        const isMainClaim = notification.requestType === ClaimRequestType.MAIN_CLAIM;
        const fromStatus = isMainClaim ? notification.fromClaimStatus : notification.fromPreAuthStatus;
        const toStatus = isMainClaim ? notification.toClaimStatus : notification.toPreAuthStatus;
        content.body = content.body.replace('CLAIM_ID', notification.claimId);
        content.body = content.body.replace('FROM_STATUS', getReadableString(fromStatus));
        content.body = content.body.replace('TO_STATUS', getReadableString(toStatus));
    };
    React.useEffect(() => {
        getNotificationContent(notification);
    }, [notification]);
    const handleNotificationClick = () => {
        navigate(ROUTE_MAPPING.CLAIM_JOURNEY.replace(':claimId', notification.claimId));
        if (!notification.readAt) {
            dispatch(readNotificationApi(notification.notificationId));
        }
    };
    return (_jsx("div", { className: `${styles['notification-item']} ${notification.readAt ? styles['read'] : ''}`, onClick: handleNotificationClick, children: _jsxs("div", { className: "flex items-start", children: [_jsx("div", { className: "w-8", children: notificationContent.icon }), _jsxs("div", { children: [_jsx(Typography, { variant: "p3", className: "mb-1", children: notificationContent.title }), _jsx(Typography, { variant: "p4", className: "mb-3 text-primary-grey", children: notificationContent.body }), _jsx(Typography, { variant: "p5", className: "text-primary-grey", children: fromNow(notification.statusChangedAt) })] })] }) }));
};
export default NotificationItem;
