export var HospitalPhoneLabel;
(function (HospitalPhoneLabel) {
    HospitalPhoneLabel["HOSPITAL_TELEPHONE"] = "HOSPITAL_TELEPHONE";
    HospitalPhoneLabel["CEO"] = "CEO";
    HospitalPhoneLabel["FINANCE_MANAGER"] = "FINANCE_MANAGER";
    HospitalPhoneLabel["HEAD_OF_OPERATIONS"] = "HEAD_OF_OPERATIONS";
    HospitalPhoneLabel["TPA_COORDINATOR"] = "TPA_COORDINATOR";
})(HospitalPhoneLabel || (HospitalPhoneLabel = {}));
export var BankAccountType;
(function (BankAccountType) {
    BankAccountType["CURRENT"] = "Current";
    BankAccountType["SAVINGS"] = "Savings";
})(BankAccountType || (BankAccountType = {}));
export var PackageType;
(function (PackageType) {
    PackageType["TARRIF_CHART"] = "TARRIF_CHART";
    PackageType["PACKAGE_DETAILS"] = "PACKAGE_DETAILS";
})(PackageType || (PackageType = {}));
export var ReferenceType;
(function (ReferenceType) {
    ReferenceType["HOSPITAL"] = "HOSPITAL";
    ReferenceType["CLAIM"] = "CLAIM";
    ReferenceType["DRAFT"] = "DRAFT";
})(ReferenceType || (ReferenceType = {}));
export var DocumentStatus;
(function (DocumentStatus) {
    DocumentStatus["PENDING"] = "PENDING";
    DocumentStatus["APPROVED"] = "APPROVED";
    DocumentStatus["REJECTED"] = "REJECTED";
})(DocumentStatus || (DocumentStatus = {}));
export const DocumentStatusMapping = {
    [DocumentStatus.PENDING]: 'Pending',
    [DocumentStatus.APPROVED]: 'Approved',
    [DocumentStatus.REJECTED]: 'Rejected'
};
export const DocumentStatusColorMapping = {
    [DocumentStatus.APPROVED]: '#00BA34',
    [DocumentStatus.PENDING]: '#FACC15',
    [DocumentStatus.REJECTED]: 'red'
};
