import { ApiService } from './ApiService';
import { PARTNER_API_BASE_URL } from '@const/ApiConstants';
const ENDPOINTS = {
    HOSPITAL_DETAILS: {
        endpoint: () => `${PARTNER_API_BASE_URL}`
    }
};
export const getHospitalDetails = (hospitalId) => {
    return ApiService.get(`${ENDPOINTS.HOSPITAL_DETAILS.endpoint()}/hospital/${hospitalId}`);
};
export const updateContactDetails = (hospitalId, payload) => {
    return ApiService.put(`${ENDPOINTS.HOSPITAL_DETAILS.endpoint()}/hospital/${hospitalId}/contact`, payload);
};
export const updateAccountDetails = (hospitalId, payload) => {
    return ApiService.put(`${ENDPOINTS.HOSPITAL_DETAILS.endpoint()}/hospital/${hospitalId}/account`, payload);
};
export const uploadHospitalDocuments = (hospitalId, payload) => {
    return ApiService.put(`${ENDPOINTS.HOSPITAL_DETAILS.endpoint()}/hospital/${hospitalId}/document`, payload);
};
