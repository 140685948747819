import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Card, Typography } from '@navi/web-ui/lib/primitives';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import { ProgressDetailsTitleMapping } from '../enums';
import { NetworkReimbursementClaimsData, PreAuthClaimsData } from '../constants';
const ProgressDetails = (props) => {
    const { title, color, percent } = props;
    return (_jsxs(GridRow, { className: "items-center justify-between m-0 mt-3", children: [_jsx(GridColumn, { xs: 4, className: "p-0 justify-end", children: _jsx(Typography, { variant: "p3", className: "text-regal-grey", children: title }) }), _jsx(GridColumn, { xs: 7, className: "m-0", children: _jsx(ProgressBar, { color: color, percent: percent }) }), _jsx(GridColumn, { xs: 1, className: "m-0", children: _jsx(Typography, { variant: "p3", className: "text-regal-grey", children: percent }) })] }));
};
const ClaimsProgressDetails = (props) => {
    const { claimsData = {}, title, color, isPreAuth = false } = props;
    const claimData = isPreAuth ? PreAuthClaimsData : NetworkReimbursementClaimsData;
    return (_jsx(GridContainer, { className: "m-0", children: _jsxs(Card, { className: `w p-6 pt-4 rounded-xl homepage-custom-card`, children: [_jsx(Typography, { variant: "p2", className: "font-semibold mb-6", color: "#1C1C1C", children: title }), claimData.map((item, index) => (_jsx(ProgressDetails, { color: color, title: ProgressDetailsTitleMapping[item], percent: claimsData[item] ? `${claimsData[item]}%` : '0%' }, index)))] }) }));
};
export default ClaimsProgressDetails;
