import { CLAIM_API_BASE_URL, STATUS_CODE } from '@const/ApiConstants';
import { getErrorMessage, toSearchParams } from '@utils/';
import { ApiService } from './ApiService';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { setNotificaitons, setUnreadNotificationsCount } from '@redux/common/common.actions';
import { DEFAULT_NOTIFICATION_PAGE_SIZE } from '@pages/Notifications/constants';
const ENDPOINTS = {
    SEARCH_NOTIFICATIONS: {
        endpoint: (params = {}) => `${CLAIM_API_BASE_URL}/notifications?${toSearchParams(params)}`
    },
    GET_UNREAD_NOTIFICATIONS_COUNT: {
        endpoint: (hospitalId) => `${CLAIM_API_BASE_URL}/notifications/count/${hospitalId}`
    },
    READ_NOTIFICATION: {
        endpoint: (notificationId) => `${CLAIM_API_BASE_URL}/notifications/${notificationId}`
    },
    READ_ALL_NOTIFICATIONS: {
        endpoint: (hospitalId) => `${CLAIM_API_BASE_URL}/notifications/hospital/${hospitalId}`
    }
};
export const searchNotificationsApi = (request, setIsLoading) => {
    return function (dispatch) {
        setIsLoading(true);
        const response = ApiService.get(ENDPOINTS.SEARCH_NOTIFICATIONS.endpoint(request));
        response
            .then((response) => {
            if (response?.data?.statusCode === STATUS_CODE.OK && response.data.data.notifications) {
                dispatch(setNotificaitons(response.data.data.notifications));
                return;
            }
            throw response;
        })
            .catch((error) => {
            toast.error(getErrorMessage(error.message));
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
};
export const getUnreadNotificationsCountApi = (hospitalId) => {
    return function (dispatch) {
        const response = ApiService.get(ENDPOINTS.GET_UNREAD_NOTIFICATIONS_COUNT.endpoint(hospitalId));
        response
            .then((response) => {
            if (response?.data?.statusCode === STATUS_CODE.OK) {
                dispatch(setUnreadNotificationsCount(response.data.data));
                return;
            }
            throw response;
        })
            .catch((error) => {
            toast.error(getErrorMessage(error.message));
        });
    };
};
export const readNotificationApi = (notificationId) => {
    return function (dispatch, state) {
        const response = ApiService.put(ENDPOINTS.READ_NOTIFICATION.endpoint(notificationId), {});
        response
            .then((response) => {
            if (response?.data?.statusCode === STATUS_CODE.OK) {
                const unreadNotificationsCount = state().commonDetails.unreadNotificationsCount;
                dispatch(setUnreadNotificationsCount(unreadNotificationsCount - 1));
                return;
            }
            throw response;
        })
            .catch((error) => {
            toast.error(getErrorMessage(error.message));
        });
    };
};
export const readAllNotificationsApi = (hospitalId) => {
    return function (dispatch) {
        const response = ApiService.put(ENDPOINTS.READ_ALL_NOTIFICATIONS.endpoint(hospitalId), {});
        response
            .then((response) => {
            if (response?.data?.statusCode === STATUS_CODE.OK) {
                dispatch(setUnreadNotificationsCount(0));
                const request = { page: '0', size: DEFAULT_NOTIFICATION_PAGE_SIZE };
                dispatch(searchNotificationsApi(request, () => { }));
                return;
            }
            throw response;
        })
            .catch((error) => {
            toast.error(getErrorMessage(error.message));
        });
    };
};
