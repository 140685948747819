import { PRE_AUTH_ACTION_TYPES } from './ActionTypes';
import { initialState as preAuthInitialState } from './constants';
const preAuthReducer = (state = preAuthInitialState, action) => {
    switch (action?.type) {
        case PRE_AUTH_ACTION_TYPES.SET_PRE_AUTH_LIST: {
            return {
                ...state,
                claims: action?.payload,
                isFetching: false
            };
        }
        case PRE_AUTH_ACTION_TYPES.RESET_PRE_AUTH_LIST: {
            return {
                ...state,
                claims: [],
                preAuthPaginationDetails: null
            };
        }
        case PRE_AUTH_ACTION_TYPES.SET_PRE_AUTH_LIST_PAGINATION_DETAILS: {
            return {
                ...state,
                preAuthPaginationDetails: action?.payload
            };
        }
        default:
            return state;
    }
};
export default preAuthReducer;
