import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridColumn, GridContainer, GridRow } from '@navi/web-ui/lib/layouts/Grid';
import { Card, Typography } from '@navi/web-ui/lib/primitives';
import { DEFAULT_TEXT } from '@const/Symbols';
const COVER_PLAN_KEY = 'Plan';
const PolicyDetails = ({ policyDetails, memberId }) => {
    const memberDetails = policyDetails?.members?.find(member => member.memberId === memberId);
    return (_jsx(Card, { className: "custom-card w", children: _jsxs(GridContainer, { children: [_jsx(GridRow, { children: _jsx(GridColumn, { className: "pb-8", children: _jsx(Typography, { className: "text-regal-grey", variant: "h2", children: "Policy Details" }) }) }), _jsxs(GridRow, { children: [_jsxs(GridColumn, { xs: 4, children: [_jsx(Typography, { variant: "p2", as: "div", className: "text-regal-grey pb-2", children: "Member name" }), _jsx(Typography, { variant: "h3", as: "div", className: "font-bold text-regal-grey", children: memberDetails?.name || DEFAULT_TEXT })] }), _jsxs(GridColumn, { xs: 4, children: [_jsx(Typography, { variant: "p2", as: "div", className: "text-regal-grey pb-2", children: "Mobile number" }), _jsx(Typography, { variant: "h3", as: "div", className: "font-bold text-regal-grey", children: policyDetails?.policyHolderDetail?.phoneNumber || DEFAULT_TEXT })] }), _jsxs(GridColumn, { xs: 4, children: [_jsx(Typography, { variant: "p2", as: "div", className: "text-regal-grey pb-2", children: "Policy name" }), _jsx(Typography, { variant: "h3", as: "div", className: "font-bold text-regal-grey", children: policyDetails?.policyName || DEFAULT_TEXT })] })] })] }) }));
};
export default PolicyDetails;
