// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nUXtSG8RNWRJ6HQqVYsB{margin-bottom:16px}.nUXtSG8RNWRJ6HQqVYsB .aaDLNxyPMMsdoF8RktNa{background-color:#fcfcfd;box-shadow:0px 2px 8px rgba(0,0,0,.08)}.nUXtSG8RNWRJ6HQqVYsB .aaDLNxyPMMsdoF8RktNa .ozdr4jtbrcjy62HtSas4{padding:10px 32px}.nUXtSG8RNWRJ6HQqVYsB .aaDLNxyPMMsdoF8RktNa .vyielBxsD3PnxbG0YTEF{padding:0 24px 24px}", "",{"version":3,"sources":["webpack://./src/common/components/CustomAccordion/CustomAccordion.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,wBAAA,CACA,sCAAA,CAEF,kEACI,iBAAA,CAEJ,kEACI,mBAAA","sourcesContent":[".custom-accordion-wrapper {\n  margin-bottom: 16px;\n}\n  .custom-accordion-wrapper .accordion {\n    background-color: #FCFCFD;\n    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);\n  }\n  .custom-accordion-wrapper .accordion .title-wrapper {\n      padding: 10px 32px;\n    }\n  .custom-accordion-wrapper .accordion .body-wrapper {\n      padding: 0 24px 24px;\n    }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-accordion-wrapper": "nUXtSG8RNWRJ6HQqVYsB",
	"accordion": "aaDLNxyPMMsdoF8RktNa",
	"title-wrapper": "ozdr4jtbrcjy62HtSas4",
	"body-wrapper": "vyielBxsD3PnxbG0YTEF"
};
export default ___CSS_LOADER_EXPORT___;
