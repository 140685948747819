import axios from 'axios';
import { AUTH_CLIENT_ID } from '@const/ApiConstants';
import { getCookie } from '@utils/Cookies';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { getErrorMessage } from '@utils/';
export class ApiService {
    // eslint-disable-next-line no-use-before-define
    static instance;
    service = axios.create();
    static getInstance() {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService();
            ApiService.instance.service.interceptors.request.use((req) => {
                // adding custom headers for all HTTP requests
                req.headers['X-Realm-Id'] = AUTH_CLIENT_ID;
                req.headers['X-Session-Token'] = getCookie();
                return req;
            });
        }
        return ApiService.instance;
    }
    constructor() {
        this.service.interceptors.response.use(ApiService.unAuthorizedHandle);
    }
    static unAuthorizedHandle = function (response) {
        if (response?.data?.statusCode === 401) {
            toast.error(getErrorMessage(response.data));
            window.location.href = '/login';
        }
        return response;
    };
    static get(path) {
        return ApiService.getInstance().instanceGet(path);
    }
    static getWithHeader(path, headers) {
        return ApiService.getInstance().instanceGetWithHeader(path, headers);
    }
    static post(path, payload) {
        return ApiService.getInstance().instancePost(path, payload);
    }
    static postWithHeader(path, payload, headers) {
        return ApiService.getInstance().instancePostWithHeader(path, payload, headers);
    }
    static patch(path, payload) {
        return ApiService.getInstance().instancePatch(path, payload);
    }
    static delete(path, payload = null) {
        return ApiService.getInstance().instanceDelete(path, payload);
    }
    static put(path, payload) {
        return ApiService.getInstance().instancePut(path, payload);
    }
    static putWithHeader(path, payload, headers) {
        return ApiService.getInstance().instancePutWithHeader(path, payload, headers);
    }
    instanceGet(path) {
        return this.service.get(path);
    }
    instanceGetWithHeader(path, headers) {
        return this.service.request({
            method: 'GET',
            url: path,
            headers: headers,
            responseType: 'json'
        });
    }
    instancePatch(path, payload) {
        return this.service.request({
            method: 'PATCH',
            url: path,
            responseType: 'json',
            data: payload
        });
    }
    instancePost(path, payload) {
        return this.service.request({
            method: 'POST',
            url: path,
            responseType: 'json',
            data: payload
        });
    }
    instancePostWithHeader(path, payload, headers) {
        return this.service.request({
            method: 'POST',
            url: path,
            headers: headers,
            responseType: 'json',
            data: payload
        });
    }
    instanceDelete(path, payload) {
        return this.service.request({
            method: 'DELETE',
            url: path,
            responseType: 'json',
            data: payload
        });
    }
    instancePut(path, payload) {
        return this.service.request({
            method: 'PUT',
            url: path,
            responseType: 'json',
            data: payload
        });
    }
    instancePutWithHeader(path, payload, headers) {
        return this.service.request({
            method: 'PUT',
            url: path,
            headers: headers,
            responseType: 'json',
            data: payload
        });
    }
}
