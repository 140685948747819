import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '@navi/web-ui/lib/components/Navbar';
import { logOutUserCall } from '@redux/auth/auth.actions';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { Typography } from '@navi/web-ui/lib/primitives';
import Dialog from '../../Dialog';
import styles from './LeftNav.module.scss';
import { selectIsAdmin, selectIsSuperAdmin, selectLoginUserGroups, selectLoginUserName } from '@redux/userDetails/userDetails.selector';
import { DashboardBuckets } from '@pages/PreAuth/constants';
import preAuthIcon from '@assets/icons/pre-auth-icon.svg';
import networkReimbursementIcon from '@assets/icons/network-reimbursement-icon.svg';
import userManagementIcon from '@assets/icons/user-management-icon.svg';
import bellIcon from '@assets/icons/bell-icon.svg';
import dashboardIcon from '@assets/icons/Dashboard.svg';
import hospitalDetailsIcon from '@assets/icons/hospital-details.svg';
import { ROUTE_MAPPING } from '@const/Routes';
import NaviLogo from '@assets/icons/NaviLogo';
import NotificationsPopup from '@pages/Notifications/NotificationsPopup';
import { getUnreadNotificationsCountApi } from '@services/NotificationsApi';
import { selectUnreadNotificationsCount } from '@redux/common/common.selector';
import { createPoller, destroyPoller } from '@utils/Polling';
// todo: Due to build failure, providing default route prop @dharmik please check
const LeftNav = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userName = useAppSelector(selectLoginUserName);
    const isAdmin = useAppSelector(selectIsAdmin);
    const isSuperAdmin = useAppSelector(selectIsSuperAdmin);
    const groups = useAppSelector(selectLoginUserGroups);
    const hospitalId = groups[0]?.toUpperCase();
    const unreadNotificationsCount = useAppSelector(selectUnreadNotificationsCount);
    const [dialogOpen, setDialogOpen] = useState(false);
    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);
    const confirmLogout = () => {
        closeDialog();
        dispatch(logOutUserCall());
    };
    const navItemsList = [
        {
            itemType: 'simpleNavItem',
            label: 'Dashboard',
            route: ROUTE_MAPPING.DASHBOARD,
            Icon: () => _jsx("img", { src: dashboardIcon, alt: "Dashboard" }),
            handleNavigation: () => navigate(ROUTE_MAPPING.DASHBOARD)
        },
        {
            itemType: 'dropdownNavItem',
            label: 'Pre-authorization',
            Icon: () => _jsx("img", { src: preAuthIcon, alt: "Pre-Authorization" }),
            options: [
                {
                    itemType: 'simpleNavItem',
                    label: 'Draft requests',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.DRAFT}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.DRAFT}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Pending with NAVI',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.PRE_AUTH_PENDING_WITH_NAVI}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.PRE_AUTH_PENDING_WITH_NAVI}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Pre-auth Approved',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.PRE_AUTH_APPROVED}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.PRE_AUTH_APPROVED}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Missing Info',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.PRE_AUTH_MISSING_INFO}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.PRE_AUTH_MISSING_INFO}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Discharge Approved',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.DISCHARGE_APPROVED}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.DISCHARGE_APPROVED}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Transaction Rejected',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.TRANSACTION_REJECTED}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.TRANSACTION_REJECTED}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Claim Rejected',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_REJECTED}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_REJECTED}`)
                }
            ]
        },
        {
            itemType: 'dropdownNavItem',
            label: 'Network Reimbursement',
            Icon: () => _jsx("img", { src: networkReimbursementIcon, alt: "Network Reimbursement" }),
            options: [
                {
                    itemType: 'simpleNavItem',
                    label: 'Discharge Approved',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.DISCHARGE_APPROVED}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.NETWORK_REIMBURSEMENT_LISTING}?bucket=${DashboardBuckets.DISCHARGE_APPROVED}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Claim Documents Awaited',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_DOCUMENTS_AWAITED}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.NETWORK_REIMBURSEMENT_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_DOCUMENTS_AWAITED}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Pending with NAVI',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_PENDING_WITH_NAVI}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.NETWORK_REIMBURSEMENT_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_PENDING_WITH_NAVI}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Missing Info',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_MISSING_INFO}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.NETWORK_REIMBURSEMENT_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_MISSING_INFO}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Claim Approved',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_APPROVED}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.NETWORK_REIMBURSEMENT_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_APPROVED}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Claim Settled',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_SETTLED}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.NETWORK_REIMBURSEMENT_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_SETTLED}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Claim Rejected',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_REJECTED}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.NETWORK_REIMBURSEMENT_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_REJECTED}`)
                },
                {
                    itemType: 'simpleNavItem',
                    label: 'Claim Cancelled',
                    route: `${ROUTE_MAPPING.PRE_AUTH_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_CANCELLED}`,
                    handleNavigation: () => navigate(`${ROUTE_MAPPING.NETWORK_REIMBURSEMENT_LISTING}?bucket=${DashboardBuckets.MAIN_CLAIM_CANCELLED}`)
                }
            ]
        }
    ];
    if (isAdmin || isSuperAdmin) {
        navItemsList.push({
            itemType: 'simpleNavItem',
            label: 'User Management',
            route: ROUTE_MAPPING.USER_MANAGEMENT,
            Icon: () => _jsx("img", { src: userManagementIcon, alt: "User Management" }),
            handleNavigation: () => navigate(ROUTE_MAPPING.USER_MANAGEMENT)
        }, {
            itemType: 'simpleNavItem',
            label: 'Hospital Details',
            route: ROUTE_MAPPING.HOSPITAL_DETAILS,
            Icon: () => _jsx("img", { src: hospitalDetailsIcon, alt: "Hospital" }),
            handleNavigation: () => navigate(ROUTE_MAPPING.HOSPITAL_DETAILS)
        });
    }
    const bottomNavItemsList = [
        {
            itemType: 'popupNavItem',
            label: 'Notifications',
            Icon: () => _jsx("img", { src: bellIcon, alt: "Notifications" }),
            popupContent: _jsx(NotificationsPopup, {}),
            count: unreadNotificationsCount
        }
    ];
    const footerDetails = {
        footerText: userName,
        options: [
            {
                label: 'Logout',
                handleClick: openDialog
            }
        ]
    };
    const headerContent = (_jsx("div", { className: styles.header, children: _jsx(NaviLogo, {}) }));
    React.useEffect(() => {
        if (hospitalId) {
            dispatch(getUnreadNotificationsCountApi(hospitalId));
        }
    }, [hospitalId]);
    const getUnreadNotificationsCount = React.useCallback(() => {
        dispatch(getUnreadNotificationsCountApi(hospitalId));
    }, [hospitalId]);
    React.useEffect(() => {
        const pollerId = createPoller(getUnreadNotificationsCount, 60);
        return () => {
            destroyPoller(pollerId);
        };
    }, [getUnreadNotificationsCount]);
    return (_jsxs(_Fragment, { children: [_jsx(Navbar, { headerContent: headerContent, navItemList: navItemsList, bottomNavItemList: bottomNavItemsList, footer: footerDetails, children: _jsx("div", { className: styles['page-content-container'], children: children }) }), dialogOpen && (_jsx(Dialog, { open: dialogOpen, onClose: closeDialog, header: "Logout", showFooter: true, footerButtons: [
                    {
                        label: 'Yes',
                        onClick: confirmLogout
                    },
                    {
                        label: 'No',
                        onClick: closeDialog
                    }
                ], children: _jsx("div", { className: styles['dialog-content'], children: _jsx(Typography, { variant: "p3", children: "Are you sure you want to logout?" }) }) }))] }));
};
export default LeftNav;
