import { createSelector } from 'reselect';
const selectFilteredListDetails = (state) => state.filteredListDetails;
export const getFilteredList = createSelector([selectFilteredListDetails], filteredListDetails => filteredListDetails.filteredList?.claims || []);
export const getFilteredListPageDetails = createSelector([selectFilteredListDetails], filteredListDetails => {
    return {
        page: filteredListDetails.filteredList?.pageOffset,
        totalElements: filteredListDetails.filteredList?.totalSize,
        size: filteredListDetails.filteredList?.pageSize
    };
});
export const getDashBoardUrl = createSelector([selectFilteredListDetails], filteredListDetails => filteredListDetails.dashboardUrl);
export const getFilterDataFetchingStatus = createSelector([selectFilteredListDetails], filteredListDetails => filteredListDetails.isFilterDataFetching);
