export var HOSPITAL_PORTAL_CLAIM_STATUS;
(function (HOSPITAL_PORTAL_CLAIM_STATUS) {
    HOSPITAL_PORTAL_CLAIM_STATUS["CLAIM_CREATED"] = "Claim Created";
    HOSPITAL_PORTAL_CLAIM_STATUS["PENDING_NAVI"] = "Pending with NAVI";
    HOSPITAL_PORTAL_CLAIM_STATUS["INITIAL_APPROVED"] = "Initial Pre-auth Approved";
    HOSPITAL_PORTAL_CLAIM_STATUS["MORE_INFORMATION_NEEDED"] = "More Information Needed";
    HOSPITAL_PORTAL_CLAIM_STATUS["ENHANCEMENT_APPROVED"] = "Enhancement Approved";
    HOSPITAL_PORTAL_CLAIM_STATUS["DISCHARGE_APPROVED"] = "Discharge Approved";
    HOSPITAL_PORTAL_CLAIM_STATUS["CLAIM_REJECTED"] = "Claim Rejected";
    HOSPITAL_PORTAL_CLAIM_STATUS["ENHANCEMENT_REJECTED"] = "Enhancement Rejected";
    HOSPITAL_PORTAL_CLAIM_STATUS["DISCHARGE_REJECTED"] = "Discharge Rejected";
    HOSPITAL_PORTAL_CLAIM_STATUS["CASHLESS_DOCUMENT_AWAITED"] = "Cashless Document Awaited";
    HOSPITAL_PORTAL_CLAIM_STATUS["CLAIM_APPROVED"] = "Claim Approved";
    HOSPITAL_PORTAL_CLAIM_STATUS["CLAIM_CANCELLED"] = "Claim Cancelled";
    HOSPITAL_PORTAL_CLAIM_STATUS["CLAIM_SETTLED"] = "Claim Settled";
})(HOSPITAL_PORTAL_CLAIM_STATUS || (HOSPITAL_PORTAL_CLAIM_STATUS = {}));
