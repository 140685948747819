import { ACTION_TYPES } from '@redux/ReduxActionTypes';
import { initialState as userDetailsInitialState } from './constants';
const { USER_ACTION_TYPES } = ACTION_TYPES;
const userDetailsReducer = (state = userDetailsInitialState, action) => {
    switch (action?.type) {
        case USER_ACTION_TYPES.SET_LOGIN_USER_DETAIL_SUCCESS: {
            return {
                ...state,
                loginUserDetailSuccess: action?.payload
            };
        }
        case USER_ACTION_TYPES.SET_LOGIN_USER_DETAIL_ERROR: {
            return {
                ...state,
                loginUserDetailError: action?.payload,
                isFetching: false
            };
        }
        case USER_ACTION_TYPES.RESET_LOGIN_USER_DETAIL_DATA: {
            return {
                ...action?.payload
            };
        }
        case USER_ACTION_TYPES.SET_USER_LIST_SUCCESS: {
            return {
                ...state,
                userListSuccess: action?.payload,
                isUserListPresent: true,
                isFetching: false
            };
        }
        case USER_ACTION_TYPES.SET_USER_LIST_ERROR: {
            return {
                ...state,
                userListError: action?.payload,
                isFetching: false
            };
        }
        case USER_ACTION_TYPES.RESET_USER_LIST_DATA: {
            return {
                ...state,
                ...action?.payload
            };
        }
        case USER_ACTION_TYPES.SET_IS_USER_LIST_PRESENT: {
            return {
                ...state,
                isUserListPresent: action?.payload
            };
        }
        case USER_ACTION_TYPES.SET_USER_LIST_PAGE_DETAILS: {
            return {
                ...state,
                userListPageDetails: action?.payload
            };
        }
        case USER_ACTION_TYPES.SET_USER_LIST_FETCHING: {
            return {
                ...state,
                isFetching: action?.payload
            };
        }
        default:
            return state;
    }
};
export default userDetailsReducer;
