import { deleteCookie, setCookie } from '@utils/Cookies';
import { toast } from '@navi/web-ui/lib/primitives/Toast';
import { ACTION_TYPES } from '@redux/ReduxActionTypes';
import { initialState as authDetailsInitState } from '@redux/auth/constants';
import { LogoutConstants, OTPInputScreenConstants } from '@pagesConstant/loginConstants';
/* Reset Actions */
import { resetUserDetails, getUserDetailsAPI } from '@redux/userDetails/userDetails.actions';
import { logOutUser, requestOTP, verifyOTP } from '@services/AuthApi';
import { resetClaimDetails } from '../claimJourney/claimJourney.actions';
import { resetPreAuthList } from '../preAuth/preAuth.actions';
import { resetFilteredListData } from '../globalFilter/globalFilter.actions';
import { getErrorMessage } from '@utils/';
const { AUTH_ACTION_TYPES } = ACTION_TYPES;
export const showOTPScreen = (showOTP) => ({
    type: AUTH_ACTION_TYPES.SHOW_OTP_SCREEN,
    payload: showOTP
});
export const setUserMobileNumber = (userMobileNumber) => ({
    type: AUTH_ACTION_TYPES.SET_MOBILE_NUMBER,
    payload: userMobileNumber
});
const setGetOTPSuccess = (payload) => ({
    type: AUTH_ACTION_TYPES.GET_OTP_SUCCESS,
    payload
});
const getOTPRequestSuccess = (payload, phoneNumber, fromOTPScreen) => {
    return (dispatch) => {
        if (!fromOTPScreen) {
            dispatch(setUserMobileNumber(phoneNumber));
            dispatch(showOTPScreen(true));
        }
        dispatch(setGetOTPSuccess(payload));
        toast.success(OTPInputScreenConstants.successMessage);
    };
};
export const setOTPRequestError = (payload) => ({
    type: AUTH_ACTION_TYPES.GET_OTP_ERROR,
    payload
});
export const getOTPApiCall = (phoneNumber, setIsFetching, onOtpGenerationSuccess = undefined, fromOTPScreen = false) => {
    return async function (dispatch) {
        dispatch(setOTPRequestError({}));
        setIsFetching(true);
        await requestOTP(phoneNumber)
            .then((response) => {
            setIsFetching(false);
            dispatch(getOTPRequestSuccess(response?.data?.data?.otpToken, phoneNumber, fromOTPScreen));
            if (onOtpGenerationSuccess)
                onOtpGenerationSuccess();
        })
            .catch((error) => {
            setIsFetching(false);
            dispatch(setOTPRequestError(error?.response?.data));
            toast.error(error?.response?.data?.message || OTPInputScreenConstants.errorMessage);
        });
    };
};
/* Verify OTP-FLOW */
const setVerifyOTPRequestSuccess = (payload) => ({
    type: AUTH_ACTION_TYPES.VERIFY_OTP_SUCCESS,
    payload
});
const setAuthToken = (payload) => {
    if (payload?.sessionToken && payload?.sessionExpiry) {
        setCookie(payload?.sessionExpiry, payload?.sessionToken);
    }
};
export const startFetchUserInfo = () => {
    return (dispatch) => {
        dispatch(getUserDetailsAPI());
    };
};
const verifyOTPRequestSuccess = (payload, navigateToHome) => {
    setAuthToken(payload);
    return (dispatch) => {
        dispatch(setVerifyOTPRequestSuccess(payload));
        dispatch(startFetchUserInfo());
        navigateToHome();
    };
};
export const setVerifyOTPRequestError = (payload) => ({
    type: AUTH_ACTION_TYPES.VERIFY_OTP_ERROR,
    payload
});
export const verifyOTPApiCall = (otp, phoneNumber, otpToken, navigateToHome) => {
    return async function (dispatch) {
        await verifyOTP(otp, phoneNumber, otpToken)
            .then((response) => {
            dispatch(verifyOTPRequestSuccess(response?.data?.data, navigateToHome));
        })
            .catch((error) => {
            const errMessage = getErrorMessage(error?.response?.data);
            dispatch(setVerifyOTPRequestError(errMessage));
            toast.error(errMessage);
        });
    };
};
/* Logout FLow */
export const setUserLogOutSuccess = (payload) => ({
    type: AUTH_ACTION_TYPES.USER_LOGOUT_SUCCESS,
    payload
});
export const setUserLogOutError = (payload) => ({
    type: AUTH_ACTION_TYPES.USER_LOGOUT_ERROR,
    payload
});
export const resetAuthDetails = () => ({
    type: AUTH_ACTION_TYPES.RESET_AUTH_DETAILS,
    payload: authDetailsInitState
});
export const resetLocalData = () => {
    deleteCookie();
    return (dispatch) => {
        dispatch(resetAuthDetails());
        dispatch(resetUserDetails());
        dispatch(resetClaimDetails());
        dispatch(resetPreAuthList());
        dispatch(resetFilteredListData());
    };
};
export const logOutUserCall = () => {
    return async function (dispatch) {
        await logOutUser()
            .then((response) => {
            dispatch(setUserLogOutSuccess(response));
            toast.success(LogoutConstants.logoutSuccess);
        })
            .catch((error) => {
            dispatch(setUserLogOutError(error));
        })
            .finally(() => {
            dispatch(resetLocalData());
        });
    };
};
export const setIsUserLoggedIn = (payload) => ({
    type: AUTH_ACTION_TYPES.SET_IS_USER_LOGGED_IN,
    payload
});
