import { USER_ROLES_TYPE } from '@enums/UserRolesType';
import { createSelector } from 'reselect';
import _ from 'lodash';
const selectUserDetails = (state) => state.userDetails;
export const selectLoginUserDetails = createSelector([selectUserDetails], selectUserDetails => selectUserDetails.loginUserDetailSuccess);
export const selectIsUserListFetched = createSelector([selectUserDetails], selectUserDetails => selectUserDetails.isFetching);
export const selectIsUserListPresent = createSelector([selectUserDetails], selectUserDetails => selectUserDetails.isUserListPresent);
export const selectLoginUserRoles = createSelector([selectUserDetails], selectUserDetails => selectUserDetails?.loginUserDetailSuccess?.roles || []);
export const selectUserList = createSelector([selectUserDetails], selectUserDetails => selectUserDetails?.userListSuccess);
export const selectUserListPageDetails = createSelector([selectUserDetails], selectUserDetails => selectUserDetails?.userListPageDetails);
export const selectLoginUserGroups = createSelector([selectUserDetails], selectUserDetails => selectUserDetails?.loginUserDetailSuccess?.groups || []);
export const selectIsAdmin = createSelector([selectUserDetails], selectUserDetails => selectUserDetails?.loginUserDetailSuccess?.roles?.includes(USER_ROLES_TYPE.ADMIN) || false);
export const selectIsSuperAdmin = createSelector([selectUserDetails], selectUserDetails => selectUserDetails?.loginUserDetailSuccess?.roles?.includes(USER_ROLES_TYPE.SUPER_ADMIN) || false);
export const selectIsAgent = createSelector([selectUserDetails], selectUserDetails => selectUserDetails?.loginUserDetailSuccess?.roles?.includes(USER_ROLES_TYPE.AGENT) || false);
export const selectIsValidRole = createSelector([selectUserDetails], selectUserDetails => _.intersection(selectUserDetails?.loginUserDetailSuccess?.roles, [
    USER_ROLES_TYPE.AGENT,
    USER_ROLES_TYPE.ADMIN,
    USER_ROLES_TYPE.SUPER_ADMIN
])?.length || false);
export const selectLoginUserName = createSelector([selectUserDetails], selectUserDetails => selectUserDetails?.loginUserDetailSuccess?.name || '');
